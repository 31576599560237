import React, { useState, useCallback } from "react";
import SingleCard from "./SingleCard";
import update from "immutability-helper";

export default function Container({ tables }) {
  const [cards, setCards] = useState(tables);
  const [isDragging, setIsDragging] = useState(false);

  const style = {
    width: "100%",
    backgroundColor: isDragging ? "#dae4fd" : "inherit",
    border: isDragging ? "2px dashed black" : "none",
  };

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = cards[dragIndex];
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
    },
    [cards]
  );

  const renderCard = (card, index, setIsDragging) => {
    return (
      <SingleCard
        key={card.id}
        index={index}
        id={card.id}
        text={card.text}
        moveCard={moveCard}
        setIsDragging={setIsDragging}
        role="listitem"
        aria-label={`Card ${index + 1}: ${card.text}`}
      />
    );
  };

  return (
    <div style={style} role="list" aria-labelledby="report-perf-boxes-title">
      {cards.map((card, i) => renderCard(card, i, setIsDragging))}
    </div>
  );
}
