import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Button, TextField } from '@material-ui/core';

import Auth0Service from "../services/auth0Service/authentikService";

import { getAllDomains, DomainModules, enableDisableModules, updateDomainLinks } from "../store/actions/adminScansManagementActions";
import { addDomain } from "../store/actions/dashboardActions"

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import AdminCybersec from './AdminComponents/AdminCybersec'
import { getAllAccounts } from "../store/actions/adminScansManagementActions";
import CustomCardContainerHead from "../components/commons/CustomCardContainerHead"


const useStyles = makeStyles({
  selectBox: {
    maxWidth: 300,
    width: 300,
  },
});

const styles = {
  textField: {
    width: '100%',
    margin: 10,
    maxWidth: 300,
  }
}

export default function CollapsibleTable() {
  const classes = useStyles();
  const allDomains = useSelector((state) => state.adminScansManagementReducer.allDomains);
  const [domain, setDomain] = React.useState("");
  const [domainName, setDomainName] = React.useState("");
  const [user, setUser] = React.useState("");
  const [numericValue, setNumericValue] = useState("");
  const [demo, setDemo] = useState(false);
  const [environment, setEnvironment] = useState("production");
  const [loadingAccounts, setLoadingAccounts] = React.useState(true)
  const [data, setData] = React.useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllDomains());
  }, [dispatch]);

  useEffect(() => {
    if (user && allDomains.length > 0) {
      const filteredDomains = allDomains.filter(d => d.userEmail === user && d.environment === "production" || d.environment === "local");
      if (filteredDomains.length > 0) {
        console.log(filteredDomains)
        setDomain(filteredDomains[0]._id);
        setDomainName(filteredDomains[0].domain);
      }
    }
  }, [user, allDomains]);


  React.useEffect(() => {
    getAccounts()
  }, [])

  const handleSubmit = () => {
    // This function will be called when the submit button is clicked
    // You can use the numericValue state here to perform any action with the entered numeric value
    console.log("Submitted numeric value:", numericValue);
    dispatch(addDomain(domainName, user, demo));


    // Add your logic here to handle the submitted numeric value
  }


  const handleUpdate = () => {
    // This function will be called when the submit button is clicked
    // You can use the numericValue state here to perform any action with the entered numeric value
    console.log("Submitted numeric value:", numericValue);
    dispatch(updateDomainLinks(domainName, numericValue, demo, environment));


    // Add your logic here to handle the submitted numeric value
  }

  function getAccounts() {
    dispatch(getAllAccounts()).then(data => {
      if (data) {
        setLoadingAccounts(false);
        setData(data.result)
        console.log(data.resul)
      } else {
        setLoadingAccounts(false)

      }
    }).catch(err => {
      setLoadingAccounts(false)
    })
  }

  const options = [
    { "value": 1000, "label": "1K" },
    { "value": 3000, "label": "3K" },
    { "value": 5000, "label": "5K" },
    { "value": 10000, "label": "10K" },
    { "value": 25000, "label": "25K" },
    { "value": 50000, "label": "50K" },
    { "value": 1000000, "label": "1M" },
    { "value": 2000000, "label": "2M" },
    { "value": 3000000, "label": "3M" },
    { "value": 4000000, "label": "4M" },
    { "value": 5000000, "label": "5M" },
    { "value": 10000000, "label": "10M" }
  ];



  if (!Auth0Service.isAuthenticated()) {
    return window.location.href = "/authenticate"
  } else {

    return (
      <>
        <CustomCardContainerHead title={"Update Production Website"} titleTag="h1">
          <Box
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="flex-start"
            mb={2}
            mx={1}
            style={{ backgroundColor: "#FFF", padding: 20 }}
          >
            <label htmlFor="select-user" style={{ marginRight: "10px" }}>
              Select User:
            </label>
            <br />
            <Select
              IconComponent={(props) => <ExpandMoreIcon {...props} />}
              variant="outlined"
              labelId="select-user"
              id="select-user"
              value={user}
              className={classes.selectBox}
              onChange={(event) => {
                const selectedUser = event.target.value;
                setUser(selectedUser);
                console.log("Selected user: " + selectedUser); // Ensure that the correct user is logged
              }}
              style={{ marginRight: "10px" }}
              aria-label="Select User"
            >
              {data.map((domain) => (
                <MenuItem key={domain.email} value={domain.email}>
                  {`${domain.name}-${domain.email}`}
                </MenuItem>
              ))}
            </Select>
            <br />
            <br />
            <label htmlFor="select-domain" style={{ marginRight: "10px" }}>
              Select Domain:
            </label>
            <br />
            <Select
              IconComponent={(props) => <ExpandMoreIcon {...props} />}
              variant="outlined"
              labelId="select-domain"
              id="select-domain"
              value={domain}
              className={classes.selectBox}
              onChange={(event) => {
                const selectedDomain = event.target.value;
                setDomain(selectedDomain);
                console.log(selectedDomain); // Ensure that the correct domain is logged
              }}
              style={{ marginRight: "20px" }}
              aria-label="Select Domain"
            >
              {allDomains
                .filter(
                  (domain) =>
                    domain.userEmail === user &&
                    (domain.environment === "production" ||
                      domain.environment === "local")
                )
                .map((filteredDomain) => (
                  <MenuItem key={filteredDomain._id} value={filteredDomain._id}>
                    {filteredDomain.domain}
                  </MenuItem>
                ))}
            </Select>
            {/* <TextField
              id="numeric-input"
              label="Enter Number of Links"
              type="number"
              InputProps={{ inputProps: { min: 1 } }} // Ensure only non-negative numbers are allowed
              value={numericValue}
              onChange={(event) => setNumericValue(event.target.value)}
              style={{ marginRight: '20px' }}
            /> */}
    
            <label htmlFor="select-links" style={{ marginRight: "10px" }}>
              Select Links:
            </label>
            <Select
              IconComponent={(props) => <ExpandMoreIcon {...props} />}
              variant="outlined"
              labelId="select-links"
              id="select-links"
              value={numericValue}
              className={classes.selectBox} // Add your class definition
              onChange={(event) => {
                const selectedValue = event.target.value;
                setNumericValue(selectedValue);
                console.log(selectedValue); // Logging the selected value
              }}
              style={{ marginRight: "20px" }}
              aria-label="Select Links"
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
    
            {/* Button to submit */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdate}
              aria-label="Update"
            >
              Update
            </Button>
          </Box>
        </CustomCardContainerHead>
      </>
    );
  }
}