import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';

import AccessibilityTable from "../components/tables/accessibilitytables/AccessibilityTable";
import SpellCheckTable from "../components/tables/spellCheckTables/SpellCheckTable";
import SqlDetection from "../components/tables/securitytables/SqlDetection";
import XSSDetection from "../components/tables/securitytables/XSSDetection";

import HTMLCSSTables from "../components/tables/htmlCssTables/CSSTables";
import history from "../@history";
import { getDomainLinkss } from "../store/actions/dashboardActions";

import {
  useLocation
} from "react-router-dom";


export default function LinkSelectionViolationReport() {
  const dispatch = useDispatch();

  const selectedDomain = useSelector(state => state.miscellaneous.selectedDomain);
  const selectedScanId = useSelector(state => state.miscellaneous.selectedScanId);
  const selectedScan = useSelector(state => state.miscellaneous.selectedScan);

  const [linky, setLink] = React.useState(null);

  const links = useSelector(state => state.customScanList.links);

  const selectedDomainId = useSelector(state => state.dashboard.selectedDomainId);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  React.useEffect(() => {
    dispatch(getDomainLinkss(selectedDomainId))
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (links) {
      const linkClicked = query.get("link");
      if (links.links.includes(linkClicked)) {
        window.history.replaceState(null, null, `?link=${linkClicked}`);
        setLink(query.get("link"));
      }
      else {
        window.history.replaceState(null, null, `?link=not-found`);

        history.push("/not-found");
      }
    }
    // eslint-disable-next-line
  }, [links]);

  const { isPackagePurchased } = useSelector(state => state.auth.user);


  if (!isPackagePurchased) {
    history.push('/')
  }
  else {
    return (
      <>
        <div>
          {selectedScan && linky ? (
            <>
              <Box my={3}>
                <Typography variant="h4" style={{ textAlign: "center" }}>
                  Accessibility Tables
                </Typography>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <AccessibilityTable
                      selectedDomain={selectedDomain}
                      selectedDomainDate={selectedScan}
                      scanId={selectedScanId}
                      link={linky}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box my={4}>
                <Typography variant="h4" style={{ textAlign: "center" }}>
                  Security Tables
                </Typography>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <SqlDetection
                      selectedDomain={selectedDomain}
                      selectedDomainDate={selectedScan}
                      scanId={selectedScanId}
                      link={linky}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <XSSDetection
                      selectedDomain={selectedDomain}
                      selectedDomainDate={selectedScan}
                      scanId={selectedScanId}
                      link={linky}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box my={4}>
                <HTMLCSSTables
                  selectedDomain={selectedDomain}
                  selectedDomainDate={selectedScan}
                  scanId={selectedScanId}
                  link={linky}
                />
              </Box>
              <Box my={4}>
                <Typography variant="h4" style={{ textAlign: "center" }}>
                  Spell Check Tables
                </Typography>
                <SpellCheckTable
                  selectedDomain={selectedDomain}
                  selectedDomainDate={selectedScan}
                  scanId={selectedScanId}
                  link={linky}
                />
              </Box>
            </>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }
}