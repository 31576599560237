


import { ErrorBoundary } from 'react-error-boundary'

import React from 'react';

import { Card, Typography } from '@material-ui/core';

export default function ErrorHandler({ children }) {


    function ErrorFallback() {


        return (
            <Card role="alert" aria-labelledby="error-title" aria-describedby="error-description" style={{
                padding: 15,
                textAlign: 'center', maxWidth: 300, margin: 'auto'
            }} >
                <Typography id="error-title" variant='h6' marginBottom={1}>Something went wrong</Typography>
                <Typography id="error-description" variant='caption' fontWeight='regular'>Contact us at: support@allyright.com
                </Typography>
            </Card>
        )
    }

    return (
        <ErrorBoundary
            onError={(error) => {
            }}
            FallbackComponent={ErrorFallback}
        >
            {children}
        </ErrorBoundary>
    )
}



