import React from "react";

const styles = {
  statusIconOffline: {
    border: '1px solid white',
    height: 10,
    width: 10,
    backgroundColor: 'grey',
    borderRadius: '50%',
    display: 'inline-block',
    boxShadow: '2px 3px 3px #A8A8A8',
    marginBottom: "11px"
  }
}

export default function OfflineIcon() {
  return (
    <div 
    style={styles.statusIconOffline}
    aria-label="Offline status"
    role="status"
    aria-hidden="true" 
    ></div>
  );
}