import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

export default function PayWall() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%"
      }}
      role="alert" // Indicates an important message
    >
      <Card 
        style={{ boxShadow: "0px 3px 25px rgba(0 0 0 / 15%)", maxWidth: "400px" }} 
        aria-labelledby="subscription-heading"
      >
        <CardContent style={{ padding: 24, textAlign: 'center' }}>
          <h4 id="subscription-heading" style={{ margin: 0, marginBottom: 20 }}>
            Please Subscribe to Package
          </h4>
          <Button 
            component={Link} 
            to="/billing" 
            variant="contained" 
            color="primary"
            aria-label="Buy now for subscription package" // Provides context for the button
          >
            Buy Now
          </Button>
        </CardContent>
      </Card>
    </div>
  );
}