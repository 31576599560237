import React from 'react';
import Box from '@material-ui/core/Box';
import loaderwave from '../../assets/loader.svg';

function Loader() {
  return (
    <Box display="flex" justifyContent='center' alignItems='center' style={{height:"100vh"}} role="status" aria-live="polite">
      <img alt="loader" src={loaderwave} />
    </Box >
  )
}

export default Loader;
