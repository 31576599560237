import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button, CircularProgress } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import {getPageTags, getAllImagesLinks, getCopyrightLinksLatest, purposeFixImagesLinksAI, getCopyrightLinks, FixImagesLinksWP, getPluginStatus, getCrawlerStatus, FixMetaTags } from "../../store/actions/SeoTagActions";
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Loader from "../../components/commons/Loader";
import { toast } from "react-toastify";
import CustomCardContainerHead from "../../components/commons/CustomCardContainerHead";
import { LinearProgress } from '@material-ui/core';
import Box from "@material-ui/core/Box";
export default function DomainImages({ domain, selectedScanId }) {
  const isSelectedDomainLink = useSelector(
    (state) => state.dashboard.selectedLink
  );

  const [loadingLinks, setLoadingLinks] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [editedValues, setEditedValues] = useState({});
  const [editedCaptions, setEditedCaptions] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(25); // Default value
  const [showProgress2, setShowProgress2] = useState(false);
  const [currentPage2, setCurrentPage2] = React.useState(1);
  const [plugindata, setPluginData] = React.useState([]);
  const [pluginInstalled, setPluginInstalled] = useState(false);
  const [resolvedIssues, setResolvedIssues] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [suggestedCaptionCheckedRows, setSuggestedCaptionCheckedRows] = useState([]);
  const totalIssues = selectedRows.length
  const itemsPerPage = rowsPerPage;


  // Calculate progress percentage
  const progress = totalIssues > 0 ? (resolvedIssues / totalIssues) * 100 : 0;

  // Simulating a process with setTimeout
  React.useEffect(() => {
    // Simulate a process taking 3 seconds to complete
    const process = setTimeout(() => {
      // Once the process is completed
      setLoading(false); // Hide the loader
      // Additional actions or state changes upon success can go here
    }, 4000);

    // Clear the timeout if component unmounts or if process succeeds before 3 seconds
    return () => clearTimeout(process);
  }, []);



  // Function to update the "Fix All" button text based on selection
  const getFixButtonText = () => {
    if (selectedRows.length === 0) {
      // No rows selected, hide the button
      return "Fix";
    } else if (selectedRows.length === data.length) {
      // All rows selected, set text to "Fix All"
      return "Fix All";
    } else {
      // Multiple rows selected, set text to "Fix Selected"
      return "Fix Selected";
    }
  };
  // const itemsPerPage = 5; // Number of items to display per page

  // Step 2: Function to toggle selection for a specific row
  const toggleRowSelection = (image) => {
    if (selectedRows.includes(image)) {
      setSelectedRows(selectedRows.filter((row) => row !== image));

    } else {
      setSelectedRows([...selectedRows, image]);
    }
  };

  // const getImageDataForSelectedRows = async () => {
  //   const selectedImagesData = data.filter((image) =>
  //     selectedRows.includes(image)
  //   );

  //   if (selectedImagesData.length > 0) {
  //     setShowProgress(true);
  //     for (let i = 0; i < selectedImagesData.length; i++) {
  //       const image = selectedImagesData[i];

  //       const isSuggestedCaptionChecked = suggestedCaptionCheckedRows.includes(image);

  //       const updatedImage = {
  //         metaCharset: image.seoTags.metaCharset,
  //         metaRefreshTag: image.seoTags.metaRefreshTag,
  //         metaKeywordsTag: image.seoTags.metaKeywordsTag,
  //         viewportMetaTag: image.seoTags.viewportMetaTag,
  //         robotMetaTag: image.seoTags.robotsMetaTags[0],
  //         canonicalLink: image.seoTags.canonicalLink[0],
  //         link: image.link,
  //         domain: new URL(image.link).hostname
  //       };
  //       try {
  //         const response = await dispatch(FixMetaTags(selectedScanId, updatedImage));
  //         if (response) {
  //           setLoadingLinks(false);
  //           setResolvedIssues(prevResolvedIssues => prevResolvedIssues + 1);
  //           // toast.success(response.response.message)
  //         } else {
  //           setLoadingLinks(false);
  //           // toast.warning("AI Suggestion not available at this time")
  //         }
  //       } catch (err) {
  //         setLoadingLinks(false);
  //         // toast.warning("AI Suggestion not available at this time")
  //       }

  //       if (resolvedIssues === selectedRows.length) {
  //         setShowProgress(false);
  //       }
  //     }
  //   } else {
  //     toast.warning("Select at least one row to perform action", {
  //       position: "top-right",
  //       autoClose: 2000,
  //     });
  //   }
  // };

  const getImageDataForSelectedRows = async () => {
    const selectedImagesData = data.filter((image) =>
      selectedRows.includes(image)
    );
  
    if (selectedImagesData.length > 0) {
      setShowProgress(true);
      for (let i = 0; i < selectedImagesData.length; i++) {
        const image = selectedImagesData[i];
  
        const isSuggestedCaptionChecked = suggestedCaptionCheckedRows.includes(image);
  
        const updatedImage = {
          metaCharset: editedValues[image.link]?.metaCharset || image.seoTags.metaCharset,
          metaRefreshTag: editedValues[image.link]?.metaRefreshTag || image.seoTags.metaRefreshTag,
          metaKeywordsTag: editedValues[image.link]?.metaKeywords || image.seoTags.metaKeywords,
          viewportMetaTag: editedValues[image.link]?.viewportMetaTag || image.seoTags.viewportMetaTag,
          robotMetaTag: image.seoTags.robotsMetaTags && image.seoTags.robotsMetaTags.length > 0 ? image.seoTags.robotsMetaTags[0] : '', // Ensure default if undefined
          canonicalLink: image.seoTags.canonicalLink && image.seoTags.canonicalLink.length > 0 ? image.seoTags.canonicalLink[0] : '', // Ensure default if undefined
          link: image.link,
          domain: new URL(image.link).hostname
        };
  
        try {
          const response = await dispatch(FixMetaTags(selectedScanId, updatedImage));
          if (response) {
            setLoadingLinks(false);
            setResolvedIssues(prevResolvedIssues => prevResolvedIssues + 1);
          } else {
            setLoadingLinks(false);
          }
        } catch (err) {
          setLoadingLinks(false);
        }
  
        if (resolvedIssues === selectedRows.length) {
          setShowProgress(false);
        }
      }
    } else {
      toast.warning("Select at least one row to perform action", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };
  // Function to handle select all checkbox
  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(currentItems);
    }
    setSelectAll(!selectAll);
  };

  const handleRowsPerPageChange = (e) => {
    const newRowsPerPage = parseInt(e.target.value);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset to the first page when rows per page changes
    getDomains(); // Call the API with the new rows per page value
  };

  const dispatch = useDispatch();

  // Function to remove duplicates based on image_url property
  function removeDuplicates(arr, prop) {
    return arr.filter((obj, index, self) =>
      index === self.findIndex((el) => (
        el[prop] === obj[prop]
      ))
    );
  }

  function getDomains() {
    console.log("here");
    console.log(selectedScanId);
    dispatch(getPageTags(selectedScanId))
      .then((data) => {
        if (data) {
          setLoadingLinks(false);
          console.log(data.result)
          let filteredLinks = [];
          if (isSelectedDomainLink && isSelectedDomainLink.trim() !== "") {
            console.log("Selected Link:", isSelectedDomainLink);
            filteredLinks = data.result.filter(item => {
              const doesMatch = item.link === isSelectedDomainLink.toString();
              console.log(`Link: ${item.link}, Matches: ${doesMatch}`);  // Debugging each link
              return doesMatch;
            }); 
            console.log("Filtered Links:", filteredLinks);
          } else {
            filteredLinks = data.result?.filter(link => link !== null) || [];
          }
          // let uniqueObjects = removeDuplicates(data.links, 'image_url');
          // const allImages = uniqueObjects.flatMap(
          //   (link) => link || []
          // );
          setData(filteredLinks);
        } else {
          setLoadingLinks(false);
        }
      })
      .catch((err) => {
        setLoadingLinks(false);
      });
  }

  async function getPluginStatus2() {
    try {
      const response = await dispatch(getPluginStatus(domain));
      console.log(response.response.response);
      if (response && response.response.response === 200) {
        setPluginInstalled(true);
        setLoadingLinks(false);
        // console.log(response.response.result);
      } else {
        setPluginInstalled(false);
        setLoadingLinks(false);
        // toast.error("Failed to retrieve contact details.");
      }
    } catch (error) {
      setPluginInstalled(false);
      setLoadingLinks(false);
    }
  }

  React.useEffect(() => {
    getDomains();
    console.log("Selected Link:", isSelectedDomainLink);
}, [isSelectedDomainLink]);

  React.useEffect(() => {
    setLoadingLinks(true);
    getDomains();
  }, [currentPage]);

  const totalPages = Math.ceil(data.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  let itemsPerPage2 = null
  let totalPages2 = null
  let indexOfLastItem2 = null
  let indexOfFirstItem2 = null
  let currentItems2 = null


  // Pagination for the second table (plugins)
  if (plugindata) {
    itemsPerPage2 = 10; // Number of items to display per page
    totalPages2 = Math.ceil(plugindata.length / itemsPerPage2);
    indexOfLastItem2 = currentPage2 * itemsPerPage2;
    indexOfFirstItem2 = indexOfLastItem2 - itemsPerPage2;
    currentItems2 = plugindata.slice(indexOfFirstItem2, indexOfLastItem2);
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageChange2 = (newPage) => {
    setCurrentPage2(newPage);
  };

  const downloadFileInNewTab = () => {
    // Set the relative path to the file in the public folder
    const fileURL = '/plugins/AllyRight.zip';

    // Open a new tab and start the downloadFollow
  };


  // Function to handle input changes for editing values
const handleInputChange = (imageId, field, value) => {
  setEditedValues((prev) => ({
    ...prev,
    [imageId]: {
      ...prev[imageId],
      [field]: value,
    },
  }));
};


  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {!pluginInstalled ? (
            <>
            {/* Table 1 */}
              <CustomCardContainerHead title={"Seo Tags"} titleTag="h1">
                {!pluginInstalled ? (
                                <Box px={4} mt={2}>
                                {" "}
                                <h3 style={{ marginBottom: 0, display: "inline" }}>
                                  Fix button will remain disabled until you install AllyRight plugin
                                </h3>{" "}
                              </Box>
                ) : (null)}
                <div style={{ overflowX: "auto", maxWidth: "100%" }}>
                  <TableContainer component={Paper} style={{ overflowX: "auto" }}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={toggleSelectAll}
                            />
                          </TableCell>
                          <TableCell style={{ whiteSpace: "nowrap", maxWidth: "400px" }}><button onClick={() => getImageDataForSelectedRows()} disabled={!pluginInstalled}>{getFixButtonText()}</button></TableCell>
                          <TableCell style={{ whiteSpace: "nowrap", maxWidth: "200px" }}>Link</TableCell>
                          {/* <TableCell style={{ whiteSpace: "nowrap", maxWidth: "500px" }}>Title Tag</TableCell> */}
                          <TableCell style={{ whiteSpace: "nowrap", maxWidth: "500px" }}>Meta Charset</TableCell>
                          <TableCell style={{ whiteSpace: "nowrap", maxWidth: "500px" }}>Meta Refresh Tag</TableCell>
                          <TableCell style={{ whiteSpace: "nowrap", maxWidth: "500px" }}>Meta Keywords Tag</TableCell>
                          <TableCell style={{ whiteSpace: "nowrap", maxWidth: "500px" }}>Viewport Meta Tag</TableCell>
                          <TableCell style={{ whiteSpace: "nowrap", maxWidth: "700px" }}>Canonical Link</TableCell>
                          <TableCell style={{ whiteSpace: "nowrap", maxWidth: "700px" }}>Robot Meta Tag</TableCell>
                          {/* <TableCell style={{ whiteSpace: "nowrap", maxWidth: "700px" }}>Meta Description</TableCell> */}
                          {/* <TableCell style={{ whiteSpace: "nowrap", maxWidth: "500px" }}>Copyright Images</TableCell> */}
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      {!loadingLinks ? (
                        <TableBody>
                          {currentItems.length > 0 ? (
                            currentItems.map((image, index) => (
                              <React.Fragment key={index}>
                                <TableRow>
                                  <TableCell>
                                    <input
                                      type="checkbox"
                                      checked={selectedRows.includes(image)}
                                      onChange={() => toggleRowSelection(image)}
                                    />
                                  </TableCell>
                                  <TableCell>

                                  </TableCell>
                                  <TableCell style={{ whiteSpace: "nowrap", minWidth: "500px", maxWidth: "500px" }}>
                                    {image.link}
                                  </TableCell>
                                  {/* <TableCell style={{ whiteSpace: "nowrap", minWidth: "400px", maxWidth: "400px" }}>
                                    <div>
                                      {image.seoTags.metaCharset.toString()}
                                    </div>
                                  </TableCell> */}
                                  <TableCell style={{ whiteSpace: "nowrap", minWidth: "400px", maxWidth: "400px" }}>
  <input
    type="text"
    value={editedValues[image.link]?.metaCharset || image.seoTags.metaCharset}
    onChange={(e) => handleInputChange(image.link, 'metaCharset', e.target.value)}
  />
</TableCell>
                                  {/* <TableCell style={{ whiteSpace: "nowrap", minWidth: "400px", maxWidth: "400px" }}>
                                    <div>
                                      {image.seoTags.metaRefreshTag}
                                    </div>
                                  </TableCell> */}

<TableCell style={{ whiteSpace: "nowrap", minWidth: "400px", maxWidth: "400px" }}>
  <input
    type="text"
    value={editedValues[image.link]?.metaRefreshTag || image.seoTags.metaRefreshTag}
    onChange={(e) => handleInputChange(image.link, 'metaRefreshTag', e.target.value)}
  />
</TableCell>
                                  {/* <TableCell style={{ whiteSpace: "nowrap", minWidth: "400px", maxWidth: "400px" }}>
                                    <div>
                                      {image.seoTags.metaKeywords.toString()}
                                    </div>
                                  </TableCell> */}
                                  <TableCell style={{ whiteSpace: "nowrap", minWidth: "400px", maxWidth: "400px" }}>
  <input
    type="text"
    value={editedValues[image.link]?.metaKeywords || image.seoTags.metaKeywords}
    onChange={(e) => handleInputChange(image.link, 'metaKeywords', e.target.value)}
  />
</TableCell>
                                  <TableCell style={{ whiteSpace: "nowrap", minWidth: "400px", maxWidth: "400px" }}>
                                    <div>
                                      {image.seoTags.viewportMetaTag.toString()}
                                    </div>
                                  </TableCell>
                                  <TableCell style={{ whiteSpace: "nowrap", minWidth: "700px", maxWidth: "700px" }}>
                                    <div>
                                      {image.seoTags.canonicalLinks ? image.seoTags.canonicalLinks[0] : "No canonical link found"}
                                    </div>
                                  </TableCell>
                                  {/* <TableCell style={{ whiteSpace: "nowrap", minWidth: "700px", maxWidth: "700px" }}>
                                    <div>
                                      {image.seoTags.robotsMetaTags ? image.seoTags.robotsMetaTags[0] : "No robot meta tag found"}
                                    </div>
                                  </TableCell> */}
                                  
                                  <TableCell style={{ whiteSpace: "nowrap", minWidth: "400px", maxWidth: "400px" }}>
  <input
    type="text"
    value={
      editedValues[image.link]?.robotsMetaTags?.length > 0
        ? editedValues[image.link].robotsMetaTags
        : image.seoTags.robotsMetaTags?.length > 0
        ? image.seoTags.robotsMetaTags[0]
        : ''
    }
    onChange={(e) => handleInputChange(image.link, 'robotsMetaTags', e.target.value)}
  />
</TableCell>
                                  {/* <TableCell style={{ whiteSpace: "nowrap", minWidth: "700px", maxWidth: "700px" }}>
                                    {image.seoTags.metaDescription}
                                  </TableCell> */}
                                </TableRow>
                              </React.Fragment>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={5}>no records found</TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      ) : (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={5}>
                              <CircularProgress />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginBottom: "10px" }}>
                  <Typography variant="body1" style={{ marginRight: "10px" }}>Number of entries:</Typography>
                  <TextField
                    type="number"
                    variant="outlined"
                    color="primary"
                    size="small"
                    value={rowsPerPage}
                    onChange={handleRowsPerPageChange}
                    InputProps={{
                      inputProps: { min: 1, max: 1000 }, // Minimum and maximum value allowed
                      style: { width: "5em" }, // Minimum value allowed
                    }}
                  />
                  <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
                </div>
              </CustomCardContainerHead>



                        {/* Table 2 */}
                        <CustomCardContainerHead title={"Social Meta Tags"} titleTag="h1">
                        {!pluginInstalled ? (
                                <Box px={4} mt={2}>
                                {" "}
                                <h3 style={{ marginBottom: 0, display: "inline" }}>
                                  Fix button will remain disabled until you install AllyRight plugin
                                </h3>{" "}
                              </Box>
                ) : (null)}
                        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
                          <TableContainer component={Paper} style={{ overflowX: "auto" }}>
                            <Table aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    <input
                                      type="checkbox"
                                      checked={selectAll}
                                      onChange={toggleSelectAll}
                                    />
                                  </TableCell>
                                  <TableCell style={{ whiteSpace: "nowrap", maxWidth: "400px" }}><button onClick={() => getImageDataForSelectedRows()} disabled={!pluginInstalled}>{getFixButtonText()}</button></TableCell>
                                  <TableCell style={{ whiteSpace: "nowrap", maxWidth: "200px" }}>Link</TableCell>
                                  <TableCell style={{ whiteSpace: "nowrap", maxWidth: "500px" }}>OG Title</TableCell>
                                  
                                  <TableCell style={{ whiteSpace: "nowrap", maxWidth: "700px" }}>OG Url</TableCell>
                                  <TableCell style={{ whiteSpace: "nowrap", maxWidth: "700px" }}>OG Image</TableCell>
                                  <TableCell style={{ whiteSpace: "nowrap", maxWidth: "700px" }}>OG Description</TableCell>
                                  {/* <TableCell style={{ whiteSpace: "nowrap", maxWidth: "500px" }}>Copyright Images</TableCell> */}
                                  <TableCell></TableCell>
                                </TableRow>
                              </TableHead>
                              {!loadingLinks ? (
                                <TableBody>
                                  {currentItems.length > 0 ? (
                                    currentItems.map((image, index) => (
                                      <React.Fragment key={index}>
                                        <TableRow>
                                          <TableCell>
                                            <input
                                              type="checkbox"
                                              checked={selectedRows.includes(image)}
                                              onChange={() => toggleRowSelection(image)}
                                            />
                                          </TableCell>
                                          <TableCell>
        
                                          </TableCell>
                                          <TableCell style={{ whiteSpace: "nowrap", minWidth: "500px", maxWidth: "500px" }}>
                                            {image.link}
                                          </TableCell>
                                          <TableCell style={{ whiteSpace: "nowrap", minWidth: "400px", maxWidth: "400px" }}>
                                            <div>
                                              {image.seoTags.socialMetaTags.ogTitle.toString()}
                                            </div>
                                          </TableCell>
                                          <TableCell style={{ whiteSpace: "nowrap", minWidth: "700px", maxWidth: "700px" }}>
                                            {image.seoTags.socialMetaTags.ogUrl}
                                          </TableCell>
                                          <TableCell style={{ whiteSpace: "nowrap", minWidth: "700px", maxWidth: "700px" }}>
                                            {image.seoTags.socialMetaTags.ogImage}
                                          </TableCell>
                                          <TableCell style={{ whiteSpace: "nowrap", minWidth: "700px", maxWidth: "700px" }}>
                                            {image.seoTags.socialMetaTags.ogDescription}
                                          </TableCell>
                                        </TableRow>
                                      </React.Fragment>
                                    ))
                                  ) : (
                                    <TableRow>
                                      <TableCell colSpan={5}>no records found</TableCell>
                                    </TableRow>
                                  )}
                                </TableBody>
                              ) : (
                                <TableBody>
                                  <TableRow>
                                    <TableCell align="center" colSpan={5}>
                                      <CircularProgress />
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              )}
                            </Table>
                          </TableContainer>
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginBottom: "10px" }}>
                          <Typography variant="body1" style={{ marginRight: "10px" }}>Number of entries:</Typography>
                          <TextField
                            type="number"
                            variant="outlined"
                            color="primary"
                            size="small"
                            value={rowsPerPage}
                            onChange={handleRowsPerPageChange}
                            InputProps={{
                              inputProps: { min: 1, max: 1000 }, // Minimum and maximum value allowed
                              style: { width: "5em" }, // Minimum value allowed
                            }}
                          />
                          <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
                        </div>
                      </CustomCardContainerHead>
                                    
                                    
                      {/* Table 3 */}
                     <CustomCardContainerHead title={"Image Tags"} titleTag="h1">
                     {!pluginInstalled ? (
                                <Box px={4} mt={2}>
                                {" "}
                                <h3 style={{ marginBottom: 0, display: "inline" }}>
                                  Fix button will remain disabled until you install AllyRight plugin
                                </h3>{" "}
                              </Box>
                ) : (null)}
                        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
                          <TableContainer component={Paper} style={{ overflowX: "auto" }}>
                            <Table aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    <input
                                      type="checkbox"
                                      checked={selectAll}
                                      onChange={toggleSelectAll}
                                    />
                                  </TableCell>
                                  <TableCell style={{ whiteSpace: "nowrap", maxWidth: "400px" }}><button onClick={() => getImageDataForSelectedRows()} disabled={!pluginInstalled}>{getFixButtonText()}</button></TableCell>
                                  <TableCell style={{ whiteSpace: "nowrap", maxWidth: "200px" }}>Link</TableCell>
                                  <TableCell style={{ whiteSpace: "nowrap", maxWidth: "700px" }}>Source Tag</TableCell>
                                  
                                  <TableCell style={{ whiteSpace: "nowrap", maxWidth: "700px" }}>ALT Tag</TableCell>
                                  {/* <TableCell style={{ whiteSpace: "nowrap", maxWidth: "500px" }}>Copyright Images</TableCell> */}
                                  <TableCell></TableCell>
                                </TableRow>
                              </TableHead>
                              {!loadingLinks ? (
                                <TableBody>
                                  {currentItems.length > 0 ? (
                                    currentItems.map((image, index) => (
                                      <React.Fragment key={index}>
                                        <TableRow>
                                          <TableCell>
                                            <input
                                              type="checkbox"
                                              checked={selectedRows.includes(image)}
                                              onChange={() => toggleRowSelection(image)}
                                            />
                                          </TableCell>
                                          <TableCell>
        
                                          </TableCell>
                                          <TableCell style={{ whiteSpace: "nowrap", minWidth: "500px", maxWidth: "500px" }}>
                                            {image.link}
                                          </TableCell>
                                          <TableCell style={{ whiteSpace: "nowrap", minWidth: "700px", maxWidth: "700px" }}>
                                          <div>
    {image.seoTags.importantAttributes.imageAlt && image.seoTags.importantAttributes.imageAlt.map((alt, index) => (
      <div key={index}>{alt.src}</div>
    ))}
  </div>
                                          </TableCell>
                                          <TableCell style={{ whiteSpace: "nowrap", minWidth: "700px", maxWidth: "700px" }}>
                                          <div>
    {image.seoTags.importantAttributes.imageAlt && image.seoTags.importantAttributes.imageAlt.map((alt, index) => (
      <div key={index}>{alt.alt}</div>
    ))}
  </div>
                                          </TableCell>
                                        </TableRow>
                                      </React.Fragment>
                                    ))
                                  ) : (
                                    <TableRow>
                                      <TableCell colSpan={5}>no records found</TableCell>
                                    </TableRow>
                                  )}
                                </TableBody>
                              ) : (
                                <TableBody>
                                  <TableRow>
                                    <TableCell align="center" colSpan={5}>
                                      <CircularProgress />
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              )}
                            </Table>
                          </TableContainer>
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginBottom: "10px" }}>
                          <Typography variant="body1" style={{ marginRight: "10px" }}>Number of entries:</Typography>
                          <TextField
                            type="number"
                            variant="outlined"
                            color="primary"
                            size="small"
                            value={rowsPerPage}
                            onChange={handleRowsPerPageChange}
                            InputProps={{
                              inputProps: { min: 1, max: 1000 }, // Minimum and maximum value allowed
                              style: { width: "5em" }, // Minimum value allowed
                            }}
                          />
                          <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
                        </div>
                      </CustomCardContainerHead>
            </>
          ) : (
            <CustomCardContainerHead title="Install Plugin" titleTag="h1">
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 20, marginBottom: 20 }} >
                <Typography variant="h4" style={{ fontSize: '24px' }}>
                  Plugin not installed please first install plugin on website
                </Typography>
              </div>
              <Button
                style={{ display: 'block', margin: 'auto', fontSize: '18px', backgroundColor: 'rgb(31, 58, 147)' }}
                variant="contained"
                color="primary"
                onClick={() => getPluginStatus2()}
              >
                Recheck
              </Button>

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 50, marginBottom: 20 }} >
                <Typography variant="h4" style={{ fontSize: '24px' }}>
                  Follow the following steps to install plugin on website:
                </Typography>
              </div>
              <div style={{ marginTop: 20, textAlign: 'center' }}>
                <Typography variant="body1">
                  {/* Step 1 */}
                  1. Download the plugin file from the download button below
                </Typography>
                <Typography variant="body1">
                  {/* Step 2 */}
                  2. Go to the wordpress dashboard and go to the plugin section
                </Typography>
                <Typography variant="body1">
                  {/* Step 3 */}
                  3. Click on the button upload plugin
                </Typography>
                <Typography variant="body1">
                  {/* Step 4 */}
                  4. Select the downloaded file from your download folder
                </Typography>
                <Typography variant="body1">
                  {/* Step 5 */}
                  5. Click on install plugin
                </Typography>
                <Typography variant="body1">
                  {/* Step 6 */}
                  6. Click on activate plugin
                </Typography>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 50, marginBottom: 20 }} >
                <Typography variant="h4" style={{ fontSize: '24px' }}>
                  Click below button to download plugin
                </Typography>
              </div>
              <Button
                style={{ display: 'block', margin: 'auto', fontSize: '18px', backgroundColor: 'rgb(31, 58, 147)' }}
                variant="contained"
                color="primary"
                onClick={() => downloadFileInNewTab()}
              >
                Download
              </Button>
            </CustomCardContainerHead>
          )}
        </>
      )}
    </>
  );
}

function Pagination({ totalPages, currentPage, onPageChange }) {
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", margin: "10px" }}>
      {currentPage > 1 && (
        <IconButton onClick={() => onPageChange(currentPage - 1)}>
          <ArrowBackIcon />
        </IconButton>
      )}
      {pageNumbers.map((page) => (
        <Button
          key={page}
          onClick={() => onPageChange(page)}
          style={{
            borderRadius: "20px",
            margin: "5px",
            backgroundColor: currentPage === page ? "#007bff" : "#fff",
            color: currentPage === page ? "#fff" : "#007bff",
          }}
        >
          {page}
        </Button>
      ))}
      {currentPage < totalPages && (
        <IconButton onClick={() => onPageChange(currentPage + 1)}>
          <ArrowForwardIcon />
        </IconButton>
      )}
    </div>
  );
}
