import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { getPerformanceVisualization } from "../../../../store/actions/performanceActions";
import LinearProgress from "@material-ui/core/LinearProgress";
import { CircularProgress, Box } from "@material-ui/core";

function PerformanceVisualization() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const scanId = useSelector((state) => state.miscellaneous.selectedScanId);

  const data = useSelector(
    (state) => state.performance.performance_visualization
  );
  const status = useSelector(
    (state) => state.performance.performance_visualization_status
  );
  const loading = useSelector(
    (state) => state.performance.performance_visualization_loading
  );

  React.useEffect(() => {
    if (scanId) {
      dispatch(getPerformanceVisualization(scanId));
    }
    // eslint-disable-next-line
  }, [scanId]);

  React.useEffect(() => {
    if (status === "running" || status === "pending") {
      const interval = setInterval(() => {
        if (scanId) {
          dispatch(getPerformanceVisualization(scanId));
        }
      }, 15000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [status]);

  return (
    <React.Fragment>
      {data && !loading ? (
        <div
          id="performance"
          style={{ display: "block" }}
          role="region"
          aria-labelledby="speed-visualization-heading"
        >
          <div>
            <div>
              <h3
                id="speed-visualization-heading"
                style={{
                  marginTop: 0,
                  fontSize: 20,
                  lineHeight: 1,
                  fontWeight: 500,
                }}
              >
                Speed Visualization
              </h3>
            </div>
            <div style={{ overflow: "auto" }}>
              <div className={classes.filmStripWrapper}>
                {data.map((item, i) => (
                  <div
                    className={classes.reportFilmstripThumbnail}
                    key={i}
                    tabIndex="0"
                    aria-label={`Thumbnail at ${item.timing} seconds`}
                  >
                    <img
                      src={`${item.data}`}
                      className={classes.thumbnailImg}
                      width="250px"
                      height="120px"
                      alt={`Thumbnail at ${item.timing} seconds`}
                    />
                    <p
                      style={{
                        color: "#585858",
                        fontSize: 18,
                        fontWeight: 400,
                      }}
                    >
                      {item.timing}s
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Box display="flex" justifyContent="center" py={2} role="status" aria-live="polite">
          <CircularProgress
            style={{
              width: "65px",
              height: "65px",
            }}
          />
        </Box>
      )}
    </React.Fragment>
  );
}
export default React.memo(PerformanceVisualization);

const useStyles = makeStyles((theme) => ({
  filmStripWrapper: {
    display: "flex",
    minWidth: 1000,
  },
  reportFilmstripThumbnail: {
    display: "flex",
    flexGrow: 1,
    flexBasis: 0,
    flexDirection: "column",
    alignItems: "center",
    margin: "0 5px",
    position: "relative",
    borderRadius: 10,
  },
  thumbnailImg: {
    height: "auto",
    border: "1px solid #e0e0e0",
    maxHeight: 200,
    objectFit: "contain",
    width: 250,
    borderRadius: 10,
  },
}));
