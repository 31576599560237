import axios from "axios";
import { BASE_URL } from "../../source/constants";
import { toast } from "react-toastify";
import auth0Service from "../../services/auth0Service/authentikService";

export const GET_ALL_SCANS = "GET_ALL_SCANS";
export const GET_ALL_SCANS_LOADING = "GET_ALL_SCANS_LOADING";
export const GET_ALL_DOMAINS = "GET_ALL_DOMAINS";





export const getAllImagesLinks = (scanId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },

    };
    const params = {
      scanId,
    }
    const res = await axios.get(`${BASE_URL}/sitemap/all-images`,
      {
        params, ...options
      }
    );
    if (res.data) {

      return res.data
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    } else {
      toast.warn(err.response.data.message);
    }
  }
}

export const FixMetaTags = (selectedScanId, imageData) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const requestBody = {
      scanId: selectedScanId,
      data: [imageData],
    }
    const res = await axios.post(
      `${BASE_URL}/sitemap/seo-tags`,
      requestBody,
      options
    );

    if (res.data) {
      return res.data;
    }
  } catch (err) {
    if (!err.response) {
      console.warn('Warning ⚠️ bad internet connection.');
    } else {
      console.warn(err.response.data.message);
    }
  }
};

export const getPageTags = (scanId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      // headers: {
      //   "Content-Type": "application/json",
      //   Authorization: `Bearer ${token}`,
      // },

    };
    const params = {
      scanId,
    }
    const res = await axios.get(`${BASE_URL}/dashboard/seo-tags`,
      {
        params
      }
    );
    if (res.data) {

      return res.data
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    } else {
      toast.warn(err.response.data.message);
    }
  }
}
  
  
  export const getAllAccessibilityIssues = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain,
      }
      const res = await axios.get(`${BASE_URL}/abracadabra-box/accessibility-issues`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        // toast.warn(err.response.data.message);
      }
    }
  }

  export const getAllSchemas = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain,
      }
      const res = await axios.get(`${BASE_URL}/abracadabra-box/website-schemas`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        // toast.warn(err.response.data.message);
      }
    }
  }

  export const getAllSchemasWp = (scanId) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        scanId,
      }
      const res = await axios.get(`${BASE_URL}/sitemap/all-schemas`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        // toast.warn(err.response.data.message);
      }
    }
  }
  
  
  
  export const getCopyrightLinks = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain
      }
      const res = await axios.get(`${BASE_URL}/abracadabra-box/all-copyright-images`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }
  
  export const getCopyrightLinksLatest = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain
      }
      const res = await axios.get(`${BASE_URL}/abracadabra-box/all-copyright-images-latest`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }
  
  
  export const purposeFixImagesLinks = (imageData) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const res = await axios.post(
        `${BASE_URL}/abracadabra-box/fix-images-ai`,
        {
          image_data: imageData,
        },
        options
      );
  
      if (res.data) {
        return res.data;
      }
    } catch (err) {
      if (!err.response) {
        console.warn('Warning ⚠️ bad internet connection.');
      } else {
        console.warn(err.response.data.message);
      }
    }
  };
  
  export const startRescan = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const res = await axios.post(
        `${BASE_URL}/abracadabra-box/rescan`,
        { domain },
        options
      );
  
      if (res.data) {
        return res.data;
      }
    } catch (err) {
      if (!err.response) {
        console.warn('Warning ⚠️ bad internet connection.');
      } else {
        console.warn(err.response.data.message);
      }
    }
  };
  
  export const DeleteScan = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain
      }
      const res = await axios.delete(`${BASE_URL}/abracadabra-box/delete-scan`, {
        params, ...options
      }
      );
  
      if (res.data) {
        return res.data;
      }
    } catch (err) {
      if (!err.response) {
        console.warn('Warning ⚠️ bad internet connection.');
      } else {
        console.warn(err.response.data.message);
      }
    }
  };
  
  // export const FixImagesLinksWP = (imageData) => async (dispatch) => {
  //   try {
  //     const token = auth0Service.getAccessToken();
  //     const options = {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  
  //     };
  //     const res = await axios.post(
  //       `${BASE_URL}/abracadabra-box/fix-wp-images`,
  //       imageData,
  //       options
  //     );
  
  //     if (res.data) {
  //       return res.data;
  //     }
  //   } catch (err) {
  //     if (!err.response) {
  //       console.warn('Warning ⚠️ bad internet connection.');
  //     } else {
  //       console.warn(err.response.data.message);
  //     }
  //   }
  // };
  
  export const FixImagesLinksWP = (selectedScanId, imageData) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const requestBody = {
        scanId: selectedScanId,
        data: [imageData],
      }
      const res = await axios.post(
        `${BASE_URL}/sitemap/fix-wp-images`,
        requestBody,
        options
      );
  
      if (res.data) {
        return res.data;
      }
    } catch (err) {
      if (!err.response) {
        console.warn('Warning ⚠️ bad internet connection.');
      } else {
        console.warn(err.response.data.message);
      }
    }
  };

  export const FixAccessibilityLinksWP = (accessibility_data) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const res = await axios.post(
        `${BASE_URL}/abracadabra-box/fix-accessibility-issues`,
        accessibility_data,
        options
      );
  
      if (res.data) {
        return res.data;
      }
    } catch (err) {
      if (!err.response) {
        console.warn('Warning ⚠️ bad internet connection.');
      } else {
        console.warn(err.response.data.message);
      }
    }
  };
  
  export const purposeFixImagesLinksAI = (imageData) => async (dispatch) => {
  
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
  
      const res = await axios.post(
        `${BASE_URL}/abracadabra-box/fix-images-ai`,
        {
          "image_data": [imageData]
        },
        options
      );
  
      if (res.data) {
        return res.data;
      }
    } catch (err) {
      if (!err.response) {
        console.warn('Warning ⚠️ bad internet connection.');
      } else {
        console.warn(err.response.data.message);
      }
    }
  };
  
  
  export const getDomainCopyrightYear = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain
      }
      const res = await axios.get(`${BASE_URL}/abracadabra-box/copyright-year`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }
  
  export const getDomainAbraAnalytics = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain
      }
      const res = await axios.get(`${BASE_URL}/abracadabra-box/abrakadabra-analytics`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }
  
  
  export const getDomainPrivacyPage = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain
      }
      const res = await axios.get(`${BASE_URL}/abracadabra-box/privacy-page`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }
  
  export const getDomainSkipToContent = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain
      }
      const res = await axios.get(`${BASE_URL}/abracadabra-box/skip-to-content`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }
  
  export const getContactInfo = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain
      }
      const res = await axios.get(`${BASE_URL}/abracadabra-box/contact-info`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }

  //New

  export const getContactInfoWp = (scanId) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        scanId
      }
      const res = await axios.get(`${BASE_URL}/sitemap/contact-details`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }
  
  export const getFooterInfo = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain
      }
      const res = await axios.get(`${BASE_URL}/abracadabra-box/footer-info`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }
  
  export const getUnfixedImages = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain
      }
      const res = await axios.get(`${BASE_URL}/abracadabra-box/unfixed-images`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }
  
  export const getAccessibilityElements = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain
      }
      const res = await axios.get(`${BASE_URL}/abracadabra-box/accessibility-fixes`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }
  
  
  export const getThemes = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain
      }
      const res = await axios.get(`${BASE_URL}/abracadabra-box/theme-info`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }

  export const getThemesWp = (scanId) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        scanId
      }
      const res = await axios.get(`${BASE_URL}/sitemap/theme-details`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        // toast.warn(err.response.data.message);
      }
    }
  }
  
  export const getModifiedPageLog = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain
      }
      const res = await axios.get(`${BASE_URL}/abracadabra-box/modified-logs`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }
  
  export const getSitemapInfo = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain
      }
      const res = await axios.get(`${BASE_URL}/abracadabra-box/sitemap-info`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }
  
  export const getWpVersion = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain
      }
      const res = await axios.get(`${BASE_URL}/abracadabra-box/wp-version`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }

  export const getWordpressVersionWp = (scanId) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        scanId
      }
      const res = await axios.get(`${BASE_URL}/sitemap/wordpress-version`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        // toast.warn(err.response.data.message);
      }
    }
  }
  
  export const getPlugins = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain
      }
      const res = await axios.get(`${BASE_URL}/abracadabra-box/plugin-info`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }

  export const getPluginsWp = (scanId) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        scanId
      }
      const res = await axios.get(`${BASE_URL}/sitemap/plugin-details`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        // toast.warn(err.response.data.message);
      }
    }
  }
  
  export const getPluginLogs = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain
      }
      const res = await axios.get(`${BASE_URL}/abracadabra-box/plugin-logs`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }

  //New API

  export const getPluginLogsWordpress = (scanId) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        scanId
      }
      const res = await axios.get(`${BASE_URL}/sitemap/plugin-logs`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }
  
  export const getDomainTechnologies = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain
      }
      const res = await axios.get(`${BASE_URL}/abracadabra-box/technologies`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        // toast.warn(err.response.data.message);
      }
    }
  }
  
  
  export const getAccessibilityStatement = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain
      }
      const res = await axios.get(`${BASE_URL}/abracadabra-box/accessibility-statement`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }


  //New 
  export const getAccessibilityStatementWp = (scanId) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        scanId
      }
      const res = await axios.get(`${BASE_URL}/sitemap/privacy-accessibility`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }
  
  export const createWorpressOptimization = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const res = await axios.post(`${BASE_URL}/abracadabra-box/wordpress-optimization`,
        {
          domain
        }, options
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }
  
  export const getPrivacyPolicy = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain
      }
      const res = await axios.get(`${BASE_URL}/abracadabra-box/privacy-policy`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }
  
  export const createSkiptoContent = (domain, action) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const requestBody = {
        domain,
        action
      }
      const res = await axios.post(`${BASE_URL}/abracadabra-box/create/skip-to-content`,
        requestBody, options
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }

  //New
  export const createSkiptoContentWp = (scanId, action) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const requestBody = {
        scanId,
        action
      }
      const res = await axios.post(`${BASE_URL}/sitemap/skip-to-content`,
        requestBody, options
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }
  
  export const updateContent = (domain, post_id, search_text, replace_text, contact, contactHref, emailaddress, emailaddresshref, footerText, footerTextHref) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const requestBody = {
        domain,
        post_id,
        search_text,
        replace_text,
        contact: contact,
        contactHref: contactHref,
        emailaddress: emailaddress,
        emailaddresshref: emailaddresshref,
        footerText: footerText,
        footerTextHref: footerTextHref
      }
      const res = await axios.post(`${BASE_URL}/abracadabra-box/update-content`,
        requestBody, options
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }

  //New
  export const updateContentWp = (scanId, post_id, search_text, replace_text, contact, contactHref, emailaddress, emailaddresshref, footerText, footerTextHref) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const requestBody = {
        scanId,
        post_id,
        search_text,
        replace_text,
        contact: contact,
        contactHref: contactHref,
        emailaddress: emailaddress,
        emailaddresshref: emailaddresshref,
        footerText: footerText,
        footerTextHref: footerTextHref
      }
      const res = await axios.post(`${BASE_URL}/sitemap/update-contact-info`,
        requestBody, options
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }


  export const updateJsonLd = (domain, page_link, json_ld_data) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const requestBody = {
        domain,
        page_link,
        json_ld_data
      }
      const res = await axios.post(`${BASE_URL}/abracadabra-box/update-json-ld`,
        requestBody, options
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }

  //New

  export const updateJsonLdWP = (scanId, page_link, json_ld_data) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const requestBody = {
        scanId,
        page_link,
        json_ld_data
      }
      const res = await axios.post(`${BASE_URL}/sitemap/update-json-ld-wp`,
        requestBody, options
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }
  
  export const createHighContrast = (domain, action) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const requestBody = {
        domain,
        action
      }
      const res = await axios.post(`${BASE_URL}/abracadabra-box/create/high-contrast`,
        requestBody, options
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }

  //New
  export const createHighContrastWp = (scanId, action) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const requestBody = {
        scanId,
        action
      }
      const res = await axios.post(`${BASE_URL}/sitemap/high-contrast`,
        requestBody, options
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }

  export const createFileAccess = (scanId, domain, action) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const requestBody = {
        scanId: scanId,
        domain,
        status: action
      }
      const res = await axios.post(`${BASE_URL}/privacy/file-restrictions`,
        requestBody, options
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }

  export const createWpVersionVisibility = (scanId, domain, action) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const requestBody = {
        scanId: scanId,
        domain,
        status: action
      }
      const res = await axios.post(`${BASE_URL}/privacy/wordpress-version-visibility`,
        requestBody, options
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }

  export const createPingback = (scanId, domain, action) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const requestBody = {
        scanId,
        domain,
        status: action
      }
      const res = await axios.post(`${BASE_URL}/privacy/update-pingback-status-wp`,
        requestBody, options
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }

  export const createXmlRpc = (scanId, domain, action) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const requestBody = {
        scanId: scanId,
        domain,
        enable: action
      }
      const res = await axios.post(`${BASE_URL}/privacy/update-xmlrpc-status-wp`,
        requestBody, options
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }

  export const deleteInactiveUsers = (scanId, domain, action) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const requestBody = {
        scanId: scanId,
        domain,
        status: action
      }
      const res = await axios.post(`${BASE_URL}/privacy/delete-inactive-user-wordpress`,
        requestBody, options
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }

  export const SecurityKeyEncryprion = (scanId, domain, action) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const requestBody = {
        scanId: scanId,
        domain,
        status: action
      }
      const res = await axios.post(`${BASE_URL}/privacy/security-encryption-wp`,
        requestBody, options
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }

  export const FileEdit = (scanId, domain, action) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const requestBody = {
        scanId: scanId,
        domain,
        status: action
      }
      const res = await axios.post(`${BASE_URL}/privacy/file-editing-wp`,
        requestBody, options
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }
  
  export const disableInactivePluginTheme = (scanId, domain, action) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const requestBody = {
        scanId: scanId,
        domain,
        status: action
      }
      const res = await axios.post(`${BASE_URL}/privacy/disable-unused-plugin-themes`,
        requestBody, options
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }

  // export const createCopyrightYear = (domain, action) => async (dispatch) => {
  //   try {
  //     const options = {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  
  //     };
  //     const requestBody = {
  //       domain,
  //       action
  //     }
  //     const res = await axios.post(`${PLUGIN_BASE_URL}/api/v1/create/copyright-year`,
  //       {
  //         requestBody, ...options
  //       }
  //     );
  //     if (res.data) {
  
  //       return res.data
  //     }
  //   } catch (err) {
  //     if (!err.response) {
  //       toast.warn('Warning ⚠️ bad internet connection.');
  //     } else {
  //       toast.warn(err.response.data.message);
  //     }
  //   }
  // }
  
  export const createPages = (domain, type, data) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const requestBody = {
        domain,
        type,
        data
      }
      const res = await axios.post(`${BASE_URL}/abracadabra-box/create/pages`,
        requestBody, options
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }

  //New
  export const createPagesWp = (scanId, type, data) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const requestBody = {
        scanId,
        type,
        data
      }
      const res = await axios.post(`${BASE_URL}/sitemap/create-pages`,
        requestBody, options
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }
  
  
  export const updatePages = (domain, type, data, page_id) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const requestBody = {
        domain,
        type,
        data,
        page_id
      }
      const res = await axios.post(`${BASE_URL}/abracadabra-box/update/pages`,
        requestBody, options
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }

  //New

  export const updatePagesWp = (scanId, type, data, page_id) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const requestBody = {
        scanId,
        type,
        data,
        page_id
      }
      const res = await axios.post(`${BASE_URL}/sitemap/update-pages`,
        requestBody, options
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }
  
  export const createWpLogin = (domain, action, wpLogin, wpAdmin) => async (dispatch) => {
    try {
      const options = {
        headers: {
          "Content-Type": "application/json",
        },
  
      };
      const requestBody = {
        domain,
        action,
        "wp-login.php": wpLogin,
        "wp-admin": wpAdmin
      }
      const res = await axios.post(`${BASE_URL}/api/v1/create/wplogin`,
        {
          requestBody, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }
  
  export const checkSkipToContentInfo = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain
      }
      const res = await axios.get(`${BASE_URL}/abracadabra-box/skip-to-content-info`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }

  //New
  export const checkSkipToContentInfoWp = (scanId) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        scanId
      }
      const res = await axios.get(`${BASE_URL}/sitemap/skip-to-content-status`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }

  export const checkWpVersionStatusInfo = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain
      }
      const res = await axios.get(`${BASE_URL}/abracadabra-box/wp-version-info`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }

  export const checkWpSecurityPatches = (scanId) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        scanId: scanId
      }

      const res = await axios.get(`${BASE_URL}/privacy/get-all-security-patches`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }

  export const checkPingbackInfo = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain
      }
      const res = await axios.get(`${BASE_URL}/abracadabra-box/pingback-info`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }

  export const checkXmlRpcInfo = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain
      }
      const res = await axios.get(`${BASE_URL}/abracadabra-box/xmlrpc-info`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }
  

  export const checkDeleteInactiveUserInfo = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain
      }
      const res = await axios.get(`${BASE_URL}/abracadabra-box/inactive-user-info`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }

  export const checkInactivePluginThemeInfo = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain
      }
      const res = await axios.get(`${BASE_URL}/abracadabra-box/inactive-plugin-theme-info`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }

  export const checkSecurityEncryptionInfo = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain
      }
      const res = await axios.get(`${BASE_URL}/abracadabra-box/security-encryption-info`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }

  export const checkFileEditingInfo = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain
      }
      const res = await axios.get(`${BASE_URL}/abracadabra-box/file-editing-info`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }

  export const checkHighContrastInfo = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain
      }
      const res = await axios.get(`${BASE_URL}/abracadabra-box/high-contrast-info`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }

  //New
  export const checkHighContrastInfoWp = (scanId) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        scanId
      }
      const res = await axios.get(`${BASE_URL}/sitemap/high-contrast-status`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }

  export const checkFileAccessInfo = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain
      }
      const res = await axios.get(`${BASE_URL}/abracadabra-box/file-access-info`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }
  
  export const DomainsLink = (email) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        email
      }
      const res = await axios.get(`${BASE_URL}/sitemap/link-website`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        toast.warn(err.response.data.message);
      }
    }
  }
  
  export const getCrawlerStatus = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain
      }
      const res = await axios.get(`${BASE_URL}/abracadabra-box/crawler-status`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        // toast.warn(err.response.data.message);
      }
    }
  }
  
  export const getPluginStatus = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        domain
      }
      const res = await axios.get(`${BASE_URL}/sitemap/plugin-status`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        // toast.warn(err.response.data.message);
      }
    }
  }

  export const getPluginStatusWp = (scanId) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const params = {
        scanId
      }
      const res = await axios.get(`${BASE_URL}/sitemap/plugin-status`,
        {
          params, ...options
        }
      );
      if (res.data) {
  
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      } else {
        // toast.warn(err.response.data.message);
      }
    }
  }