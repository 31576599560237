import React from 'react';
import Box from '@material-ui/core/Box';
import { useSelector, useDispatch } from 'react-redux';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import { makeStyles } from '@material-ui/core/styles';
import TablesCard from "../components/commons/TablesCard";
import ReactTable from "../components/commons/ReactTable";
import { codeSummary } from "../store/actions/dashboardActions.js";
import { useLocation } from "react-router-dom";
import LaunchIcon from '@material-ui/icons/Launch';

const useStyles = makeStyles((theme) => ({
  headingFix: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    marginBottom: 2,
    [theme.breakpoints.up('sm')]: {
      fontSize: "1.8rem"
    },
  },
  instances: {
    fontSize: "0.8rem",
    fontWeight: "bold",
    [theme.breakpoints.up('sm')]: {
      fontSize: "1.1rem"
    },
  },
}));


const codeColumns = [
  {
    width: '250px',
    Header: 'Web Page Link',
    accessor: 'link',
  },
  {
    width: '130px',
    Header: 'Count',
    accessor: 'count',
  },
]

const styles = {
  marginRight: {
    marginRight: 9
  },
  wplinks: {
    textDecoration: "none",fontWeight:500
  },
  textCenter: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  }
}

export default function CodeSummary() {
  const [data, setData] = React.useState(null);
  const codeData = useSelector(state => state.dashboard.codeSummary);

  const dispatch = useDispatch();

  const classes = useStyles();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  function getParsedData(codeData) {
    const links = [];
    for (let i = 0; i < codeData.links.length; i++) {

      const shortLink = new URL(codeData.links[i].link)

      links.push({
        link: <div style={styles.textCenter} >
          <LaunchIcon style={{fontSize:'1.2rem'}}
            onClick={() => window.open(`${codeData.links[i].link}`, '_blank')}
          />
          <span style={styles.wplinks} className="primarycolor"
            onClick={() => window.open(
              `/violation-reports/link?${new URLSearchParams({ link: codeData.links[i].link }).toString()}`
            )}
          >{(shortLink.pathname.length > 40) ? `...${shortLink.pathname.substring(20, 45)}...` : shortLink.pathname}</span>
        </div>,
        count: codeData.links[i].count
      })
    }
    const code = codeData.code;
    const title = codeData.title;
    const desc = codeData.desc;
    const totalCount = codeData.totalCount

    return {
      links,
      code,
      desc,
      title,
      totalCount
    }
  }

  React.useEffect(() => {
    const code = query.get("code");
    const scanId = query.get("scanId");
    dispatch(codeSummary(scanId, code));
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (codeData) {
      const code = query.get("code");
      const scanId = query.get("scanId");
      window.history.replaceState(null, null, `?code=${code}&scanId=${scanId}`);
      let parsedData = getParsedData(codeData)
      setData(parsedData)
    }
    // eslint-disable-next-line
  }, [codeData]);


  return (
    <>
      {data && (
        <Box height="100%" width="100%">
          <p className={classes.headingFix}>WCAG {data.code}</p>
          <p style={{ margin: 0 }}>({data.title})</p>
          <p className={classes.instances}>Total Count: {data.totalCount}</p>
          <p>{data.desc}</p>
          <TablesCard
            icon={<AccessibilityIcon />}
            title="WCAG Code Summary"
            status={"completed"}
          >
            <ReactTable
              tableColumns={codeColumns}
              data={data.links}
              aria-label="WCAG Code Summary Table"
            />
          </TablesCard>
        </Box>
      )}
    </>
  );
}
