import React from 'react';
import "../Styles/css/thankyoupage.css"

export default function ThankYou() {

  React.useEffect(() => {
    setTimeout(() => {
      window.location.pathname = "/dashboard"
    }, 4000);
  }, []);

  return (
    <div className="thankyou_main">
      <header className="site-header" id="header">
        <h1 className="site-header__title">THANK YOU!</h1>
      </header>
      <div className="main-content">
        <img alt="check-icon" src="https://img.icons8.com/color/96/000000/checked--v1.png" />
        {/* <p className="main-content__body">Thank you very much for subscribing. It is extremely important to us, as it is to you! We are grateful for your time today. You're welcome to be yourself.</p> */}
        <p className="main-content__body">Welcome to AllyRight. Thank you for choosing us. Your subscription means the world to us, just as your unique self does. Let's embark on this journey together!</p>
      </div>
      <h3 >You are being redirected to the Dashboard....</h3>
      <footer className="site-footer" id="footer">
        <p className="site-footer__fineprint" id="fineprint">Copyright AllyRight ©2025 | All Rights Reserved</p>
      </footer>
    </div>
  )
}