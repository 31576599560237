import React from "react";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ElementsConsumer } from "@stripe/react-stripe-js";
import MuiCard from "@material-ui/core/Card";
import CheckoutForm from "./Payments/StripeMethod";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import Auth0Service from "../services/auth0Service/authentikService";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "7rem",
    },
  },
  marginZero: {
    margin: "0px",
  },
}));

const getConfig = localStorage.getItem("authConfig");
const configValue = JSON.parse(getConfig);

const STRIPE_KEY = configValue;

const stripePromise = STRIPE_KEY
  ? loadStripe(STRIPE_KEY.value.stripe_key)
  : loadStripe("");

const AddPayment = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const InjectedCheckoutForm = () => {
    return (
      <ElementsConsumer>
        {({ elements, stripe }) => (
          <CheckoutForm elements={elements} stripe={stripe} />
        )}
      </ElementsConsumer>
    );
  };

  const PaymentForm = () => {
    return (
      <div>
        <Elements stripe={stripePromise}>
          <InjectedCheckoutForm />
        </Elements>
      </div>
    );
  };
  const classes = useStyles();

  // if (!Auth0Service.isAuthenticated()) {
  //   return window.location.replace("/authenticate");
  // } else {
    return (
      <>
        <div className={classes.root}>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            my={3}
            alignItems="center"
          >
            <Typography variant="h4" className={classes.mainHeading}>
              <small>Add Payment Method</small>
            </Typography>
            <Typography variant="subtitle2">
              <small style={{ color: "#505050" }}>
                Enter Your Card Details Here
              </small>
            </Typography>
          </Box>
          <Grid spacing={3} container>
            <Grid item xs={12} md={7} style={{ margin: "0 auto" }}>
              <MuiCard style={{ boxShadow: "0px 3px 25px rgba(0 0 0 / 15%)" }}>
                <CustomTabs
                  value={value}
                  onChange={handleChange}
                  centered
                  scrollButtons="on"
                >
                  <CustomTab
                    label={
                      <Box
                        display="flex"
                        justifyContent="center"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <CreditCardIcon style={{ fontSize: "2rem" }} />{" "}
                        <span>Credit/Debit Card</span>
                      </Box>
                    }
                    aria-label="Credit/Debit Card"
                  />
                  {/* <CustomTab disabled label={<div><p className={classes.marginZero}>Coming Soon</p> <span>ACH Wire</span></div>} />
                  <CustomTab disabled label={<div><p className={classes.marginZero}>Coming Soon</p> <span>Cheque</span></div>} />
                  <CustomTab disabled label={<div><p className={classes.marginZero}>Coming Soon</p> <span>Other</span></div>} /> */}
                </CustomTabs>
                <PaymentForm />
              </MuiCard>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
// };
export default AddPayment;

const CustomTabs = withStyles({
  root: {
    borderBottom: "none",
  },
  indicator: {
    backgroundColor: "#1f3a93",
  },
})(Tabs);

const CustomTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    maxWidth: "100%",
    width: 210,
    // width: "auto",
    // maxWidth: 250,
    opacity: 1,
    color: "black",
    "&:hover": {
      color: "#1f3a93",
      opacity: 1,
    },
    "&$selected": {
      color: "#1f3a93",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#1f3a93",
    },
  },
  selected: {
    color: "#1f3a93",
  },
}))((props) => <Tab disableRipple {...props} />);
