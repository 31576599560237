import axios from "axios";
import { BASE_URL } from "../../source/constants";
import { toast } from "react-toastify";
import auth0Service from "../../services/auth0Service/authentikService";

export const GET_ALL_SCANS = "GET_ALL_SCANS";
export const GET_ALL_SCANS_LOADING = "GET_ALL_SCANS_LOADING";



export const GET_ALL_DOMAINS = "GET_ALL_DOMAINS";


export const getCrashedScans = () => async (dispatch) => {
  dispatch({
    type: GET_ALL_SCANS_LOADING,
  });
  try {
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.get(`${BASE_URL}/admin/crashed-scans`,
      options
    );
    if (res.data) {
      dispatch({
        type: GET_ALL_SCANS,
        payload: res.data.result.scans,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ALL_SCANS_LOADING,
    });
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    } else {
      toast.warn(err.response.data.message);
    }

  }
};


// , key
export const deleteScan = (scanId) => async (dispatch) => {

  try {

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(
      `${BASE_URL}/admin/delete-crashed-scan`,
      {
        scanId
      },
      options
    );

    if (res.status === 200) {
      toast.success(res.data.message);

      dispatch(getCrashedScans());
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
    toast.error(err.response.data.message);
  }
};

export const reScan = (scanId) => async (dispatch) => {
  try {

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(
      `${BASE_URL}/admin/rescan-crashed-scan`,
      {
        scanId
      },
      options
    );

    if (res.status === 200) {
      toast.success(res.data.message);

      dispatch(getCrashedScans());
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
    toast.error(err.response.data.message);
  }
};

export const scanAction = (scanId, action) => async (dispatch) => {
  try {

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(
      `${BASE_URL}/scan/admin-subscan-action`,
      {
        scanId, action
      },
      options
    );

    if (res.status === 200) {
      toast.success(res.data.message);
      dispatch(getCrashedScans());
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
    toast.error(err.response.data.message);
  }
};

export const getAllDomains = () => async (dispatch) => {
  try {
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // const params = {
    //   key
    // }
    // params,
    const res = await axios.get(`${BASE_URL}/admin/domains`,
      {
        ...options
      }
    );
    if (res.data) {
      dispatch({
        type: GET_ALL_DOMAINS,
        payload: res.data
      })
      return res.data
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    } else {
      toast.warn(err.response.data.message);
    }
  }
}

export const getDomainLinks = (selectedScanId, searchValue) => async (dispatch) => {
  try {
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const params = { scanId: selectedScanId , searchTerm: searchValue};

    const res = await axios.get(`${BASE_URL}/sitemap/domain/links`, { params, ...options });

    if (res.data) {
      return res.data
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    } else {
      toast.warn(err.response.data.message);
    }
  }
};


export const addBreachedInfo = (data) => async (dispatch) => {
  try {

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(
      `${BASE_URL}/admin/breached-info`,
      {
        ...data
      },
      options
    );

    if (res.status === 200) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
    toast.error(err.response.data.message);
  }
};

export const getAllFreeDomains = () => async (dispatch) => {
  try {
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // const params = {
    //   key
    // }
    const res = await axios.get(`${BASE_URL}/admin/free-domains`,
      {
        ...options
      }
    );
    if (res.data) {
      return res.data
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    } else {
      toast.warn(err.response.data.message);
    }
  }
}

export const getAllAccounts = () => async (dispatch) => {
  try {
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(`${BASE_URL}/admin/accounts`,
      {
        ...options
      }
    );
    if (res.data) {

      return res.data
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    } else {
      toast.warn(err.response.data.message);
    }
  }
}


export const updateAccount = (data) => async (dispatch) => {
  try {
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(
      `${BASE_URL}/admin/update-account/${data.userId}`, // Assuming BASE_URL is defined somewhere
      {
        isAdmin: data.isAdmin,
        hasAbracadabra: data.hasAbracadabra
      },
      options
    );

    if (res.data) {
      toast.success(res.data.message);
      // Here you can dispatch any action if needed
      return res.data;
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ Bad internet connection.');
    }
    toast.error(err.response.data.message);
  }
};

export const getAllCompletedScans = (key) => async (dispatch) => {
  try {
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(`${BASE_URL}/admin/completed-scans`,
      {
        ...options
      }
    );
    if (res.data) {

      return res.data
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    } else {
      toast.warn(err.response.data.message);
    }
  }
}

export const getAllCrashedcans = () => async (dispatch) => {
  try {
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(`${BASE_URL}/admin/scan/crashed`,
      {
        ...options
      }
    );
    if (res.data) {

      return res.data
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    } else {
      toast.warn(err.response.data.message);
    }
  }
}

export const getAllUnsubDomains = () => async (dispatch) => {
  try {
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(`${BASE_URL}/admin/unsub-domains`,
      {
        ...options
      }
    );
    if (res.data) {
      return res.data
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    } else {
      toast.warn(err.response.data.message);
    }
  }
}

export const addMultipleDomains = (data) => async (dispatch) => {
  try {

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(
      `${BASE_URL}/admin/scan-domains`,
      {
        ...data
      },
      options
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
    toast.error(err.response.data.message);
  }
};

export const addFreeDomain = (data) => async (dispatch) => {
  try {

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(
      `${BASE_URL}/admin/domain-free`,
      {
        ...data
      },
      options
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
    toast.error(err.response.data.message);
  }
};

export const domainFreeDuration = (data) => async (dispatch) => {
  try {

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(
      `${BASE_URL}/admin/domain-free-duration`,
      {
        ...data
      },
      options
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
    toast.error(err.response.data.message);
  }
};

export const enableDisableModules = (data) => async (dispatch) => {
  try {

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(
      `${BASE_URL}/admin/update-domain-modules`,
      {
        domainId: data.domainId,
        moduleName: data.moduleName,
        moduleValue: data.moduleValue
      },
      options
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
    toast.error(err.response.data.message);
  }
};

export const updateDomainLinks = (domainName, customLinksCount, demo, environment) => async (dispatch) => {
  try {

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(
      `${BASE_URL}/admin/update-scan-links`,
      {
        domainName: domainName,
        customLinks: customLinksCount,
        demoScan: demo,
        demo: demo,
        environment: environment
      },
      options
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
    toast.error(err.response.data.message);
  }
};

export const deleteFreeDomain = (data) => async (dispatch) => {
  try {
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const params = {
      ...data
    }

    const res = await axios.delete(`${BASE_URL}/admin/domain-free`, {
      params,
      ...options,
    });
    if (res.data) {
      toast.success(res.data.message)

      return res.data
    }
  } catch (err) {
    toast.error(err.response.data.message)
  }
}

export const DomainsLink = (email) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },

    };
    const params = {
      email
    }
    const res = await axios.get(`${BASE_URL}/sitemap/link-website`,
      {
        params, ...options
      }
    );
    if (res.data) {

      return res.data
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    } else {
      toast.warn(err.response.data.message);
    }
  }
}

export const DomainModules = (domainId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },

    };
    const params = {
      domainId:domainId.selectedDomain
    }
    const res = await axios.get(`${BASE_URL}/admin/domain-modules`,
      {
        params, ...options
      }
    );
    if (res.data) {

      return res.data
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    } else {
      toast.warn(err.response.data.message);
    }
  }
}

export const DomainModulesAdmin = (domainId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },

    };
    const params = {
      domainId:domainId.selectedDomainId
    }
    const res = await axios.get(`${BASE_URL}/admin/domain-modules`,
      {
        params, ...options
      }
    );
    if (res.data) {

      return res.data
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    } else {
      toast.warn(err.response.data.message);
    }
  }
}

export const DomainModulesLayout = (domainId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },

    };
    const params = {
      domainId:domainId.domainId
    }
    const res = await axios.get(`${BASE_URL}/admin/domain-modules`,
      {
        params, ...options
      }
    );
    if (res.data) {

      return res.data
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    } else {
      // toast.warn(err.response.data.message);
    }
  }
}
// export const getAllImagesLinks = (domain) => async (dispatch) => {
//   try {
//     const options = {
//       headers: {
//         "Content-Type": "application/json",
//         apiKey: PUBLIC_KEY
//       },

//     };
//     const params = {
//       domain
//     }
//     const res = await axios.get(`${CRAWLER_BASE_CRAWLER}/api/v1/link/images/info`,
//       {
//         params, ...options
//       }
//     );
//     if (res.data) {

//       return res.data
//     }
//   } catch (err) {
//     if (!err.response) {
//       toast.warn('Warning ⚠️ bad internet connection.');
//     } else {
//       toast.warn(err.response.data.message);
//     }
//   }
// }