import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import colors from './colors';
const useStyles = makeStyles((theme, options) => ({
    small: {
        height: (theme.size || 75) + 'px',
        width: (theme.size || 75) + 'px',
        textAlign: 'center',
        borderRadius: '50%',
        position: 'relative',
        marginRight: 10,
        border: '4px solid',
        // backgroundColor: 'white',
        '& > p': {
            fontSize: '35px',
            fontWeight: 600,
            padding: 20
        },

        '& > *': {
            lineHeight: ((theme.size || 29) - 4) + 'px',
        },

        '&.yellow': {

            color: colors.yellow,
        },
        '&.green': {

            color: colors.green,
        },
        '&.red': {

            color: colors.red,
        },
        '&.warning': {

            color: colors.warning,
        },
        '&.danger': {

            color: colors.danger,
        },
        '&.success': {

            color: colors.success,
        },
    },
    big: {
        border: '2px solid #707070',
        height: (theme.size || 60) + 'px',
        width: (theme.size || 60) + 'px',
        textAlign: 'center',
        borderRadius: '100%',
        position: 'relative',
        marginRight: 10,
        background: "white",

        '& > p': {
            fontSize: '30px',
            fontWeight: 'bold',
        },

        '& > *': {
            lineHeight: ((theme.size || 60) - 4) + 'px',
        },

        '&.yellow': {

            color: colors.yellow,
        },
        '&.green': {

            color: colors.green,
        },
        '&.red': {

            color: colors.red,
        },
    },
    '@media (max-width: 960px)': {
        small: {
            height: (theme.size || 84) + 'px',
            width: (theme.size || 84) + 'px',

            '& > *': {
                lineHeight: ((theme.size || 32) - 4) + 'px',
            }
        }
    },
}));

const CardNote = (props) => {
    const classes = useStyles(props, { props });
    
    return (
        <div 
            style={{ margin: "auto" }} 
            className={clsx(props.size === "big" ? classes.big : classes.small, props.className)} 
            role="note" 
            aria-label={props.ariaLabel || 'Note'}
        >
            <Typography role="text">
                {props.children}
            </Typography>
        </div>
    );
}

CardNote.defaultProps = {
    size: '46'
}

export { CardNote };