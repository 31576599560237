import React from "react";
import { BASE_URL_OLD_DASHBOARD } from "../source/constants";

export default function OldDashboardPreload() {

  function setIframe() {
    return {
      __html: iframe
    }
  }



  const iframe = `<iframe src="${BASE_URL_OLD_DASHBOARD}" width="1px" height="1px"></iframe>`

  return (
    <div dangerouslySetInnerHTML={setIframe()} style={{ height:1 }} aria-label="Iframe content"></div>
  )
}