import React from 'react';
import {
	AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';



export default function GrammerGraph({ data }) {

	return (
		<div style={{ marginTop: "1rem" }} role="img" aria-label="Grammar Data Chart">
		  <ResponsiveContainer width={'99%'} height={300}>
			<AreaChart width={730} height={290} data={data}>
			  <XAxis dataKey="month" aria-label="Month" />
			  <YAxis aria-label="Count" />
			  <CartesianGrid strokeDasharray="3 3" />
			  <Tooltip />
			  <Area dataKey="numOfErrors" stroke="#FFA412" fillOpacity={0} strokeWidth="2" aria-label="Number of Errors" />
			  <Area dataKey="numOfPagesWithErrors" stroke="#E3211F" fillOpacity={0} strokeWidth="2" aria-label="Number of Pages with Errors" />
			  <Area dataKey="numOfCommonErrors" stroke="#1F3A93" fillOpacity={0} strokeWidth="2" aria-label="Number of Common Errors" />
			  <Area dataKey="numOfPagesWithCommonErrors" stroke="#96281B" fillOpacity={0} strokeWidth="2" aria-label="Number of Pages with Common Errors" />
			</AreaChart>
		  </ResponsiveContainer>
		</div>
	  );
}
