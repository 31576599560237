import React, { useCallback } from "react";
import MuiCard from "@material-ui/core/Card";
import { CardContent } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
 
import MuiTypography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { useDropzone } from "react-dropzone";
import { useSelector, useDispatch } from "react-redux";
import { showAlert, removeAlert } from "../store/actions/alertActions";
import { sendRequest } from "../store/actions/helpAndSupportActions";
import loaderwave from "../assets/loader.svg";
import helpAndSupportStyles from "../Styles/jss/helpAndSupportStyles";

const useStyles = makeStyles(helpAndSupportStyles);

const inquiryButtons = [
  {
    value: "Tech Support",
    name: "techsupport",
  },
  {
    value: "Sales",
    name: "sales",
  },
  {
    value: "Request New Feature",
    name: "requestNewFeature",
  },
  {
    value: "Billing",
    name: "billing",
  },
  {
    value: "Other",
    name: "other",
  },
];

const urgencyButtons = [
  {
    value: "Urgent",
    name: "urgent",
  },
  {
    value: "High",
    name: "high",
  },
  {
    value: "Medium",
    name: "medium",
  },
  {
    value: "Low",
    name: "low",
  },
];
export default function HelpAndSupport() {
  const [file, setFile] = React.useState("");
  const [inquiryType, setInquiryType] = React.useState(inquiryButtons[0].name);
  const [urgencyType, setUrgencyType] = React.useState(urgencyButtons[0].name);
  const [activeOtherType, setActiveOtherType] = React.useState(false);
  const [mainSubject, setMainSubject] = React.useState("");
  const [otherSubject, setOtherSubject] = React.useState("");
  const [description, setDescription] = React.useState("");
  const dispatch = useDispatch();

  const classes = useStyles();
  const loading = useSelector((state) => state.helpAndSupport.loading);
  const isDomainPurchased = useSelector((state) => state.dashboard.isDomainPackagePurchased);

  const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      // eslint-disable-next-line
      dispatch(showAlert("Max File Size Exceeded"));
      const alert = setTimeout(() => {
        dispatch(removeAlert());
        clearTimeout(alert);
      }, 1000);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onload = () => {
        const binaryStr = reader.result;
        setFile({
          data: binaryStr,
          name: acceptedFiles[0].name,
          contentType: acceptedFiles[0].type,
          size: acceptedFiles[0].size,
        });
      };
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
    }
    // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept:
      ".doc, .docx, .zip, .xlsx, .xls, .csv, .odt,  .gif,  .pdf, .jpeg, .png  ",
    minSize: 0,
    maxSize: 5242880,
  });

  const onIquiryTypeBtnClick = (e) => {
    const name = e.target.name;
    if (name === "other") {
      setActiveOtherType(true);
    } else {
      setActiveOtherType(false);
      setOtherSubject(null);
    }
    setInquiryType(name);
  };

  const onUrgencyTypeBtnClick = (e) => {
    const name = e.target.name;
    setUrgencyType(name);
  };

  function submitForm(event) {
    event.preventDefault();
    if (inquiryType !== "other") {
      dispatch(
        sendRequest(file, inquiryType, urgencyType, mainSubject, description)
      );
    } else {
      dispatch(
        sendRequest(file, otherSubject, urgencyType, mainSubject, description)
      );
    }
    setInquiryType(inquiryButtons[0].name);
    setUrgencyType(urgencyButtons[0].name);
    setFile("");
    setActiveOtherType("");
    setMainSubject("");
    setDescription("");
  }


  // React.useEffect(() => {
  //   if (!isPackagePurchased) {
  //     history.push("/");
  //   }
  //   // eslint-disable-next-line
  // }, []);
	
  // if (!isDomainPurchased ) {
  //   return window.location.pathname = "/dashboard"
  // } else {
    return (
      <>
        {!loading ? (
          <div className={classes.root}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <MuiTypography
                variant="h4"
                tabIndex="0"
                className={classes.mainHeading}
                gutterBottom
              >
                We are here to help you
              </MuiTypography>
              <p tabIndex="0">
                Please fill out this form and we will get back to you immediately
              </p>
            </Box>
            <form onSubmit={submitForm}>
              <Grid container>
                {/* <Grid item xs={12} sm={2} md={2} ></Grid> */}
                <Grid
                  item
                  xs={12}
                  sm={11}
                  lg={11}
                  style={{
                    boxShadow: "0px 3px 25px rgba(0 0 0 / 15%)",
                    margin: "0 auto",
                  }}
                >
                  <MuiCard>
                    <CardContent className={classes.cardContent}>
                      <p variant="subtitle2" style={{ fontFamily: "Segoe UI" }}>
                        Inquiry Type
                      </p>
    
                      <Grid container spacing={2} role="tablist">
                        {inquiryButtons.map((item, index) => {
                          const classNamebtn =
                            inquiryType === item.name
                              ? "activeHelpButton"
                              : "helpButton";
                          return (
                            <Grid item xs={12} sm={6} md={3} key={index}>
                              <button
                                name={item.name}
                                role="tab"
                                aria-selected={inquiryType === item.name}
                                className={classNamebtn}
                                onClick={(e) => onIquiryTypeBtnClick(e)}
                              >
                                {item.value}
                              </button>
                            </Grid>
                          );
                        })}
    
                        {activeOtherType && (
                          <Grid item xs={12} sm={6} md={3}>
                            <TextField
                              required
                              id="support"
                              onChange={(e) => setOtherSubject(e.target.value)}
                              label="Subject"
                              style={{ width: "100%" }}
                            />
                          </Grid>
                        )}
                      </Grid>
    
                      <p variant="subtitle2" style={{ fontFamily: "Segoe UI" }}>
                        Urgency Level
                      </p>
                      <Grid container spacing={2} role="tablist">
                        {urgencyButtons.map((item, index) => {
                          const classNamebtn =
                            urgencyType === item.name
                              ? "activeHelpButton"
                              : "helpButton";
                          return (
                            <Grid item xs={12} sm={6} md={3} key={index}>
                              <button
                                name={item.name}
                                role="tab"
                                aria-selected={urgencyType === item.name}
                                className={classNamebtn}
                                onClick={(e) => onUrgencyTypeBtnClick(e)}
                              >
                                {item.value}
                              </button>
                            </Grid>
                          );
                        })}
                      </Grid>
                      <TextField
                        id="support"
                        required
                        onChange={(e) => setMainSubject(e.target.value)}
                        label="Subject"
                        style={{ width: "100%", marginTop: "2rem" }}
                      />
                      <TextField
                        onChange={(e) => setDescription(e.target.value)}
                        id="description"
                        label="Description"
                        aria-label="Enter Description here"
                        placeholder="Enter Here..."
                        multiline
                        rows={5}
                        style={{ width: "100%", marginTop: "2.5rem" }}
                      />
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        my={2}
                        py={2}
                        flexDirection="column"
                        style={{
                          borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
                        }}
                      >
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          {!file ? (
                            <Box
                              display="flex"
                              justifyContent="center"
                              flexDirection="column"
                              alignItems="center"
                            >
                              <CloudUploadOutlinedIcon
                                style={{
                                  fontSize: "30px",
                                  color: "rgb(31, 58, 147)",
                                }}
                              />
                              <MuiTypography>Attach a File</MuiTypography>
                            </Box>
                          ) : (
                            <div>{file.name}</div>
                          )}
                        </div>
                      </Box>
                      <small style={{ color: "rgba(0, 0, 0, 0.42)" }}>
                        Supported File: .doc, .docx, .zip, .xlsx, .xls, .csv, .odt,
                        .gif, .pdf, .jpeg, .png
                      </small>
                      <Box display="flex" justifyContent="flex-end" mt={1.5}>
                        <Button style={{ color: "#96281B" }}>CANCEL</Button>
                        <Button
                          type="submit"
                          style={{
                            backgroundColor: "rgb(31, 58, 147)",
                            color: "white",
                            borderRadius: 25,
                            padding: "8px 33px",
                          }}
                        >
                          SEND
                        </Button>
                      </Box>
                    </CardContent>
                  </MuiCard>
                </Grid>
              </Grid>
            </form>
          </div>
        ) : (
          <div className={classes.overlayLoader}>
            <div
              style={{
                position: "absolute",
                top: "50%",
                transform: "translateX(-50%, -50%)",
                left: "50%",
              }}
            >
              <img src={loaderwave} alt="loader" />
            </div>
          </div>
        )}
      </>
    );
  }
// }
