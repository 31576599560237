import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

export default function NoDomain({text}) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%"
      }}
      role="main"
      aria-label="Centered informational card"
    >
      <Card style={{ boxShadow: "0px 3px 25px rgba(0 0 0 / 15%)", maxWidth: "400px" }}
        role="region"
        aria-labelledby="card-title"
      >
        <CardContent style={{padding:24}}>
          <h4  id="card-title" style={{margin:0}}>{text}</h4>
        </CardContent>
      </Card>
    </div>
  );
}