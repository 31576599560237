import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Collapse from '@material-ui/core/Collapse';
import ShortenNumber from "../../commons/ShortenNumber";
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useSelector, useDispatch } from "react-redux";

const styles = {
  cardMain: {
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    height: "100%",
    padding: "12px 10px",
    minHeight: 295,
  },
  boxContainer: {
    background: "#FFFFFF",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.12)",
    boxSizing: "border-box",
    borderRadius: 9,
    justifyContent: "center",
    alignItems: "center",
    padding: "14px",
    minHeight: 275,
  },
  top5: {
    fontFamily: "Segoe UI",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 20,
    marginRight: "20px",
    marginBottom: 5,
    // marginTop: 9
  },
  pType: {
    marginRight: 10,
    width: 60,
    fontWeight: 500,
  },
  IconButton: {
    marginLeft: 5,
    color: "black",
    padding: 0
  },
  infoIcon: {
    width: 15,
    height: 15,
  },
};

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
  table: {
    // minWidth: 480,
    overflow: "auto",
    tableLayout: "fixed",
  },
  IconButton: {
    marginLeft: 20,
    color: "black",
    padding: 0,
  },
}));


function Top5({ title, data, name, columns, totalViolations }) {
  const [tabData, setTabData] = React.useState([]);
  const classes = useStyles();
  function percentageConvert(value) {
    return (value / totalViolations) * 100;
  }

  React.useEffect(() => {
    if (data) {

      setTabData(data[name]);
    }
  }, [data]);

  return (
    <Box style={styles.boxContainer}>
      <Box height='100%'>
        <Box display='flex' flexWrap='wrap' alignItems='center' tabIndex='0' aria-label={title}>
          <span style={styles.top5}>{title}</span>
        </Box>
        {tabData.length > 0 ? (
          <TableContainer tabIndex='0' aria-label='Data Table'>
            <Table className={classes.table} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  {columns.map((td, index) => (
                    <THead key={index} style={{ width: td.width }}>
                      {td.column}
                    </THead>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tabData.map((td, index) => (
                  <Row td={td} key={index} percentageConvert={percentageConvert} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='180px'
            role='alert'
            aria-live='assertive'
          >
            <span>No data available</span>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default React.memo(Top5);

const BorderLinearProgressHigh = withStyles((theme) => ({
  root: {
    height: 10,
    width: 130,
    borderRadius: 4,
    display: "inline-block",
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 4,
    backgroundColor: "#E2211E",
  },
}))(LinearProgress);

const BorderLinearProgressModerate = withStyles((theme) => ({
  root: {
    height: 10,
    width: 130,
    borderRadius: 4,
    display: "inline-block",
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 4,
    backgroundColor: "#FFD800",
  },
}))(LinearProgress);

const BorderLinearProgressLow = withStyles((theme) => ({
  root: {
    height: 10,
    width: 130,
    borderRadius: 4,
    display: "inline-block",
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 4,
    backgroundColor: "#21CA5A",
  },
}))(LinearProgress);

const THead = withStyles((theme) => ({
  head: {
    fontSize: 16,
    fontFamily: "Segoe UI",
    fontStyle: "normal",
    fontWeight: 400,
    letterSpacing: "0em",
    textAlign: "left",
    padding: 5,
  },
}))(TableCell);

const TData = withStyles((theme) => ({
  body: {
    fontFamily: "Segoe UI",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 13,
    padding: "7px 7px",
    letterSpacing: "0.01em",
    color: "#212225",
    textAlign: "left",
    border: "none",
  },
}))(TableCell);



const TDPriority = withStyles((theme) => ({
  body: {
    fontFamily: "Segoe UI",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 13,
    padding: "7px 10px",
    letterSpacing: "0.01em",
    color: "#212225",
    textAlign: "left",
    border: "none",
    // display: "flex",
    // justifyContent: "flex-start",
    // alignItems: "center",
  },
}))(TableCell);



function Row({ td, percentageConvert }) {
  const [open, setOpen] = React.useState(false);
  const isSelectedDomainLink = useSelector(
    (state) => state.dashboard.selectedLink
  );


  return (
    <>
      <TableRow>
        <TData component='th'>
          <Box display="flex" alignItems="center">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </TData>
        <TData component='th'>
          <ShortenNumber>{td.count}</ShortenNumber>
        </TData>
        <TData>
          {td.pages.length}
        </TData>
        <TData>
          {td.code.split(".")[3].split('_').join('.')}
        </TData>
        {isSelectedDomainLink === "" || isSelectedDomainLink === undefined ? (
          <TDPriority>
            <Box display='flex' alignItems='center'>
              <span style={styles.pType}>{td.priority}</span>
              {td.priority === "high" ? (
                <BorderLinearProgressHigh
                  variant='determinate'
                  value={percentageConvert(td.count)}
                />
              ) : td.priority === "medium" ? (
                <BorderLinearProgressModerate
                  variant='determinate'
                  value={percentageConvert(td.count)}
                />
              ) : (
                <BorderLinearProgressLow
                  variant='determinate'
                  value={percentageConvert(td.count)}
                />
              )}
              <p
                style={{ margin: "0 0 0 10px", fontWeight: 500 }}
              >
                {percentageConvert(td.count).toFixed(2)}%{" "}
              </p>
            </Box>
          </TDPriority>
        ) : (
          <TDPriority>
            <Box display='flex' alignItems='center'>
              <span style={styles.pType}>{td.priority}</span>
              {td.priority === "high" ? (
                <BorderLinearProgressHigh
                  variant='determinate'
                />
              ) : td.priority === "medium" ? (
                <BorderLinearProgressModerate
                  variant='determinate'
                />
              ) : (
                <BorderLinearProgressLow
                  variant='determinate'
                />
              )}
              <p
                style={{ margin: "0 0 0 10px", fontWeight: 500 }}
              >
              </p>
            </Box>
          </TDPriority>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, overflow: "auto" }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases" style={{ tableLayout: "fixed" }}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 250 }}>Issue Detail</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={td.message}>
                    <TableCell scope="row">
                      {td.message}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
