import React from "react";
import CardContainer from "../components/commons/CardContainer";
import { useSelector, useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import loaderwave from "../assets/loader.svg";

import Typography from '@material-ui/core/Typography';

import { getBillingStage } from "../store/actions/onBoardingActions";
import Manage from './financialComponents/billing-steps/Manage';
import Package from './financialComponents/billing-steps/Package';
import Auth0Service from '../services/auth0Service/index'
import History from "../@history";

export default function CustomScanPageList() {

  const [billingContent, setBillingContent] = React.useState(null)
  const dispatch = useDispatch();
  const domainId = useSelector((state) => state.dashboard.selectedDomainId);
  const selectedDomain = useSelector((state) => state.dashboard.selectedDomain);

  const scanProgress = useSelector((state) => state.onBoardDashboard.scanProgress);
  const scanId = useSelector((state) => state.miscellaneous.selectedScanId);


  const classes = useStyles();

  function getSteps() {
    return ['Options', 'Payment'];
  }

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();



  function getStepContent(step, billingContent) {
    switch (step) {
      case 0:
        return <Manage setActiveStep={setActiveStep} data={billingContent} domainId={domainId} />;
      case 1:
        return <Package setActiveStep={setActiveStep} data={billingContent} domainId={domainId} />;
      default:
        return 'Unknown step';
    }
  }

  // React.useEffect(() => {
  //   if (billingContent) {
  //       dispatch(getBillingStage(domainId)).then((data) => {
  //         if (data) {
  //           setBillingContent(data.evaluation);
  //           setActiveStep(data.stage);
  //         }
  //       })
  //     }
  //   // eslint-disable-next-line
  // }, [billingContent])

  React.useEffect(() => {
    if (domainId) {
      dispatch(getBillingStage(domainId)).then((data) => {
        if (data) {
          if (data.freeBilling) {
            History.push(`/dashboard?domain=${selectedDomain}&scanId=${scanId}`)
          }
          setBillingContent(data.evaluation);
          setActiveStep(data.stage);
        }
      })
    }
    // eslint-disable-next-line
  }, [domainId])



  const [delayed, setDelayed] = React.useState(true);

  React.useEffect(() => {
    if (domainId) {
      dispatch(getBillingStage(domainId)).then((data) => {
        if (data) {
          if (data.freeBilling) {
            History.push(`/dashboard?domain=${selectedDomain}&scanId=${scanId}`)
          }
          setBillingContent(data.evaluation);
          setActiveStep(data.stage);
        }
      })
    }
    // eslint-disable-next-line
  }, [activeStep])

  React.useEffect(() => {
    setTimeout(() => {
      setDelayed(false)
    }, 1500);
  }, [])


  if (!Auth0Service.isAuthenticated() && scanProgress.status !== "completed") {
    window.location.pathname = "/dashboard";
  }
  else {
    return (
      <>
        {!delayed ? (
          domainId && (
            <>
              <CardContainer>
                {/* <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />} >
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper> */}
                <div>
                  {activeStep === steps.length ? (
                    <div>
                      <Typography className={classes.instructions}>
                        All steps completed - you &apos; re finished
                      </Typography>
                    </div>
                  ) : (
                    <div>
                      {billingContent ? (
                        <Box p={3}>{getStepContent(activeStep, billingContent)}</Box>
                      ) : (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          height="100vh"
                          tabIndex="0"
                          role="status"
                          aria-live="polite"
                        >
                          <img src={loaderwave} alt="loader" />
                        </Box>
                      )}
                    </div>
                  )}
                </div>
              </CardContainer>
            </>
          )
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            tabIndex="0"
            role="status"
            aria-live="polite"
          >
            <img src={loaderwave} alt="loader" />
          </Box>
        )}
      </>
    );
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },

  fieldset: {
    width: "100%",
    maxWidth: "600px",
    margin: "20px 0px",
    borderRadius: 10,
  },
  customLinkInput: {
    width: "100%",
    maxWidth: "400px",
    margin: "10px 0px",
  },
  menuButton: {
    fontSize: 14,
    fontWeight: "bold",
    color: "white",
  },
  buttonIcon: {
    marginRight: theme.spacing(0),
  },
}));