import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import GraphCard from "../commons/GraphCard";
import { CardNote } from "../accessibility-main/components/CardNote";
import ShortenNumber from "../commons/ShortenNumber";
import CircularProgressRing from "../commons/CircularProgressRing";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import DateRangePicker from "../commons/DateRangePicker";
import SubLoader from "../commons/SubLoader";
import Top5ReapeadtedMistakes from "./spellingAndGrammer/Top5RepeadtedMistakes";
import Top5PagesWithMistakes from "./spellingAndGrammer/Top5PagesWithMistakes";
import GrammarHistory from "../GrammarComponents/historycard/GrammarCard";
import { getSpellCheckMain } from "../../store/actions/spellCheckActions";
import Auth0Service from "../../services/auth0Service/authentikService";

const styles = {
  subBoxTitle: {
    fontFamily: "Segoe UI",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 20,
    marginBottom: 15,
  },
  cardMain: {
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    height: "100%",
    padding: "12px 10px",
    marginTop: "10px",
  },
  boxContainer: {
    background: "#FFFFFF",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.12)",
    boxSizing: "border-box",
    borderRadius: 9,
    padding: "14px",
    height: "100%",
  },
  siteStatus: {
    fontFamily: "Segoe UI",
    fontSize: 18,
    textTransform: "capitalize",
    color: "#484848",
    marginBottom: "15px",
  },
  subTitle2: {
    fontFamily: "Segoe UI",
    color: "#484848",
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: "0em",
    lineHeight: 2,
  },
  vulPages: {
    fontFamily: "Segoe UI",
    fontSize: 21,
    fontWeight: 700,
    letterSpacing: "0em",
    color: "#1f3a93",
  },
  vulPagesTitle: {
    fontFamily: "Segoe UI",
    fontSize: 18,
    textTransform: "capitalize",
    color: "#484848",
  },
  subtitle: {
    fontFamily: "Segoe UI",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 13,
    textTransform: "capitalize",
    letterSpacing: "-0.45px",
  },
  avgperPageValue: {
    fontFamily: "Segoe UI",
    fontSize: 64,
    textAlign: "right",
  },
  avgChangeValue: {
    fontFamily: "Segoe UI",
    fontSize: 14,
  },
  pieCahrtValue: {
    fontFamily: "Segoe UI",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 15,
    textAlign: "right",
  },
  infoIcon: {
    width: 15,
    height: 15,
  },
  boxContainer2: {
    background: "#FFFFFF",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.12)",
    boxSizing: "border-box",
    borderRadius: 9,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "14px",
    height: "100%",
  },
  subTitle: {
    fontFamily: "Segoe UI",
    color: "#484848",
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: "0em",
    lineHeight: 2,
  },

  barPercentage: {
    fontFamily: "Segoe UI",
    fontSize: 19,
    fontWeight: 700,
    letterSpacing: "0em",
    color: "#1f3a93",
    marginRight: 4,
  },
  avgperPageTitle: {
    fontFamily: "Segoe UI",
    fontSize: 18,
    marginBottom: "15px",
    textAlign: "left",
  },
  priorityValue: {
    fontFamily: "Segoe UI",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 20,
  },
  chartBoxContainer: {
    background: "#FFFFFF",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.12)",
    boxSizing: "border-box",
    borderRadius: 9,
    display: "flex",
    justifyContent: "center",

    alignItems: "center",
    padding: "14px",
    height: "100%",
  },
  boxIconDiv: {
    width: 35,
    textAlign: "center",
  },
  value: {
    fontFamily: "Segoe UI",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 29,
    color: "#343AA3",
  },
};

const grammarTble1Columns = [
  {
    column: "Total",
    width: 80,
  },
  {
    column: "Word",
    width: 80,
  },
];

const grammarTble2Columns = [
  {
    column: "Page Link",
    width: 150,
  },
  {
    column: "Errors",
    width: 80,
  },
];

function SpellingGrammerCard({ scanId }) {
  const [time, setTime] = React.useState({
    current: true,
    overtime: false,
  });

  const dispatch = useDispatch();

  const data = useSelector((state) => state.spellCheck.spellCheckMain);
  const status = useSelector((state) => state.spellCheck.spellCheckMainStatus);
  const domains = useSelector((state) => state.dashboard.domains);
  const selectedDomain = useSelector(
    (state) => state.miscellaneous.selectedDomain
  );
  const { isPackagePurchased } = useSelector((state) => state.auth.user);

  const [dateRange, setDateRange] = React.useState(null);
  const [loader, setLoader] = React.useState(false);

  React.useEffect(() => {
    if (scanId) {
      dispatch(getSpellCheckMain(scanId));
    }
    // eslint-disable-next-line
  }, [scanId]);

  React.useEffect(() => {
    if (status === "running" || status === "pending") {
      const interval = setInterval(() => {
        if (scanId) {
          dispatch(getSpellCheckMain(scanId));
        }
      }, 15000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [status]);

  return (
    <React.Fragment>
      <GraphCard>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <p style={{ margin: 0, fontSize: 18, fontWeight: "500" }} aria-live="polite">
            {time.current
              ? "Spelling and Grammer Analytics"
              : "Spelling and Grammer History"}
            {loader && <CircularProgressRing aria-label="Loading..." />}
          </p>
        </Box>
        <Box display="flex" justifyContent="center" mt={1} mb={2}>
          <ButtonGroup
            size="small"
            aria-label="Button group to switch between current and overtime"
            style={{ marginRight: 10 }}
          >
          </ButtonGroup>{" "}
          {time.overtime && <DateRangePicker setDateRange={setDateRange} aria-label="Date range picker" />}
        </Box>
        {time.current ? (
          <>
            {data ? (
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4} tabIndex="0" aria-labelledby="score-title">
                    <Box style={styles.boxContainer}>
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="end"
                        flexWrap="wrap"
                        height="100%"
                      >
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="center"
                          width="100%"
                        >
                          <span id="score-title" style={styles.subtitle}> Score </span>
                        </Box>
                        <CardNote
                          size="big"
                          className={clsx(
                            data.gradeType === "high"
                              ? "circle-critical"
                              : data.gradeType === "medium"
                                ? "circle-moderate2"
                                : "circle-safe"
                          )}
                        >
                          {data.grade}
                        </CardNote>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} tabIndex="0" aria-labelledby="average-per-page-title">
                    <Box style={styles.boxContainer}>
                      <Box mb={2}>
                        <span id="average-per-page-title" style={styles.subtitle}> Average Per Page </span>
                      </Box>
                      <div style={styles.value}>
                        <ShortenNumber>{data.averagePerPage}</ShortenNumber>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} tabIndex="0" aria-labelledby="pages-with-errors-title">
                    <Box style={styles.boxContainer}>
                      <Box width="100%">
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          mb={2.5}
                        >
                          <span id="pages-with-errors-title" style={styles.subtitle}>
                            Pages with Grammatical Mistakes
                          </span>
                          <span style={styles.vulPages}>
                            <ShortenNumber>
                              {data.pagesWithErrors}
                            </ShortenNumber>
                          </span>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <span className={styles.barPercentage}>
                            {data.pagesWithErrorsPercentage}%
                          </span>
                          <Box width="100%">
                            <BorderLinearProgress
                              variant="determinate"
                              value={data.pagesWithErrorsPercentage}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} tabIndex="0" aria-labelledby="top-repeated-mistakes-title">
                    <Top5ReapeadtedMistakes
                      columns={grammarTble1Columns}
                      rows={data.top5RepeatedErrors}
                    ></Top5ReapeadtedMistakes>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} tabIndex="0" aria-labelledby="top-pages-with-mistakes-title">
                    <Top5PagesWithMistakes
                      columns={grammarTble2Columns}
                      rows={data.top5Pages}
                    ></Top5PagesWithMistakes>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.15) 0px 0px 12px",
                  padding: 14,
                  minHeight: "415px",
                  maxHeight: "415px",
                }}
                role="alert" // Added role to indicate an important message
              >
                <Box display="flex" alignItems="center">
                  <SubLoader />
                </Box>
              </Box>
            )}
          </>
        ) : (
          <GrammarHistory
            setLoader={setLoader}
            scanId={scanId}
            dateRange={dateRange}
          />
        )}
      </GraphCard>
    </React.Fragment>
  );
}
export default React.memo(SpellingGrammerCard);

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 7.5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 7.5,
    backgroundColor: "#1f3a93",
  },
}))(LinearProgress);
