import { Box } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { getModuleSummary } from '../../store/actions/moduleSummaryActions';
import ModuleCard from './ModuleCard';

export default function ModuleSummary({ module, title }) {

  const dispatch = useDispatch();

  const scanId = useSelector((state) => state.miscellaneous.selectedScanId);
  const unified = useSelector((state) => state.dashboard.unified);


  const [data, setData] = React.useState({
    result: {
      grade: null,
      gradeType: null,
      issues: null
    },
    status: "pending"
  })

  React.useEffect(() => {
    dispatch(getModuleSummary(scanId, module, unified)).then((data) => {
      if (data) {
        setData({ ...data });
      }
    })
    console.log("data" )
    console.log(data)
  }, [scanId, unified])

  React.useEffect(() => {
    if (
      data.status === "running" ||
      data.status === "pending"
    ) {
      const interval = setInterval(() => {
        if (scanId) {
          dispatch(getModuleSummary(scanId, module, unified));
        }
      }, 5000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [scanId, data]);

  return (
    <Box 
      display="flex" 
      mb={4} 
      justifyContent="center" 
      alignItems="center"
      aria-live="polite" // Screen reader will announce changes
      role="region"
      aria-label="Module card showing overall score"
      >
       <div style={{ pointerEvents: "none" }} aria-hidden="true">
      <ModuleCard
        title={title || "Overall Score"}
        isPerPage={true}
        body={data.result.issues}
        grade={data.result.grade}
        gradeType={data.result.gradeType}
        perPage={data.result.average}
        module={module}
        aria-label={`Module card for ${title || "Overall Score"}`}
      /></div>

    </Box>
  )
}