import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import { Link } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import loaderwave from "../../assets/loader.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import { nFormatter } from "../../utils/shortNumber";
import { CardNote } from "../accessibility-main/components/CardNote";
import Arrow from "./Arrow";
import { useSelector } from "react-redux";
// import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
// import CustomTooltip from "./Tooltip";
import Chip from '@material-ui/core/Chip';
const cardNoteColor = (grade) => {
  return (
    grade === "A" ? '#FFF' : grade === "B" ? '#000' : grade === "C" ? '#000' : grade === "D" ? '#000' : grade === "F" ? '#FFF' : '#FFFFFF'
  )
}
const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 30,
    fontFamily: "Segoe UI",
    fontWeight: 'bold',
    margin: 0,
    [theme.breakpoints.down("md")]: {
      fontSize: "30px",
    },
  },
  IconButton: {
    color: "black",
    padding: 0,
    marginLeft: 5,
  },
  bgRed: {
    backgroundColor: '#B50202'
  },
  bgGreen: {
    backgroundColor: '#005D35'
  },
  bgWhite: {
    backgroundColor: '#FFFFFF'
  },
  colorRed: {
    color: '#B50202'
  },
  colorGreen: {
    color: '#005D35'
  },
  cardBackGround: {
    backgroundColor: (props) => props.grade === "A" ? '#005D35' : props.grade === "B" ? '#51D3D3' : props.grade === "C" ? '#F7CA05' : props.grade === "D" ? '#FF8000' : props.grade === "F" ? '#B50202' : '#FFFFFF',
  },
  cardNote: {
    color: (props) => cardNoteColor(props.grade),
    border: (props) => `3px solid ${cardNoteColor(props.grade)}}`
  },
  cardContentColor: {
    "& > *": {
      color: 'black !important'
    }
  },
  percentageText: {
    marginLeft: "2px",
    fontSize: 12,
  },
}));

function StatCard({
  title,
  body,
  isPerPage,
  perPage,
  grade,
  gradeType,
  change,
  percentage,
  goto,
  beta,
  module
}) {
  const classes = useStyles({ gradeType, grade });
  const status = useSelector((state) => state.dashboard.domainSummary.status);
  
  const scanProgress = useSelector(
    (state) => state.onBoardDashboard.scanProgress
  );
  console.log(status)

  return (
      <Link
        aria-label={goto ? `Go to ${title}` : `Current page: ${title}`}
        style={{
          textDecoration: 'none',
          cursor: !goto ? 'default' : 'pointer',
          userSelect: !goto ? 'auto' : 'none'
        }}
        to={goto}
        tabIndex={goto ? 0 : -1} // Focusable only if a link
      >
        <Card
          tabIndex="0" // Allows keyboard focus
          style={{
            minHeight: 172,
            maxWidth: 450,
            borderRadius: 10,
            boxShadow: '2px 4px 9px rgba(0, 0, 0, 0.04)',
            padding: '20px 23px',
            color: cardNoteColor(grade)
          }}
          aria-live="polite"
          aria-labelledby="card-title"
          aria-describedby="card-content"
          className={clsx(classes.cardBackGround)}
        >
          <CardContent style={{ padding: 5 }}>
            {body || body === 0 ? (
              <Box
                display="flex"
                flexWrap="wrap"
                position="relative"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                className="stat-card-dash"
                id="card-content"
              >
                <Box
                  display="flex"
                  flexWrap="wrap"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Box display="flex" alignItems="center">
                    <h2 id="card-title" className={classes.title} style={{ marginRight: 10 }}>
                      {title}
                    </h2>
                    {beta && (
                      <Chip
                        label="Beta"
                        aria-label="Beta version"
                        style={{ backgroundColor: 'yellow', color: 'black', fontWeight: 'bold', height: 25 }}
                      />
                    )}
                  </Box>
                  <Arrow change={change} value={percentage} aria-label={`Percentage change: ${percentage}%`} />
                </Box>
  
                <Box
                  width="100%"
                  display="flex"
                  flexWrap="wrap"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    my={2}
                    style={{
                      minWidth: title !== "AllyRight Score" && title !== "Web Privacy" ? "153px" : "50px",
                    }}
                  >
                    {gradeType && grade ? (
                      <CardNote
                        aria-label={`Grade: ${grade}`}
                        className={clsx(classes.cardNoteColor)}
                      >
                        {grade}
                      </CardNote>
                    ) : (
                      <CircularProgress
                        style={{
                          width: "50px",
                          height: "50px",
                          margin: '19px auto',
                          color: "black",
                        }}
                        aria-label="Loading grade"
                      />
                    )}
                  </Box>
  
                  {title !== "AllyRight Score" && title !== "Web Privacy" && (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                      mt={-2}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        mt={0}
                        style={{ lineHeight: 1.1 }}
                        aria-label={`Total ${title === "Broken Links" ? "Links" : "Issues"}: ${nFormatter(body, 1000)}`}
                      >
                        <span style={{ fontWeight: "bold", fontSize: 35 }}>{nFormatter(body, 1000)}</span>
                        <span style={{ fontSize: 20 }}> Total {title === "Broken Links" ? "Links" : "Issues"}</span>
                      </Box>
  
                      {isPerPage && status === "completed" && (
                        <Box
                          display="flex"
                          alignItems="center"
                          flexDirection="column"
                          style={{ lineHeight: 1.1 }}
                          aria-label={`Average per page: ${perPage}`}
                        >
                          <span style={{ fontWeight: "bold", fontSize: 35 }}>{perPage}</span>
                          <span style={{ fontSize: 20 }}>Avg. per page</span>
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            ) : (
              <Box display="flex" flexWrap="wrap" flexDirection="column">
                <Box
                  display="flex"
                  flexWrap="wrap"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <small id="card-title" className={classes.title}>{title}</small>
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                  style={{ paddingTop: "11px" }}
                  aria-live="polite"
                  aria-label="Loading content"
                >
                  <img height="100px" src={loaderwave} alt="Loading content" />
                </Box>
              </Box>
            )}
          </CardContent>
        </Card>
      </Link>
    );
}

export default React.memo(StatCard);

