import React from "react";
import visual from "../../../../assets/disabilityicons/visual.svg";
import Box from "@material-ui/core/Box";

export default function Visual() {
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      mr={1.5}
      style={{ fontSize: 13, fontWeight: 500, marginRight: 12, width: 60 }}
      role="group"
      aria-labelledby="visual-title"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{ border: "2px solid black", borderRadius: "50%", padding: 6 }}
        role="img"
        aria-label="Visual Disability Icon"
      >
        <img src={visual} alt="Visual Disability" height="22px" width="22px" />
      </Box>
      <span  id="visual-title" style={{ fontSize: "17.5px", fontWeight: 500 }}>Visual</span>
    </Box>
  );
}
