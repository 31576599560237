import React from "react";
import { useSelector, useDispatch } from "react-redux";
// import ReadMoreReact from 'read-more-react';
import { getTableAccessibilityTableOne } from "../../../store/actions/dashboardActions";
import {
  archiveAccessibilityLinks,
  completeAccessibilityLinks,
  ignoreAccessibilityLinks,
  fixAccessibilityIssues
} from "../../../store/actions/accessibilityTablesActions";
import TablesCard from "../../commons/TablesCard";
import DescriptionIcon from "@material-ui/icons/Description";
import Box from "@material-ui/core/Box";
import ReactTable from "../../commons/ReactTable";
import Cognitive from "../../commons/icons/Disabilityicons/Cognitive";
import Hearing from "../../commons/icons/Disabilityicons/Hearing";
import Motor from "../../commons/icons/Disabilityicons/Motor";
import Visual from "../../commons/icons/Disabilityicons/Visual";
import { IconTriangle } from "../../commons/icons/accessibilitymainIcons/icons/IconTriangle";
import { IconSquare } from "../../commons/icons/accessibilitymainIcons/icons/IconSquare";
import { IconCircle } from "../../commons/icons/accessibilitymainIcons/icons/IconCircle";
import loader from "../../../assets/loader.svg";
import LaunchIcon from "@material-ui/icons/Launch";
import tablesWrapperStyles from "../../../Styles/jss/tables/tablesWrapperStyles";
import {getTechnologies}  from "../../../store/actions/technologiesActions"

import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { makeStyles } from "@material-ui/core/styles";
import ReadMore from "../../commons/ReadMore";
import { toast } from "react-toastify";

const styles = {
  ...tablesWrapperStyles,
};

const accessibilityColumns = [
  {
    width: "250px",
    Header: "Page Title",
    accessor: "pageTitle",
  },

  {
    width: "200px",
    Header: "Disability Type",
    accessor: "disabilitytype",
  },
  {
    width: "150px",
    Header: "Priority",
    accessor: "priority",
  },
  {
    width: "100px",
    Header: "Standard",
    accessor: "standard",
  },
  {
    width: "140px",
    Header: "Criterion",
    accessor: "criteria",
  },
  {
    width: "200px",
    Header: "Element",
    accessor: "selector",
  },
  {
    width: "200px",
    Header: "Element Location",
    accessor: "context",
  },
  {
    width: "400px",
    Header: "Explanation",
    accessor: "shortexp",
  },
  {
    width: "50px",
    Header: "Suggested",
    accessor: "suggested",
  },
  {
    width: "50px",
    Header: "Fixed",
    accessor: "fixes",
  },
  {
    width: "200px",
    Header: "Suggested Description",
    accessor: "suggested_description",
  },
  {
    width: "200px",
    Header: "Suggested Html",
    accessor: "suggested_html",
  },
];

// export default function AccessibilityTable({ scanId, tabs }) {
//   const [tblData, setTblData] = React.useState(null);
//   const [page, setPage] = React.useState(1);

//   const dispatch = useDispatch();
//   const data = useSelector((state) => state.dashboard.accessibilityTableOne);
//   const pages = useSelector(
//     (state) => state.dashboard.accessibilityTableOnePages
//   );
//   const count = useSelector(
//     (state) => state.dashboard.accessibilityTableOneCount
//   );

//   const status = useSelector(
//     (state) => state.dashboard.accessibilityTableOneStatus
//   );
//   const loading = useSelector(
//     (state) => state.dashboard.accessibilityTableOneLoading
//   );

//   // function getPriorityIcon(priority) {
//   //   switch (priority) {
//   //     case "low":
//   //       return "circle";
//   //     case "medium":
//   //       return "square";
//   //     case "high":
//   //       return "triangle";
//   //     default:
//   //       return "circle";
//   //   }
//   // }

//   React.useEffect(() => {
//     if (scanId) {
//       dispatch(getTableAccessibilityTableOne(scanId, page));
//     } else {
//       dispatch(getTableAccessibilityTableOne(scanId, page));
//     }
//     // eslint-disable-next-line
//   }, [scanId, page]);

//   React.useEffect(() => {
//     if (status === "pending" || status === "running") {
//       const interval = setInterval(() => {
//         if (scanId) {
//           dispatch(getTableAccessibilityTableOne(scanId, page));
//         } else {
//           dispatch(getTableAccessibilityTableOne(scanId, page));
//         }
//       }, 15000);
//       return () => clearInterval(interval);
//     }
//     // eslint-disable-next-line
//   }, [status, scanId, page]);

//   React.useEffect(() => {
//     function getTbl1AccessibilityData(dataFortbl1) {
//       let tblDataAccessibility = [];

//       for (let i = 0; i < dataFortbl1.length; i++) {
//         const link = dataFortbl1[i].pageUrl;
//         const message = dataFortbl1[i].message;
//         const icon = dataFortbl1[i].priority;
//         const code = dataFortbl1[i].code;
//         const selector = dataFortbl1[i].selector;
//         const context = dataFortbl1[i].context;
//         const pageTitle = dataFortbl1[i].documentTitle;
//         let criteria = dataFortbl1[i].criterion;
//         const disabilityType = dataFortbl1[i].type;
//         tblDataAccessibility.push({
//           key: i,
//           pageTitle: (
//             <div style={{ fontWeight: 600, ...styles.textCenter }}>
//               {" "}
//               <LaunchIcon
//                 style={{ fontSize: "1.2rem" }}
//                 onClick={() => window.open(`${link}`, "_blank")}
//               />{" "}
//               {pageTitle}
//             </div>
//           ),

//           disabilitytype: (
//             <Box
//               style={{
//                 width: "250px",
//                 display: "flex",
//                 justifyContent: "space-evenly",
//               }}
//             >
//               {disabilityType.map((type, index) => (
//                 <Box key={index} display="flex">
//                   {
//                     // eslint-disable-next-line
//                     (type === "visual" && <Visual />) ||
//                     // eslint-disable-next-line
//                     (type === "hearing" && <Hearing />) ||
//                     // eslint-disable-next-line
//                     (type === "motor" && <Motor />) ||
//                     // eslint-disable-next-line
//                     (type === "cognitive" && <Cognitive />)
//                   }
//                 </Box>
//               ))}
//             </Box>
//           ),
//           priority: (
//             <div className="disability-type">
//               <Box
//                 display="flex"
//                 justifyContent="flex-start"
//                 alignItems="center"
//                 flexDirection="row"
//               >
//                 {icon === "high" ? (
//                   <>
//                     <span style={styles.marginRight}>
//                       <IconTriangle />
//                     </span>
//                     <span
//                       className="icon-priority"
//                       aria-label="violation is high"
//                     >
//                       High
//                     </span>
//                   </>
//                 ) : icon === "medium" ? (
//                   <>
//                     <span style={styles.marginRight}>
//                       <IconSquare />
//                     </span>
//                     <span
//                       className="icon-priority"
//                       aria-label="violation is medium"
//                     >
//                       Medium
//                     </span>
//                   </>
//                 ) : icon === "low" ? (
//                   <>
//                     <span style={styles.marginRight}>
//                       <IconCircle />
//                     </span>
//                     <span
//                       className="icon-priority"
//                       aria-label="violation is low"
//                     >
//                       Low
//                     </span>
//                   </>
//                 ) : (
//                   <></>
//                 )}
//               </Box>
//             </div>
//           ),
//           standard: <div style={{ fontWeight: 600 }}>WCAG</div>,
//           criteria: (
//             <p
//               onClick={() =>
//                 window.open(
//                   `/code-summary?${new URLSearchParams({
//                     code: criteria,
//                     scanId,
//                   }).toString()}`
//                 )
//               }
//               style={{
//                 textDecoration: "underline",
//                 cursor: "pointer",
//                 margin: "0px 1px",
//                 fontWeight: 600,
//               }}
//               aria-label={`criteria is ${criteria}`}
//             >
//               {criteria}{" "}
//             </p>
//           ),
//           code: (
//             <div style={{ fontWeight: 600 }} aria-label={`${code}`}>
//               {code}
//             </div>
//           ),
//           selector: (
//             <div style={{ fontWeight: 600 }} aria-label={`${selector}`}>
//               <ReadMore lines={3}> {selector}</ReadMore>
//             </div>
//           ),
//           context: (
//             <div style={{ fontWeight: 600 }} aria-label={`${context}`}>
//               <ReadMore lines={3}>{context}</ReadMore>
//             </div>
//           ),
//           // shortexp: <div style={{ fontWeight: 600 }} aria-label={`${message}`}></div>,
//           issue: {
//             link,
//             code,
//             selector,
//           },
//         });
//       }

//       tblDataAccessibility = tblDataAccessibility.map((item, index) => {
//         return {
//           key: item.key,
//           pageTitle: <Row row={item} />,
//           issue: item.issue,
//         };
//       });

//       return tblDataAccessibility;
//     }
//     let getParsedData = null;

//     if (data && data.length > 0) {
//       getParsedData = getTbl1AccessibilityData(data);

//       setTblData(getParsedData);
//     }
//     return function cleanup() {
//       setTblData(null);

//     }
//   }, [data, scanId]);

//   function Row(props) {
//     const useRowStyles = makeStyles({
//       root: {
//         "& > *": {
//           borderBottom: "none",
//         },
//       },
//     });

//     const { row } = props;
//     const [open, setOpen] = React.useState(false);
//     const classes = useRowStyles();

//     return (
//       <Table style={{ tableLayout: "fixed", marginLeft: "-1.5rem" }}>
//         <TableRow className={classes.root}>
//           <TableCell style={{ width: 30 }}>
//             <IconButton
//               aria-label="expand row"
//               size="small"
//               onClick={() => setOpen(!open)}
//             >
//               {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
//             </IconButton>
//           </TableCell>
//           <TableCell component="th" scope="row">
//             {row.pageTitle}
//           </TableCell>
//         </TableRow>
//         <TableRow>
//           <TableCell
//             style={{ paddingBottom: 0, paddingTop: 0, borderBottom: "none" }}
//             colSpan={2}
//           >
//             <Collapse
//               in={open}
//               timeout="auto"
//               unmountOnExit
//               style={{ overFlow: "auto" }}
//             >
//               <Box margin={1}>
//                 <Table size="small" style={{ overflow: "auto" }}>
//                   <TableHead>
//                     <TableRow style={{ display: "table-row" }}>
//                       <TableCell style={{ minWidth: 150 }}>
//                         Disability Type
//                       </TableCell>
//                       <TableCell style={{ minWidth: 120 }}>Priority</TableCell>
//                       <TableCell style={{ minWidth: 100 }}>Standard</TableCell>
//                       <TableCell style={{ minWidth: 100 }}>Criterion</TableCell>
//                       <TableCell style={{ minWidth: 150 }}>Element</TableCell>
//                       <TableCell style={{ minWidth: 300 }}>
//                         Element Location
//                       </TableCell>
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     <TableRow key={row.key}>
//                       <TableCell component="th" scope="row">
//                         {row.disabilitytype}
//                       </TableCell>
//                       <TableCell>{row.priority}</TableCell>
//                       <TableCell>{row.standard}</TableCell>
//                       <TableCell>{row.criteria}</TableCell>
//                       <TableCell>{row.selector}</TableCell>
//                       <TableCell>{row.context}</TableCell>
//                     </TableRow>
//                   </TableBody>
//                 </Table>
//               </Box>
//             </Collapse>
//           </TableCell>
//         </TableRow>
//       </Table>
//     );
//   }

//   function archivedActions(selectedRowIds, options) {
//     const issues = selectedRowIds.map((item) => {
//       return item.original.issue;
//     });
//     const readyData = {
//       scanId: scanId,
//       archiveOptions: {
//         ...options,
//       },
//       issues,
//     };
//     dispatch(archiveAccessibilityLinks(readyData, scanId, []));
//   }

//   function completeAction(selectedRowIds) {
//     const issues = selectedRowIds.map((item) => {
//       return item.original.issue;
//     });
//     const readyData = {
//       scanId: scanId,
//       issues,
//     };
//     dispatch(completeAccessibilityLinks(readyData, scanId));
//   }

//   function ignoreAction(selectedRowIds) {
//     const issues = selectedRowIds.map((item) => {
//       return item.original.issue;
//     });
//     const readyData = {
//       scanId: scanId,
//       issues,
//     };
//     dispatch(ignoreAccessibilityLinks(readyData, scanId));
//   }

//   return (
//     <TablesCard tabs={tabs} title="" status={status}>
//       {tblData ? (
//         <>
//           {tblData.length > 0 ? (
//             <ReactTable
//               tableColumns={accessibilityColumns}
//               data={tblData}
//               selection={true}
//               ignoreAction={ignoreAction}
//               completeAction={completeAction}
//               archivedActions={archivedActions}
//               pages={pages}
//               count={count}
//               page={page}
//               setPage={setPage}
//             />
//           ) : (
//             <Box
//               display="flex"
//               justifyContent="center"
//               alignItems="center"
//               style={{ minHeight: "200px", maxHeight: "200px" }}
//             >
//               <h4>We found no Accessibility Issues on your site</h4>
//             </Box>
//           )}{" "}
//         </>
//       ) : (
//         <Box
//           display="flex"
//           justifyContent="center"
//           alignItems="center"
//           style={{ minHeight: "200px", maxHeight: "200px" }}
//         >
//           <img src={loader} alt="loader" />
//         </Box>
//       )}
//     </TablesCard>
//   );
// }
export default function AccessibilityTable({ scanId }) {
  const [tblData, setTblData] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const [technologyData, setTechnologyData] = React.useState([]);
  const [wordpressCms, setWordPressCms] = React.useState(false);

  const dispatch = useDispatch();
  const data = useSelector((state) => state.dashboard.accessibilityTableOne);
  const pages = useSelector(
    (state) => state.dashboard.accessibilityTableOnePages
  );
  const count = useSelector(
    (state) => state.dashboard.accessibilityTableOneCount
  );

  const status = useSelector(
    (state) => state.dashboard.accessibilityTableOneStatus
  );
  const loading = useSelector(
    (state) => state.dashboard.accessibilityTableOneLoading
  );

  const isSelectedDomainLink = useSelector(
    (state) => state.dashboard.selectedLink
  );

  React.useEffect(() => {
    const fetchData = async () => {
      if (scanId) {
        try {
          const response = await dispatch(getTechnologies(scanId, false));
          const hasWordPress = response.result.some(
            (item) => item.name.toLowerCase() === "wordpress"
          );
          setTechnologyData(response);
          setWordPressCms(hasWordPress);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();

    // eslint-disable-next-line
  }, [scanId]);

  React.useEffect(() => {
    if (scanId) {
      dispatch(getTableAccessibilityTableOne(scanId, page));
    } else {
      dispatch(getTableAccessibilityTableOne(scanId, page));
    }
    // eslint-disable-next-line
  }, [scanId, page]);

  React.useEffect(() => {
    if (status === "pending" || status === "running") {
      const interval = setInterval(() => {
        if (scanId) {
          dispatch(getTableAccessibilityTableOne(scanId, page));
        } else {
          dispatch(getTableAccessibilityTableOne(scanId, page));
        }
      }, 15000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [status, scanId, page]);

  React.useEffect(() => {
    function getTbl1AccessibilityData(dataFortbl1) {
      const tblDataAccessibility = [];

      for (let i = 0; i < dataFortbl1.length; i++) {
        const link = dataFortbl1[i].pageUrl;
        const message = dataFortbl1[i].message;
        const icon = dataFortbl1[i].priority;
        const code = dataFortbl1[i].code;
        const selector = dataFortbl1[i].selector;
        const context = dataFortbl1[i].context;
        const pageTitle = dataFortbl1[i].documentTitle;
        let criteria = dataFortbl1[i].criterion;
        const disabilityType = dataFortbl1[i].type;
        const suggested = dataFortbl1[i]?.suggested;
        const linkId = dataFortbl1[i]?.linkId;
        const issueId = dataFortbl1[i]?.issueId;
        const fixed = dataFortbl1[i]?.fixes;
        const suggestedDescription = dataFortbl1[i]?.suggested_description;
        const suggestedHtml = dataFortbl1[i]?.suggested_html;
        tblDataAccessibility.push({
          key: i,
          pageTitle: (
            <div style={{ fontWeight: 600, ...styles.textCenter }}>
              <LaunchIcon
                style={{ fontSize: "1.2rem" }}
                onClick={() => window.open(`${link}`, "_blank")}
                aria-label={`Open link ${link}`}
              />
              <ReadMore lines={1}>{pageTitle}</ReadMore>
            </div>
          ),

          disabilitytype: (
            <Box display="flex">
              {disabilityType.map((type, index) => (
                <Box key={index} display="flex">
                  {
                    // eslint-disable-next-line
                    (type === "visual" && <Visual />) ||
                    // eslint-disable-next-line
                    (type === "hearing" && <Hearing />) ||
                    // eslint-disable-next-line
                    (type === "motor" && <Motor />) ||
                    // eslint-disable-next-line
                    (type === "cognitive" && <Cognitive />)
                  }
                </Box>
              ))}
            </Box>
          ),
          priority: (
            <div className="disability-type">
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                flexDirection="row"
              >
                {icon === "high" ? (
                  <>
                    <span style={styles.marginRight}>
                      <IconTriangle />
                    </span>
                    <span
                      className="icon-priority"
                      aria-label="violation is high"
                    >
                      High
                    </span>
                  </>
                ) : icon === "medium" ? (
                  <>
                    <span style={styles.marginRight}>
                      <IconSquare />
                    </span>
                    <span
                      className="icon-priority"
                      aria-label="violation is medium"
                    >
                      Medium
                    </span>
                  </>
                ) : icon === "low" ? (
                  <>
                    <span style={styles.marginRight}>
                      <IconCircle />
                    </span>
                    <span
                      className="icon-priority"
                      aria-label="violation is low"
                    >
                      Low
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </div>
          ),
          standard: <div style={{ fontWeight: 600 }}>WCAG</div>,
          criteria: (
            <p
              onClick={() =>
                window.open(
                  `/code-summary?${new URLSearchParams({
                    code: criteria,
                    scanId,
                  }).toString()}`
                )
              }
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                margin: "0px 1px",
                fontWeight: 600,
              }}
              aria-label={`criteria is ${criteria}`}
            >
              {criteria}
            </p>
          ),
          code: (
            <div style={{ fontWeight: 600 }} aria-label={`${code}`}>
              {code}
            </div>
          ),
          //Element
          selector: (
            <div
              style={{ fontWeight: 600, maxWidth: 200 }}
              aria-label={`${selector}`}
            >
              <ReadMore lines={2}> {selector}</ReadMore>
            </div>
          ),
          //Element Location
          context: (
            <div
              style={{ fontWeight: 600, maxWidth: 200 }}
              aria-label={`${context}`}
            >
              <ReadMore lines={2}> {context}</ReadMore>
            </div>
          ),
          shortexp: (
            <div style={{ fontWeight: 600 }} aria-label={`${message}`}>
              <ReadMore lines={2}>{message}</ReadMore>
            </div>
          ),
          issue: {
            link,
            code,
            selector,
            linkId,
            issueId,
            suggested,
            suggestedDescription,
            suggestedHtml,
            fixed: true,
          },
          suggested: (
            <div
              style={{ fontWeight: 200, maxWidth: 50 }}
              aria-label={`${suggested || "false"}`}
            >
              {suggested ? suggested.toString() : "false"}
            </div>
          ),
          fixes: (
            <div
              style={{ fontWeight: 200, maxWidth: 50 }}
              aria-label={`${fixed || "false"}`}
            >
              {fixed ? fixed.toString() : "false"}
            </div>
          ),
          suggested_description: (
            <div
              style={{ fontWeight: 600, maxWidth: 200 }}
              aria-label={`${suggestedDescription}`}
            >
              <ReadMore lines={2}> {suggestedDescription}</ReadMore>
            </div>
          ),
          suggested_html: (
            <div
              style={{ fontWeight: 600, maxWidth: 200 }}
              aria-label={`${suggestedHtml}`}
            >
              <ReadMore lines={2}> {suggestedHtml}</ReadMore>
            </div>
          ),
        });
      }
      return tblDataAccessibility;
    }
    let getParsedData = null;

    if (data) {
      getParsedData = getTbl1AccessibilityData(data);
      if (isSelectedDomainLink === "" || isSelectedDomainLink === undefined) {
        setTblData(getParsedData);
      } else {
        const filteredData = getParsedData.filter((item) => {
          return item.issue.link === isSelectedDomainLink;
        });
        setTblData(filteredData);
      }
    }
  }, [data, scanId, isSelectedDomainLink]);

  function archivedActions(selectedRowIds, options) {
    const issues = selectedRowIds.map((item) => {
      return item.original.issue;
    });
    const readyData = {
      scanId: scanId,
      archiveOptions: {
        ...options,
      },
      issues,
    };
    dispatch(archiveAccessibilityLinks(readyData, scanId, []));
  }

  function fixActions(selectedRowIds, options) {
    if (wordpressCms) {
      const issues = selectedRowIds.map((item) => {
        return item.original.issue;
      });
      const readyData = {
        issues,
      };
      dispatch(fixAccessibilityIssues(readyData, []));
    } else {
      toast.warning("Not a wordpress cms");
    }
  }

  function completeAction(selectedRowIds) {
    const issues = selectedRowIds.map((item) => {
      return item.original.issue;
    });
    const readyData = {
      scanId: scanId,
      issues,
    };
    dispatch(completeAccessibilityLinks(readyData, scanId));
  }

  function ignoreAction(selectedRowIds) {
    const issues = selectedRowIds.map((item) => {
      return item.original.issue;
    });
    const readyData = {
      scanId: scanId,
      issues,
    };
    dispatch(ignoreAccessibilityLinks(readyData, scanId));
  }

  return (
    <TablesCard title="" status={status}>
      {tblData && !loading ? (
        <>
          {tblData.length > 0 ? (
            <ReactTable
              tableColumns={accessibilityColumns}
              data={tblData}
              selection={true}
              ignoreAction={ignoreAction}
              completeAction={completeAction}
              archivedActions={archivedActions}
              fixActions={fixActions}
              pages={pages}
              count={count}
              page={page}
              setPage={setPage}
            />
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ minHeight: "200px", maxHeight: "200px" }}
              role="alert"
              aria-live="assertive"
            >
              <h4>Please wait while we load your data</h4>
            </Box>
          )}
        </>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "200px", maxHeight: "200px" }}
          role="status"
          aria-live="polite"
        >
          <img src={loader} alt="loader" />
        </Box>
      )}
    </TablesCard>
  );
}
