import React from 'react';

export default function Tricon({ type, width = 24, height = 24 }) {
	let color = "#1F3A93";
	if (type === "success") {
		color = "#3AAA35"
	}
	if (type === "primary") {
		color = "#1F3A93"
	}
	if (type === "warning") {
		color = "#FFA412"
	}
	if (type === "danger") {
		color = "#E3211F"
	}
	if (type === "maroon") {
		color = "#96281B"
	}
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={`${width}`}
			height={`${height}`}
			fill={color}
			viewBox="0 0 24 24"
			role="img"
  			aria-label="tricon icon"
			>
			<path d="M12 3L2 21h20z" /></svg>
	);
}
