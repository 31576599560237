import React from "react";
import CardContainer from "../components/commons/CardContainer";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import loaderwave from "../assets/loader.svg";

import NoDomain from "../components/commons/NoDomain";
import { startBasicScan } from "../store/actions/customScanPageListActions";

export default function BasicScan({ options }) {

  const [delayed, setDelayed] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const selectedDomain = useSelector((state) => state.miscellaneous.selectedDomain);
  const selectedDomainId = useSelector((state) => state.dashboard.selectedDomainId);
  const isDomainPurchased = useSelector((state) => state.dashboard.isDomainPackagePurchased);

  const classes = useStyles();
  const dispatch = useDispatch();

  React.useEffect(() => {
    setTimeout(() => {
      setDelayed(false)
    }, 1500);
  }, [])

  function startScan() {
    setLoading(true)
    if (selectedDomainId) {
      dispatch(startBasicScan({
        scanOptions: options.scanOptions,
        sitemapOptions: options.sitemapOptions,
        modules: Object.keys(options.modules).map(item => {
          if (options.modules[item]) {
            return item
          }
        }), domainId: selectedDomainId
      })).then((data) => {
        if (data) {
          setLoading(false);
        }
      });
    }
  }


  if (!isDomainPurchased) {
    return window.location.pathname = "/dashboard"
  } else {
    return (
      <>
        {!delayed ? (
          <React.Fragment>
            {selectedDomainId ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <Box style={{ maxWidth: 700, width: "100%" }}>
                  <CardContainer>
                    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                      <p className={classes.domain}>Domain: {selectedDomain}</p>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        size="large"
                        onClick={() => startScan()}
                        aria-label="Start Scan"
                      >
                        Start Scan {loading && <CircularProgress size={20} style={{ marginLeft: 10 }} />}
                      </Button>
                    </Box>
                  </CardContainer>
                </Box>
              </Box>
            ) : (
              <NoDomain text="Please add Domain " />
            )}
          </React.Fragment>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            tabIndex="0"
            role="status"
            aria-live="polite"
          >
            <img src={loaderwave} alt="loader" />
          </Box>
        )}
      </>
    );
  }
}

const useStyles = makeStyles((theme) => ({
  domain: {
    fontSize: 24,
    fontFamily: "Segoe UI"
  },
}));