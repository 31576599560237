import React from "react";
import { useDispatch } from "react-redux";
import { getCveExploitsTable } from "../../../store/actions/securityTablesActions";
import TablesCard from "../../commons/TablesCard";
import DescriptionIcon from "@material-ui/icons/Description";
import Box from "@material-ui/core/Box";

import loader from "../../../assets/loader.svg";
import CVEExploitTable from "../../../pages/cve/CVEExploitTable";

export default function CveExploits({ scanId, tech }) {
  const [tblData, setTblData] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const [data, setData] = React.useState(null);
  const [status, setStatus] = React.useState(null);

  const [recieveUpdatedData, setRecieveUpdatedData] = React.useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (scanId) {
      dispatch(getCveExploitsTable(scanId, page, tech, false, false)).then(
        (result) => {
          if (result) {
            setData(result);
            setStatus(result.status);
          }
        }
      );
    }
    // eslint-disable-next-line
  }, [scanId, page]);

  React.useEffect(() => {
    if (status === "pending" || status === "running") {
      const interval = setInterval(() => {
        if (scanId) {
          dispatch(getCveExploitsTable(scanId, page, tech, false, false)).then(
            (result) => {
              if (result) {
                setData(result);
                setStatus(result.status);
              }
            }
          );
        }
      }, 15000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [status, scanId, page]);

  React.useEffect(() => {
    if (recieveUpdatedData) {
      dispatch(getCveExploitsTable(scanId, page, tech, false, false)).then(
        (result) => {
          if (result) {
            setData(result);
            setStatus(result.status);
          }
        }
      );
    }
  }, [recieveUpdatedData]);

  React.useEffect(() => {
    if (data) {
      setTblData(data.result);
    }
  }, [data, scanId]);

  return (
    <TablesCard
      icon={<DescriptionIcon />}
      title={`${tech} CVE's`}
      status={status}
    >
      {tblData ? (
        <>
          {tblData.length > 0 ? (
            <CVEExploitTable
              data={tblData}
              scanId={scanId}
              setPage={setPage}
              pages={data.pages}
              archive={false}
              dismiss={false}
              tech={tech}
              setRecieveUpdatedData={setRecieveUpdatedData}
            />
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ minHeight: "200px", maxHeight: "200px" }}
              role="alert"
              aria-live="assertive"
            >
              <h4>We found no CVEs for {tech}</h4>
            </Box>
          )}
        </>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "200px", maxHeight: "200px" }}
          role="status"
          aria-live="polite"
        >
          <img src={loader} alt="loader" />
        </Box>
      )}
    </TablesCard>
  );
}
