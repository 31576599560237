import React from "react";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import { CardNote } from "../../accessibility-main/components/CardNote";
import { IconCircle } from "../../commons/icons/accessibilitymainIcons/icons/IconCircle";
import ShortenNumber from "../../commons/ShortenNumber";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import PerformanceTable from "./PerformanceTable";
import { nFormatter } from "../../../utils/shortNumber";
import SubLoader from "../../commons/SubLoader";

const styles = {
  cardMain: {
    background: "rgb(255, 255, 255)",
    boxSizing: "border-box",
    borderRadius: 9,
    justifyContent: "center",
    alignItems: "center",
  },
  boxContainer: {
    background: "#FFFFFF",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.12)",
    boxSizing: "border-box",
    borderRadius: 9,
    display: "flex",
    justifyContent: "center",
    // alignItems: "center",
    padding: "14px",
    height: "100%",
  },
  siteStatus: {
    fontFamily: "Segoe UI",
    fontWeight: 560,
    fontSize: 20,
    textTransform: "capitalize",
    margin: "0px 0px 15px 0px",
  },
  subTitle: {
    fontFamily: "Segoe UI",
    color: "#484848",
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: "0em",
    lineHeight: 2,
    margin: 0,
  },
  barPercentage: {
    fontFamily: "Segoe UI",
    fontSize: 19,
    fontWeight: 700,
    letterSpacing: "0em",
    color: "#1f3a93",
    marginRight: 4,
  },
  vulPages: {
    fontFamily: "Segoe UI",
    fontSize: 21,
    fontWeight: 700,
    letterSpacing: "0em",
    color: "#1f3a93",
  },
  vulPagesTitle: {
    fontFamily: "Segoe UI",
    fontWeight: 560,
    fontSize: 20,
    textTransform: "capitalize",
    color: "#000",
    margin: 0,
  },
  avgperPageTitle: {
    fontFamily: "Segoe UI",
    fontWeight: 560,
    fontSize: 20,
    marginBottom: "15px",
    textAlign: "center",
    margin: 0,
  },
  totalVulnerablitiesTitle: {
    fontFamily: "Segoe UI",
    fontWeight: 560,
    fontSize: 20,
    margin: "0px 0px 6px 0px",
    textAlign: "center",
  },
  ViolationsByPriorityTitle: {
    fontFamily: "Segoe UI",
    fontWeight: 560,
    fontSize: 20,
    margin: "0px 0px 10px 0px",
    textAlign: "center",
  },
  avgperPageValue: {
    fontFamily: "Segoe UI",
    fontSize: 64,
    textAlign: "right",
  },
  avgChangeValue: {
    fontFamily: "Segoe UI",
    fontSize: 14,
  },
  pieCahrtValue: {
    fontFamily: "Segoe UI",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 21,
    textAlign: "right",
  },
  priorityValue: {
    fontFamily: "Segoe UI",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 20,
    textAlign: "right",
    minWidth: 35,
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Segoe UI",
    fontWeight: "bold",
    fontSize: 15,
    textAlign: "center",
    color: "white",
    textTransform: "uppercase",
    width: "fit-content",
    borderRadius: 8,
  },
  chipLabel: {
    marginTop: 3,
  },
}));

const columns = [
  {
    column: "",
    width: 30,
  },
  {
    column: "Total",
    width: 64,
  },
  // {
  //   column: "Vulnerability Type",
  //   width: 230
  // },
  {
    column: "Priority",
    width: 200,
  },
];

function PerformanceSummary({
  data,
  loading,
  tableData,
  tabs,
  securityMainStatus,
}) {
  const classes = useStyles();

  const [donutData, setDonutData] = React.useState([]);

  // const data2 = [{ name: 'Group A', value: 600 }, { name: 'Group B', value: 300 },
  // { name: 'Group C', value: 300 }];

  function parseDonutData(data) {
    const parsedData = [];
    const keys = Object.keys(data.vulnerablePrioties);

    for (let i = 0; i < keys.length; i++) {
      if (keys[i] === "critical") {
        parsedData.push({
          name: "Critical",
          value: data.vulnerablePrioties.critical,
        });
      } else if (keys[i] === "moderate") {
        parsedData.push({
          name: "Moderate",
          value: data.vulnerablePrioties.moderate,
        });
      } else {
        parsedData.push({
          name: "Low",
          value: data.vulnerablePrioties.low,
        });
      }
    }
    return parsedData;
  }

  React.useEffect(() => {
    if (data) {
      const parsedData = parseDonutData(data);
      setDonutData(parsedData);
    }
  }, [data]);

  const pagesColumns = [
    {
      column: "",
      width: 30,
    },
    {
      column: "Total",
      width: 64,
    },
    {
      column: "Pages",
      width: 230,
    },
    {
      column: "Priority",
      width: 200,
    },
  ];

  const COLORS = ["#E2211E", "#FFA412", "#21CA5A"];
  // const RADIAN = Math.PI / 180;

  // const statUp = (value) => <Box display="flex" alignItems="center" mt={1}><ExpandLessIcon style={{ color: "#E2211E", fontSize: "2.2em" }} /><span style={styles.avgChangeValue}>{value}</span> </Box>
  // const statSame = (value) => <Box display="flex" alignItems="center" mt={1}><DragHandleIcon style={{ fontSize: "1.6em" }} /><span style={styles.avgChangeValue}>{value}</span> </Box>
  // const statDown = (value) => <Box display="flex" alignItems="center" mt={1}><ExpandMoreIcon style={{ color: "#21CA5A", fontSize: "2.2em" }} /><span style={styles.avgChangeValue}>{value}</span> </Box>

  return (
    <React.Fragment>
      {!loading ? (
        <>
          {data && data.totalVulnerabilitiesFound !== 0 ? (
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <Box style={styles.cardMain}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3} tabIndex="0" aria-label="Security Score">
                      <Box style={styles.boxContainer}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          height="100%"
                        >
                          <h2 style={styles.siteStatus}> Security Score </h2>
                          <CardNote
                            size="big"
                            className={clsx(
                              data.scoreType === "high"
                                ? "circle-critical"
                                : data.scoreType === "medium"
                                ? "circle-moderate2"
                                : "circle-safe"
                            )}
                          >
                            {data.score}
                          </CardNote>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} tabIndex="0" aria-label="Site Status">
                      <Box style={styles.boxContainer}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          height="100%"
                        >
                          <h2 style={styles.siteStatus}> Site Status </h2>
                          <Chip
                            classes={{ label: classes.chipLabel }}
                            className={classes.root}
                            style={{
                              backgroundColor: data.siteStatus
                                ? "#21CA5A"
                                : "#E2211E",
                            }}
                            size="small"
                            label={data.siteStatus ? "Live" : "Down"}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} tabIndex="0" aria-label="Vulnerable Pages">
                      <Box style={styles.boxContainer}>
                        <Box width="100%">
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            mb={2.5}
                          >
                            <h2 style={styles.vulPagesTitle}>
                              Vulnerable Pages
                            </h2>
                            <span style={styles.vulPages}>
                              <ShortenNumber>
                                {data.totalVulnerablePages}
                              </ShortenNumber>
                            </span>
                          </Box>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <span style={styles.barPercentage}>
                              {data.totalVulnerablePagesPercentage}%
                            </span>
                            <Box width="100%">
                              <BorderLinearProgress
                                variant="determinate"
                                value={data.totalVulnerablePagesPercentage}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} tabIndex="0" aria-label="Average Per Page">
                      <Box style={styles.boxContainer}>
                        <Box
                          display="flex"
                          justifyContent="center"
                          flexDirection="column"
                          alignItems="center"
                          height="100%"
                        >
                          <h2 style={styles.avgperPageTitle}>
                            {" "}
                            Average <br /> Per Page{" "}
                          </h2>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <span
                              style={styles.avgperPageValue}
                              className={clsx(
                                data.scoreType === "high"
                                  ? "color-critical"
                                  : data.scoreType === "medium"
                                  ? "color-moderate2"
                                  : "color-safe"
                              )}
                            >
                              {data.totalAverage}
                            </span>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} tabIndex="0" aria-label="Total Vulnerabilities">
                      <Box style={styles.boxContainer}>
                        <Box
                          display="flex"
                          justifyContent="center"
                          flexDirection="column"
                          alignItems="center"
                          height="100%"
                        >
                          <h2 style={styles.totalVulnerablitiesTitle}>
                            {" "}
                            Total <br /> Vulnerabilities{" "}
                          </h2>
                          <ResponsiveContainer width={"99%"} height={100}>
                            <PieChart width={50} height={50}>
                              <text
                                style={styles.pieCahrtValue}
                                x={"50%"}
                                y={"50%"}
                                textAnchor="middle"
                                dominantBaseline="middle"
                              >
                                {data.totalVulnerabilitiesFound &&
                                  nFormatter(
                                    data.totalVulnerabilitiesFound,
                                    10000
                                  )}
                              </text>
                              <Pie
                                dataKey="value"
                                data={donutData}
                                cx={"50%"}
                                cy={"50%"}
                                innerRadius={40}
                                outerRadius={50}
                                fill="#8884d8"
                              >
                                {donutData.map((entry, index) => (
                                  <Cell
                                    key={index}
                                    fill={COLORS[index % COLORS.length]}
                                  />
                                ))}
                              </Pie>
                            </PieChart>
                          </ResponsiveContainer>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} tabIndex="0" aria-label="Vulnerabilities by Priority">
                      <Box style={styles.boxContainer}>
                        <Box
                          display="flex"
                          justifyContent="center"
                          flexDirection="column"
                          alignItems="center"
                          height="100%"
                        >
                          <h2 style={styles.ViolationsByPriorityTitle}>
                            {" "}
                            Vulnerabilities <br /> by Priority{" "}
                          </h2>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <div>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                mb={1}
                              >
                                <span
                                  style={styles.priorityValue}
                                  className="color-critical"
                                >
                                  {" "}
                                  <ShortenNumber>
                                    {data.vulnerablePrioties.critical}
                                  </ShortenNumber>
                                </span>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  style={{ minWidth: "90px", maxWidth: "90px" }}
                                >
                                  <span style={{ margin: "0px 5px" }}>
                                    <IconCircle
                                      fill="#E2211E"
                                      height="6px"
                                      width="6px"
                                    />
                                  </span>
                                  <span style={{ fontSize: "18px" }}>
                                    Critical
                                  </span>
                                </Box>
                              </Box>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                mb={1}
                              >
                                <span
                                  style={styles.priorityValue}
                                  className="color-moderate2"
                                >
                                  <ShortenNumber>
                                    {data.vulnerablePrioties.moderate}
                                  </ShortenNumber>
                                </span>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  style={{ minWidth: "90px", maxWidth: "90px" }}
                                >
                                  <span style={{ margin: "0px 5px" }}>
                                    <IconCircle
                                      fill="#FFA412"
                                      height="6px"
                                      width="6px"
                                    />
                                  </span>
                                  <span style={{ fontSize: "18px" }}>
                                    Moderate
                                  </span>
                                </Box>
                              </Box>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <span
                                  style={styles.priorityValue}
                                  className="color-safe"
                                >
                                  <ShortenNumber>
                                    {data.vulnerablePrioties.low}
                                  </ShortenNumber>
                                </span>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  style={{ minWidth: "90px", maxWidth: "90px" }}
                                >
                                  <span style={{ margin: "0px 5px" }}>
                                    <IconCircle
                                      fill="#21CA5A"
                                      height="6px"
                                      width="6px"
                                    />
                                  </span>
                                  <span style={{ fontSize: "18px" }}>Low</span>
                                </Box>
                              </Box>
                            </div>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} lg={6}>
                <PerformanceTable
                  columns={columns}
                  tabs={tabs}
                  columns2={pagesColumns}
                  data={tableData}
                  totalViolations={data.totalVulnerabilitiesFound}
                />
              </Grid>
            </Grid>
          ) : (
            <>
              {securityMainStatus === "completed" ? (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  p={5}
                  style={{ minHeight: "150px" }}
                  role="alert"
                  aria-live="assertive"
                >
                  <b>
                    Congratulations! <br /> We didn't find any security
                    vulnerability on your website
                  </b>
                </Box>
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  p={5}
                  style={{ minHeight: "150px" }}
                  role="status"
                  aria-live="polite"
                >
                  <SubLoader />
                </Box>
              )}
            </>
          )}
        </>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "150px" }}
          role="status"
          aria-live="polite"
        >
          <SubLoader />
        </Box>
      )}
    </React.Fragment>
  );
}

export default React.memo(PerformanceSummary);

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 10,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 10,
    backgroundColor: "#1f3a93",
  },
}))(LinearProgress);
