import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addDomain, getDomains, afterAddDomainAdded } from '../../store/actions/dashboardActions';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from "@material-ui/core/Snackbar";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Auth0Service from "../../services/auth0Service/authentikService";
import user from '../../auth/store/reducers/user.reducer';

export default function AddDomainLinkDialog({ onClose, open }) {

  const [domain, setDomain] = React.useState("");
  const [demo, setDemo] = React.useState(false);
  const [environment, setEnvironment] = React.useState(null);

  const dispatch = useDispatch();
  // const user = useSelector(state => state.auth.user);
  const loading = useSelector(state => state.dashboard.addDomainLoading);
  const addedDomain = useSelector(state => state.dashboard.addDomainSuccess);
  const userRole = useSelector(state => state.auth.user.role[0]);

  const [snackBarOpen, setSnackBarOpen] = React.useState({
    openSnack: false,
    vertical: "bottom",
    horizontal: "left",
  });

  const { vertical, horizontal, openSnack } = snackBarOpen;

  const handleCloseSnackBar = () => {
    setSnackBarOpen({ ...snackBarOpen, openSnack: false });
  };

  const handleDomainChange = (event) => {
    setDomain(event.target.value);
  };

  function onDomainSubmit(event) {
    console.log("onDomainSubmit")
    event.preventDefault();
    // Auth0Service.getUserEmail()
    Auth0Service.getUserData()
      .then(userData => {
        console.log(userData);
        dispatch(addDomain(domain,userData.email, demo)).then(data => {
          if (data) {
            onClose();
            setDomain("");
            dispatch(afterAddDomainAdded());
            dispatch(getDomains())

          }
        });
      })
      .catch(error => {
        console.error('Error occurred:', error);
      });

  };


  React.useEffect(() => {
    const currentUrl = window.location.href;
    const hostname = window.location.hostname;
    const pathname = window.location.pathname;


        // Logic based on URL content
        let checkenvironment = 'unknown';
    if (hostname.includes('dashboard.allyright.com')) {
      checkenvironment = 'production';
      setEnvironment(checkenvironment)
      setDemo(false)
    } else if (hostname.includes('demo.allyright.com')) {
      checkenvironment = 'demo';
      setEnvironment(checkenvironment)
      setDemo(true)
    } else if (hostname.includes('localhost')) {
      checkenvironment = 'local';
      setEnvironment(checkenvironment)
      setDemo(false)
    } else if (hostname.includes('vps1.allyright.com')) {
      checkenvironment = 'demo';
      setEnvironment(checkenvironment)
      setDemo(true)
    } else if (hostname.includes('vps2.allyright.com')) {
      checkenvironment = 'demo';
      setEnvironment(checkenvironment)
      setDemo(true)
    }
  }, [environment])

  function closeDialog() {
    dispatch(afterAddDomainAdded())
    dispatch(getDomains())
    setDomain("")
    onClose();
  }

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        aria-describedby="dialog-description"
        fullWidth
        maxWidth="sm"
      >
        {userRole === "admin" ? (
          <>
            <DialogTitle id="form-dialog-title">Add Domain</DialogTitle>
            <DialogContent>
              {!addedDomain ? (
                <form noValidate autoComplete="off" onSubmit={onDomainSubmit}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="Domain"
                    label="Enter Domain"
                    type="text"
                    fullWidth
                    onChange={handleDomainChange}
                    disabled={loading}
                    inputProps={{ "aria-label": "Enter Domain" }}
                  />
  
                  <DialogActions>
                    <Button onClick={onClose} color="primary" disabled={loading} aria-label="Cancel">
                      Cancel
                    </Button>
                    <Button color="primary" type="submit" disabled={loading} aria-label="Add Domain">
                      Add {loading && (
                        <CircularProgress
                          style={{ color: "black", marginLeft: "1rem" }}
                          size={20}
                          aria-label="Loading"
                        />
                      )}
                    </Button>
                  </DialogActions>
                </form>
              ) : (
                <form>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="Domain"
                    label="Domain Added"
                    type="text"
                    fullWidth
                    disabled
                    value={addedDomain}
                    inputProps={{ "aria-label": "Domain Added" }}
                  />
                  <DialogActions>
                    <Button color="primary" onClick={closeDialog} aria-label="Close dialog">
                      OK
                    </Button>
                  </DialogActions>
                </form>
              )}
            </DialogContent>
          </>
        ) : (
          <>
            <DialogTitle>Access Restricted</DialogTitle>
            <DialogContent>
              <p id="dialog-description">
                Sorry! You do not have access to add a website.
              </p>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={closeDialog} aria-label="Close dialog">
                OK
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSnack}
        onClose={handleCloseSnackBar}
        message="URL is not valid"
        aria-live="assertive"
        autoHideDuration={6000}
        role="alert"
      />
    </>
  );
}