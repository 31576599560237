import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiTabs from '@material-ui/core/Tabs';

const AntTabs = withStyles({
  indicator: {
    backgroundColor: '#1890ff',
  },
})(MuiTabs);


const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    flexGrow: 1,

  },
}));

export default function Tabs(props) {
  const classes = useStyles();


  return (
  
      <div className={classes.demo1}>
        <AntTabs {...props}
              role="tab" // Define the role of the tab for assistive technologies
              aria-selected={props.selected} // Indicate whether the tab is selected
              aria-controls={props['aria-controls']} // Reference the associated panel ID
              id={props.id} // Ensure the tab has an ID for accessibility
              >
          {props.children}
        </AntTabs>
      </div>
    
  );
}
