import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import { getChecklists, submitChecklist, resetChecklist } from '../store/actions/checklistActions'
import CheckList from './checklistComponents/CheckList';

import loaderwave from "../assets/loader.svg";

export default function CheckLists() {
  const dispatch = useDispatch();

  const lists = useSelector((state) => state.checklists.lists);
  const type = useSelector((state) => state.checklists.type);
  const isDomainPurchased = useSelector((state) => state.dashboard.isDomainPackagePurchased);

  const loading = useSelector((state) => state.checklists.loading);

  const [mainCheckItems, setMainCheckItems] = React.useState([]);

  React.useEffect(() => {
    dispatch(getChecklists())
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if (lists) {
      setMainCheckItems(lists)
    }
    // eslint-disable-next-line
  }, [lists])

  if (!isDomainPurchased) {
    return window.location.pathname = "/dashboard"
  } else {
    return (
      <>
        {!loading ? (
          <Grid container spacing={3}>
            {lists.length > 0 ? (
              <>
                {lists.map((list) => (
                  <CheckList
                    key={list.id}
                    list={list}
                    mainCheckItems={mainCheckItems}
                    setMainCheckItems={setMainCheckItems}
                    type={type}
                  />
                ))}
                {type === "main" ? (
                  <Box width="100%" textAlign="left" px={5}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!mainCheckItems.some((val) =>
                        val.checkItems.some((val) => val.checked === true)
                      )}
                      onClick={() => dispatch(submitChecklist(mainCheckItems))}
                      aria-label="Submit Checklist"
                    >
                      Submit
                    </Button>
                  </Box>
                ) : (
                  <Box width="100%" textAlign="left" px={5}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!mainCheckItems.some((val) =>
                        val.checkItems.some((val) => val.checked === true)
                      )}
                      onClick={() => dispatch(resetChecklist())}
                      aria-label="Reset Checklist"
                    >
                      Reset
                    </Button>
                  </Box>
                )}
              </>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
                width="100%"
                role="alert"
                aria-live="assertive"
              >
                <h2>No list Found</h2>
              </Box>
            )}
          </Grid>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            tabIndex="0"
            role="status"
            aria-live="polite"
          >
            <img src={loaderwave} alt="loader" />
          </Box>
        )}
      </>
    );
  }

}

