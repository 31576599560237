import React from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';

import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { toast } from 'react-toastify';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useDropzone } from 'react-dropzone';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import MuiTypography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useLocation } from "react-router-dom";
import criteriaList from "../utils/criteria";
import { addNewIssue, getTableIds } from '../store/actions/issueTrackerActions'
import CircularProgress from '@material-ui/core/CircularProgress';
import Auth0Service from "../services/auth0Service/authentikService";
import addIssueStyles from "../Styles/jss/addIssueStyles";

var ID = function () {
  return Math.random().toString(10).substr(2, 9);
};

export default function AddIssue() {

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const [postify, setPostify] = React.useState({ scanId: "", tableId: "" });
  const [category, setCategory] = React.useState("");
  const loading = useSelector(state => state.issueTracker.addIssueLoading);
  const tablesId = useSelector(state => state.issueTracker.tablesId);

  const domains = useSelector(state => state.dashboard.domains);


  const [file, setFile] = React.useState({
    filename: "",
    fileSize: "",
    fileData: "",
    contentType: ""
  })

  let query = useQuery();

  const [issueFields, setIssueFields] = React.useState({
    issueData: {
      bugId: ID(),
      title: "",
      url: "",
      standard: "",
      criteria: "",
      standard_508: "",
      description: "",
      severity: "",
      dueDate: "",
      reproduceable: "",
      isAttachment: false,
      attachment: {
        filename: "",
        fileSize: "",
        fileData: "",
        contentType: ""
      }
    }
  })

  const types = [
    "accessibility",
    "security"
  ]

  React.useEffect(() => {
    if (domains && domains.length > 0) {
      const domain = query.get("domain");
      const type = query.get("type");
      if (types.includes(type) && domains.filter(dom => dom.domain === domain).length > 0) {
        dispatch(getTableIds(domains.filter(dom => dom.domain === domain)[0].domainId, type));
      } else {
        window.location.replace('/issue-tracker')
      }
    }
    // eslint-disable-next-line
  }, [domains]);

  React.useEffect(() => {
    if (domains && domains.length > 0 && tablesId.length > 0) {
      const domain = query.get("domain");
      const scanId = query.get("scanId");
      const tableId = query.get("tableId")
      setPostify({ tableId: tableId, scanId: scanId })
      const type = query.get("type")
      setCategory(type);
      if (types.includes(type) && domains.filter(dom => dom.domain === domain).length > 0 && tablesId.filter(table => table.id === tableId).length > 0) {
        if (domains.filter(dom => dom.domain === domain)[0].scans.filter(scan => scan.scanId === scanId).length > 0) {
          window.history.replaceState(null, null, `?domain=${domain}&scanId=${scanId}&tableId=${tableId}&type=${type}`);
        } else {
          window.location.replace('/issue-tracker')
        }
      } else {
        window.location.replace('/issue-tracker')
      }
    }
    // eslint-disable-next-line
  }, [domains, tablesId]);


  React.useEffect(() => {
    if (file.filename !== "") {
      setIssueFields({ ...issueFields, issueData: { ...issueFields.issueData, isAttachment: true, attachment: file } })
    }
    // eslint-disable-next-line
  }, [file]);


  const dispatch = useDispatch();

  const classes = useStyles();

  const onDrop = React.useCallback(async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      toast.warning("Max File Size Exceeded")
    }
    else {
      const reader = new FileReader()
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onload = () => {
        const binaryStr = reader.result;
        setFile({
          filename: acceptedFiles[0].name,
          fileSize: acceptedFiles[0].size,
          fileData: binaryStr,
          contentType: acceptedFiles[0].type
        })

      }
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed');
    }
    // eslint-disable-next-line
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".doc, .docx, .zip, .xlsx, .xls, .csv, .odt,  .gif,  .pdf, .jpeg, .png  ",
    minSize: 0,
    maxSize: 5242880
  })

  function handleAddIssue(event) {
    event.preventDefault();
    if (issueFields.issueData.title !== "" && issueFields.issueData.url !== "" && issueFields.issueData.dueDate !== "" && issueFields.issueData.description !== "" && issueFields.issueData.reproduceable !== "" && issueFields.issueData.severity !== "") {
      const postData = {
        scanId: postify.scanId, tableId: postify.tableId, issueData: issueFields.issueData
      }
      dispatch(addNewIssue(postData));

    } else {
      toast.error("Please fill the requied fields")
    }
  }
  if (!Auth0Service.isAuthenticated()) {
    return window.location.replace('/authenticate')
  } else {
    return (
      <>
        <Container fixed>
          <div className={classes.mainRow}>
            <h3 className={classes.mainTitle}>New Issue</h3>
          </div>
          {issueFields && <form noValidate style={{ marginTop: "15px" }} onSubmit={handleAddIssue}>

            <CustomGridContainer container spacing={2}>
              <CustomGrid item xs={12} sm={2} >
                Bug ID
              </CustomGrid>
              <Grid item xs={10}>
                <TextField
                  disabled={true}
                  value={issueFields.issueData.bugId}
                  className={classes.bugId}
                  variant="outlined"
                  id="bug-id"
                  placeholder="ID"
                  aria-label="Bug ID"
                />
              </Grid>
            </CustomGridContainer>

            <CustomGridContainer container spacing={2}>
              <CustomGrid item xs={12} sm={2} >
                <span style={{ color: "red" }} >*</span> Title
              </CustomGrid>
              <Grid item xs={10}>
                <TextField
                  disabled={loading}
                  value={issueFields.issueData.title}
                  variant="outlined"
                  id="title"
                  placeholder="Title"
                  onChange={(event) => setIssueFields({ ...issueFields, issueData: { ...issueFields.issueData, title: event.target.value } })}
                  aria-label="Title"
                />
              </Grid>
            </CustomGridContainer>

            <CustomGridContainer container spacing={2}>
              <CustomGrid item xs={12} sm={2} >
                <span style={{ color: "red" }} >*</span> URL
              </CustomGrid>
              <Grid item xs={10}>
                <TextField
                  disabled={loading}
                  value={issueFields.issueData.url}
                  style={{ width: 300, maxWidth: 300 }}
                  variant="outlined"
                  id="url"
                  placeholder="URL"
                  onChange={(event) => setIssueFields({ ...issueFields, issueData: { ...issueFields.issueData, url: event.target.value } })}
                  aria-label="URL"
                />
              </Grid>
            </CustomGridContainer>

            <CustomGridContainer container spacing={2}>
              <CustomGrid item xs={12} sm={2} >
                Category
              </CustomGrid>
              <Grid item xs={10}>
                <Select
                  IconComponent={(props) => (
                    <ExpandMoreIcon {...props} />
                  )}
                  variant="outlined"
                  labelId="issue-category"
                  id="issue-category"
                  value={category}
                  className={classes.selectBox}
                  disabled={true}
                  onChange={(event) => setCategory(event.target.value)}
                  aria-label="Category"
                >
                  <MenuItem value={"accessibility"}>Accessibility</MenuItem>
                  <MenuItem value={"security"}>Security</MenuItem>
                  <MenuItem value={"bugTracking"}>Bug Tracking</MenuItem>
                </Select>
              </Grid>
            </CustomGridContainer>

            {category === "accessibility" &&
              <>
                <CustomGridContainer container spacing={2}>
                  <CustomGrid item xs={12} sm={2} >
                    Standard
                  </CustomGrid>
                  <Grid item xs={10}>
                    <Select
                      IconComponent={(props) => (
                        <ExpandMoreIcon {...props} />
                      )}
                      disabled={loading}
                      variant="outlined"
                      labelId="issue-standard"
                      id="issue-standard"
                      value={issueFields.issueData.standard}
                      className={classes.selectBox}
                      onChange={(event) => {
                        if (event.target.value === "WCAG") {
                          setIssueFields({ ...issueFields, issueData: { ...issueFields.issueData, standard: event.target.value, standard_508: "", criteria: criteriaList[0] } })
                        } else {
                          setIssueFields({ ...issueFields, issueData: { ...issueFields.issueData, standard: event.target.value, criteria: "", standard_508: "pass" } })

                        }
                      }}
                      aria-label="Standard"
                    >
                      <MenuItem value={"WCAG"}>WCAG</MenuItem>
                      <MenuItem value={"Section 508"}>Section 508</MenuItem>
                    </Select>
                  </Grid>
                </CustomGridContainer>

                {issueFields.issueData.standard === "WCAG" && <CustomGridContainer container spacing={2}>
                  <CustomGrid item xs={12} sm={2} >
                    Criteria
                  </CustomGrid>
                  <Grid item xs={10}>
                    <Select
                      IconComponent={(props) => (
                        <ExpandMoreIcon {...props} />
                      )}
                      disabled={loading}
                      variant="outlined"
                      labelId="issue-criteria"
                      id="issue-criteria"
                      value={issueFields.issueData.criteria}
                      className={classes.selectBox}
                      onChange={(event) => setIssueFields({ ...issueFields, issueData: { ...issueFields.issueData, criteria: event.target.value } })}
                      aria-label="Criteria"
                    >
                      {criteriaList.map(criteria => {
                        return <MenuItem value={criteria}>{criteria}</MenuItem>
                      }
                      )}
                    </Select>
                  </Grid>
                </CustomGridContainer>}
                {issueFields.issueData.standard === "Section 508" &&
                  <CustomGridContainer container spacing={2}>
                    <CustomGrid item xs={12} sm={2} >
                      508 STANDARD
                    </CustomGrid>
                    <Grid item xs={10}>

                      <RadioGroup row aria-label="position" className={classes.radioButton}
                        onChange={(event) => setIssueFields({ ...issueFields, issueData: { ...issueFields.issueData, standard_508: event.target.value } })} name="position" value={issueFields.issueData.standard_508}>
                        <FormControlLabel
                          value="pass"
                          control={<Radio color="primary" className={classes.radioButton} />}
                          label="Pass"
                          labelPlacement="pass"
                        />
                        <FormControlLabel
                          value="fail"
                          control={<Radio color="primary" className={classes.radioButton} />}
                          label="Fail"
                          labelPlacement="fail"
                        />

                      </RadioGroup>
                    </Grid>
                  </CustomGridContainer>
                }
              </>}
            {category === "bugTracking" &&

              <CustomGridContainer container spacing={2}>
                <CustomGrid item xs={12} sm={2} >
                  Type Of Issue
                </CustomGrid>
                <Grid item xs={10}>
                  <Select
                    IconComponent={(props) => (
                      <ExpandMoreIcon {...props} />
                    )}
                    disabled={loading}
                    variant="outlined"
                    labelId="typeOfIssue"
                    id="typeOfIssue"
                    value={issueFields.issueData.typeOfIssue}
                    className={classes.selectBox}
                    onChange={(event) => setIssueFields({ ...issueFields, issueData: { ...issueFields.issueData, typeOfIssue: event.target.value } })}
                    aria-label="Type Of Issue"
                  >
                    <MenuItem value={"bug"}>Bug</MenuItem>
                    <MenuItem value={"vulnerability"}>Vulnerability</MenuItem>
                    <MenuItem value={"improvement"}>Improvement</MenuItem>
                  </Select>
                </Grid>
              </CustomGridContainer>
            }
            <CustomGridContainer container spacing={2}>
              <CustomGrid item xs={12} sm={2} >
                <span style={{ color: "red" }} >*</span> Description
              </CustomGrid>
              <Grid item xs={10}>
                <CKEditor
                  disabled={loading}
                  editor={ClassicEditor}
                  config={{
                    removePlugins: ['CKFinderUploadAdapter', 'CKFinder', 'EasyImage', 'Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed'],
                  }}

                  data={issueFields.issueData.description}
                  onReady={editor => {
                    editor.editing.view.change(writer => {
                      writer.setStyle(
                        "height",
                        "250px",
                        editor.editing.view.document.getRoot()
                      );
                    });
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setIssueFields({ ...issueFields, issueData: { ...issueFields.issueData, description: data } })
                  }}
                  aria-label="Description"
                />
              </Grid>
            </CustomGridContainer>

            <CustomGridContainer container spacing={2}>
              <CustomGrid item xs={12} sm={2} >
                <span style={{ color: "red" }} >*</span> Severity
              </CustomGrid>
              <Grid item xs={10}>
                <Select
                  IconComponent={(props) => (
                    <ExpandMoreIcon {...props} />
                  )}
                  disabled={loading}
                  variant="outlined"
                  labelId="severity"
                  id="severity"
                  value={issueFields.issueData.severity}
                  className={classes.selectBox}
                  onChange={(event) => setIssueFields({ ...issueFields, issueData: { ...issueFields.issueData, severity: event.target.value } })}
                  aria-label="Severity"
                >
                  <MenuItem value={"high"}>High</MenuItem>
                  <MenuItem value={"medium"}>Medium</MenuItem>
                  <MenuItem value={"low"}>Low</MenuItem>
                </Select>
              </Grid>
            </CustomGridContainer>

            <CustomGridContainer container spacing={2}>
              <CustomGrid item xs={12} sm={2} >
                <span style={{ color: "red" }} >*</span> Due Date
              </CustomGrid>
              <Grid item xs={10}>

                <input disabled={loading} className={classes.datePicker} onChange={(event) => {

                  setIssueFields({ ...issueFields, issueData: { ...issueFields.issueData, dueDate: event.target.value } })
                }} type="datetime-local" aria-label="Due Date" />
              </Grid>
            </CustomGridContainer>

            <CustomGridContainer container spacing={2}>
              <CustomGrid item xs={12} sm={2} >
                <span style={{ color: "red" }} >*</span> Reproduceable
              </CustomGrid>
              <Grid item xs={10}>
                <TextField
                  disabled={loading}
                  onChange={(event => setIssueFields({ ...issueFields, issueData: { ...issueFields.issueData, reproduceable: event.target.value } }))}
                  value={issueFields.issueData.reproduceable}
                  variant="outlined"
                  id="reproduceable-id"
                  placeholder="Your answer"
                  aria-label="Reproduceable"

                />
              </Grid>
            </CustomGridContainer>
            <CustomGridContainer container spacing={2}>
              <CustomGrid item xs={12} sm={2} >
                Attachments
              </CustomGrid>
              <Grid item xs={10}>
                <Box display="flex" justifyContent="center" alignItems="center" my={2} py={2} flexDirection="column" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.42)" }}>
                  <div {...getRootProps()} >
                    <input {...getInputProps()} disabled={loading} />
                    {!file.filename ?
                      <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" >
                        <CloudUploadOutlinedIcon style={{ fontSize: '30px', color: 'rgb(31, 58, 147)' }} />
                        <MuiTypography >Attach a File</MuiTypography>
                      </Box>
                      :
                      <div>{file.filename}</div>}
                  </div>
                </Box>
              </Grid>
            </CustomGridContainer>
            <div className={classes.buttonRow}>
              <Grid item xs={12}>
                <Button variant="contained" disabled={loading} style={{ margin: 15 }} type="submit" color="primary">
                  Submit Issue  {loading && <CircularProgress style={{ color: "black", marginLeft: "0.5rem" }} size={20} />}
                </Button>
              </Grid>
            </div>
          </form>}
        </Container>
      </>
    );
  }
}

const useStyles = makeStyles(addIssueStyles);

const CustomGrid = withStyles((theme) => ({
  root: {
    textAlign: "right",
    fontSize: "0.875rem",
    lineHeight: 2,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      textAlign: "left",
    },
  }

}))(Grid);

const CustomGridContainer = withStyles({
  root: {
    margin: 0
  }
})(Grid);
