import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { Box, Grid } from '@material-ui/core';
import CustomCardContainerHead from "../components/commons/CustomCardContainerHead";
import { Button, CircularProgress } from "@material-ui/core";
import Auth0Service from "../services/auth0Service/authentikService";
import { DomainsLink } from "../../src/store/actions/adminScansManagementActions"
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1),
    },
    domainName: {
        marginBottom: theme.spacing(1),
    },
    scanDetails: {
        marginTop: theme.spacing(2),
    },
}));


const DomainList = () => {

    function linkDomains() {
        // Auth0Service.getUserEmail()
        Auth0Service.getUserData()
            .then(userData => {
                console.log(userData); // This will log the user email or resolved data
                dispatch(DomainsLink(userData.email))
                    .then((data) => {
                        if (data) {
                            console.log(data.message);
                            toast.success("domains link successfully")
                        } else {
                            toast.error("No free domains found for the provided email");
                        }
                    })
                    .catch((err) => {
                        console.error('Error occurred:', err);
                    });
            })
            .catch(error => {
                console.error('Error occurred:', error);
            });
    }

    const dispatch = useDispatch();
    const classes = useStyles();
    const domains = useSelector((state) => state.dashboard.domains);
    console.log(domains)

    return (
        <>
            {domains.length > 0 ? (
                <>
                    <Typography variant='h5' style={{ marginBottom: 20, marginTop: 20, fontWeight: 'bold' }} >Your Domains</Typography>
                    <Grid container spacing={2}>
                        {domains.map((domain, index) => (
                            <Grid item xs={4}>
                                <Card key={index} className={classes.card}>
                                    <CardContent style={{ padding: 10 }}>
                                        <Typography variant="h6" className={classes.domainName} style={{ color: 'black' }} component='a' target='_blank' href={`/?domain=${domain.domain}&scanId=${domain.scans[domain.scans.length - 1].scanId}`}>
                                            {domain.domain}
                                        </Typography>
                                        {/* <div className={classes.scanDetails}>
                            {domain.scans.map((scan, scanIndex) => (
                                <Box mt={2}>
                                    <Typography key={scanIndex} variant="body2" style={{ color: 'black' }} component='a' target='_blank' href={`/?domain=${domain.domain}&scanId=${scan.scanId}`}>
                                        Date: {new Date(scan.dateNow).toLocaleString()}
                                        <br />
                                        Device: {scan.device}
                                        <br />
                                        Resolution: {scan.resolution}
                                    </Typography>
                                </Box>
                            ))}
                        </div> */}
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </>
            ) : (
                <>
                    {/* <Typography variant='h5' style={{ marginBottom: 20, marginTop: 20, fontWeight: 'bold' }} ></Typography> */}
                    <CustomCardContainerHead title="Relink Free Scans" titleTag="h1">
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 20, marginBottom: 20 }} >
                            <Typography variant="h4" style={{ fontSize: '24px' }}>
                                Click below button to relink domains to the organization
                            </Typography>
                        </div>
                        <Button
                            style={{ display: 'block', margin: 'auto', fontSize: '18px', backgroundColor: 'rgb(31, 58, 147)' }}
                            variant="contained"
                            color="primary"
                        onClick={() => linkDomains()}
                        aria-label="Relink Scans"
                        >
                            Relink Scans
                        </Button>
                    </CustomCardContainerHead>
                </>
            )}

        </>
    );
};

export default DomainList;
