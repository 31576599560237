import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CardContainer from "../components/commons/CardContainer";
import MaterialTable from "material-table";
import { getActivityLogs } from "../store/actions/activityLogsActions";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1F3A93",
    },
    secondary: {
      main: "#1F3A93",
    },
  },
});

export default function ActivityLogs() {

  const logs = useSelector((state) => state.activityLogs.logs);

  const userId = useSelector((state) => state.auth.user.data.id);

  const isDomainPurchased = useSelector((state) => state.dashboard.isDomainPackagePurchased);

  const [laoding, setLoading] = React.useState(false)
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (userId) {
      setLoading(true)

      dispatch(getActivityLogs(userId)).then(data => {
        if (data) {
          setLoading(false)
        } else {
          setLoading(false)

        }
      });
    }
    // eslint-disable-next-line
  }, [userId]);

  if (!isDomainPurchased) {
    return window.location.pathname = "/dashboard"
  } else {
    return (
      <>
        <ThemeProvider theme={theme}>
          <CardContainer>
            <div className="activity-logs-table">
              <MaterialTable
                isLoading={laoding}
                title="Activity Logs"
                columns={[
                  {
                    title: "Time",
                    field: "dateAdded",
                    cellStyle: {
                      width: 200,
                      maxWidth: 200,
                    },
                    headerStyle: {
                      width: 200,
                      maxWidth: 200,
                    },
                  },
                  { title: "Action", field: "action" },
                ]}
                data={logs}
                options={{
                  search: true,
                  paging: true,
                  sorting: false,
                  maxBodyHeight: "50vh",
                  headerStyle: {
                    color: "#1F3A93",
                  },
                }}
                aria-label="Activity Logs Table"
              />
            </div>
          </CardContainer>
        </ThemeProvider>
      </>
    );
  }
}
