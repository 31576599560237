import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer
} from 'recharts';

export default function MiniBarChart({ phases }) {
  const [dataset, setDataset] = React.useState([]);
  React.useEffect(() => {
    if (phases) {
      setDataset([
        {
          // name: 'timings',
          Blocking: phases.request,
          DNSLookUp: phases.dns,
          Connecting: phases.downlaod,
          Waiting: phases.wait,
          Total: phases.total,
        },
      ]);
    }
  }, [phases]);
  return (
    <div style={{ display: "flex", justifyContent: "center" }} role="img" aria-label="Bar chart showing various metrics">
      <ResponsiveContainer width={"100%"} height={70}>
        <BarChart
          layout='vertical'
          width={500}
          height={50}
          data={dataset}
        >
          <CartesianGrid strokeDasharray='3 3' />
  
          <XAxis
            type='number'
            unit='ms'
            ticks={[1, 5, 50, 100, 150, 200, 250, 500, 700, 800, 1000]}
            domain={[0, 1000]}
            aria-label="X-axis showing time in milliseconds"
          />
          <YAxis type='category' aria-label="Y-axis showing categories" />
          <Tooltip aria-label="Tooltip showing data details" />
          {/* <Legend /> */}
          <Bar dataKey='Blocking' stackId='a' fill='#E2211E' aria-label="Blocking time in red" />
          <Bar dataKey='DNSLookUp' stackId='a' fill='#57b0bf' aria-label="DNS Lookup time in blue" />
          <Bar dataKey='Connecting' stackId='a' fill='#b0dc59' aria-label="Connecting time in green" />
          <Bar dataKey='Waiting' stackId='a' fill='#218B34' aria-label="Waiting time in dark green" />
          <Bar dataKey='Total' stackId='a' fill='#FFA412' aria-label="Total time in orange" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};