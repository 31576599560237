import auth0Service from "../../services/auth0Service/authentikService";
import axios from "axios";
import { BASE_URL } from "../../source/constants";
import { toast } from "react-toastify";

export const GET_ACCESSIBILITY_CARD_DETAILS = "GET_ACCESSIBILITY_CARD_DETAILS";
export const LOADING_ACCESSIBILITY = "LOADING_ACCESSIBILITY";

export const GET_SECTION_508_CARD_DETAILS = "GET_SECTION_508_CARD_DETAILS";
export const LOADING_SECTION_508 = "LOADING_SECTION_508";

export const GET_SECTION_508_HISTORY = "GET_SECTION_508_HISTORY ";
export const LOADING_SECTION_508_HISTORY = "LOADING_SECTION_508_HISTORY";

export const GET_ACCESSIBILITY_HISTORY = "GET_ACCESSIBILITY_HISTORY";
export const LOADING_ACCESSIBILITY_HISTORY = "LOADING_ACCESSIBILITY_HISTORY";

export const getAccessibilityCardDetails = (scanId, tag, unified, selectedLink) => async (dispatch) => {
  dispatch({
    type: LOADING_ACCESSIBILITY,
  });
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
      tag,
      unified,
      link: selectedLink,
    };

    const res = await axios.get(
      `${BASE_URL}/dashboard/accessibility-priority-main-chart`,
      {
        params,
        ...options,
      }
    );

    if (res.data) {
      dispatch({
        type: GET_ACCESSIBILITY_CARD_DETAILS,
        payload: res.data,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
};

export const getAccessibilityHistory = (dateNowRange, scanId, tag) => async (
  dispatch
) => {
  dispatch({
    type: LOADING_ACCESSIBILITY_HISTORY,
  });
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      dateNowRange,
      scanId,
      tag,
    };

    const res = await axios.get(`${BASE_URL}/history/accessibility-history`, {
      params,
      ...options,
    });

    if (res.data) {
      dispatch({
        type: GET_ACCESSIBILITY_HISTORY,
        payload: res.data,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
};


export const getSection508CardDetails = (scanId) => async (dispatch) => {
  dispatch({
    type: LOADING_SECTION_508,
  });
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
    };

    const res = await axios.get(
      `${BASE_URL}/dashboard/accessibility-section508-main-chart`,
      {
        params,
        ...options,
      }
    );

    if (res.data) {
      dispatch({
        type: GET_SECTION_508_CARD_DETAILS,
        payload: res.data,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
};


export const getSection508History = (dateNowRange, scanId) => async (
  dispatch
) => {
  dispatch({
    type: LOADING_SECTION_508_HISTORY,
  });
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      dateNowRange,
      scanId,

    };

    const res = await axios.get(`${BASE_URL}/history/accessibility-history`, {
      params,
      ...options,
    });

    if (res.data) {
      dispatch({
        type: GET_SECTION_508_HISTORY,
        payload: res.data,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
};