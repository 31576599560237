import React from "react";
import CardContainer from "../components/commons/CardContainer";
import { useSelector, useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { getSitemapStage } from "../store/actions/customScanPageListActions";
import Auth0Service from "../services/auth0Service/authentikService";
import NoDomain from "../components/commons/NoDomain";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import StepConnector from "@material-ui/core/StepConnector";
import Typography from "@material-ui/core/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTasks } from "@fortawesome/free-solid-svg-icons";
import ProcessIcon from "../components/mixIcons/ProcessIcon";

import EvaluatingDomain from "./CustomScanComponents/EvaluatingDomain";
import SitemapOptions from "./CustomScanComponents/SitemapOptions";
import GeneratingSitemap from "./CustomScanComponents/GeneratingSitemap";
import ScanOptions from "./CustomScanComponents/ScanOptions";
import { setScanData } from "../store/actions/miscellaneousActions";
import loaderwave from "../assets/loader.svg";

export default function AdvanceScan() {
  const dispatch = useDispatch();

  const managerScanId = useSelector(
    (state) => state.miscellaneous.selectedScanManagerId
  );
  const selectedScanId = useSelector(
    (state) => state.miscellaneous.selectedScanId
  );
  const isDomainPurchased = useSelector(
    (state) => state.dashboard.isDomainPackagePurchased
  );

  const classes = useStyles();

  const [scanId, setScanId] = React.useState(null);

  function getSteps() {
    return [
      "Evaluating Domain",
      "Sitemap Options",
      "Generating Sitemap",
      "Scan Options",
    ];
  }

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 22,
    },
    active: {
      "& $line": {
        backgroundImage:
          "linear-gradient(90deg, rgba(39,35,105,1) 4%, rgba(45,83,155,1) 28%, rgba(33,68,147,1) 84%, rgba(67,68,85,0.9836309523809523) 100%)",
      },
    },
    completed: {
      "& $line": {
        backgroundImage:
          "linear-gradient(90deg, rgba(39,35,105,1) 4%, rgba(45,83,155,1) 28%, rgba(33,68,147,1) 84%, rgba(67,68,85,0.9836309523809523) 100%)",
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: "#eaeaf0",
      borderRadius: 1,
    },
  })(StepConnector);

  const useColorlibStepIconStyles = makeStyles({
    root: {
      backgroundColor: "#ccc",
      zIndex: 1,
      color: "#fff",
      width: 50,
      height: 50,
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
    },
    active: {
      backgroundColor: "#1f3a93",
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    },
    completed: {
      backgroundColor: "#1f3a93",
    },
  });

  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
      1: <ProcessIcon />,
      2: (
        <FontAwesomeIcon
          className="sidebarIcons"
          style={{ fontSize: "1.6em" }}
          icon={faTasks}
        />
      ),
      3: <ProcessIcon />,
      4: <GroupAddIcon />,
    };

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    );
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <EvaluatingDomain setActiveStep={setActiveStep} />;
      case 1:
        return <SitemapOptions setActiveStep={setActiveStep} />;
      case 2:
        return <GeneratingSitemap setActiveStep={setActiveStep} />;
      case 3:
        return <ScanOptions setActiveStep={setActiveStep} />;
      default:
        return "Unknown step";
    }
  }

  React.useEffect(() => {
    if (managerScanId && selectedScanId) {
      dispatch(getSitemapStage(managerScanId)).then((data) => {
        setActiveStep(data);
      });
      setScanId(selectedScanId);
    } else {
      if (managerScanId) {
        setScanId(managerScanId);
      }
    }
    // eslint-disable-next-line
  }, [managerScanId, selectedScanId]);

  React.useEffect(() => {
    const getScanManagerId = localStorage.getItem("scanManagerId");
    if (managerScanId && Auth0Service.isAuthenticated()) {
      dispatch(getSitemapStage(managerScanId)).then((data) => {
        setActiveStep(data);
      });
    } else if (getScanManagerId) {
      setScanId(getScanManagerId);
      dispatch(setScanData(null, null, null, getScanManagerId));
      dispatch(getSitemapStage(getScanManagerId)).then((data) => {
        setActiveStep(data);
      });
    }
    // eslint-disable-next-line
  }, [managerScanId]);

  const [delayed, setDelayed] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setDelayed(false);
    }, 1500);
  }, []);

  if (!isDomainPurchased) {
    return (window.location.pathname = "/dashboard");
  } else {
    return (
      <>
        {!delayed ? (
          <>
            {scanId ? (
              <>
                <br />
                <br />
                <CardContainer>
                  <Stepper
                    alternativeLabel
                    style={{ padding: 0, marginLeft: -5 }}
                    activeStep={activeStep}
                    connector={<ColorlibConnector />}
                  >
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}>
                          {label}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  <div>
                    {activeStep === steps.length ? (
                      <div>
                        <Typography className={classes.instructions}>
                          All steps completed - you &apos; re finished
                        </Typography>
                      </div>
                    ) : (
                      <div>
                        <Box p={3}>{getStepContent(activeStep)}</Box>
                      </div>
                    )}
                  </div>
                </CardContainer>
              </>
            ) : (
              <NoDomain text="Please add Domain " />
            )}
          </>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            tabIndex="0"
            role="status"
            aria-live="polite"
          >
            <img src={loaderwave} alt="loader" />
          </Box>
        )}
      </>
    );
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },

  fieldset: {
    width: "100%",
    maxWidth: "600px",
    margin: "20px 0px",
    borderRadius: 10,
  },
  customLinkInput: {
    width: "100%",
    maxWidth: "400px",
    margin: "10px 0px",
  },
  menuButton: {
    fontSize: 14,
    fontWeight: "bold",
    color: "white",
  },
  buttonIcon: {
    marginRight: theme.spacing(0),
  },
}));
