import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { setDomainLink } from '../../store/actions/dashboardActions';

const LiveSearchSelect = ({ options = [], onSearch, onSelect, searchValue, selectedValue }) => {
  const dispatch = useDispatch();
  const [filteredOptions, setFilteredOptions] = useState([]);

  const isSelectedDomainLink = useSelector(
    (state) => state.dashboard.selectedLink
  );

  // Set domain link if isSelectedDomainLink has a valid value
  useEffect(() => {
    if (isSelectedDomainLink !== "" && isSelectedDomainLink !== undefined) {
      dispatch(setDomainLink(isSelectedDomainLink));
    }
    console.log("Selected Link:", isSelectedDomainLink);
  }, [isSelectedDomainLink, dispatch]);

  // Format options for react-select
  useEffect(() => {
    const formattedOptions = options.map((option) => ({ value: option, label: option }));
    setFilteredOptions(formattedOptions);
  }, [options]);

  // Handle search term change
  const handleInputChange = (inputValue) => {
    onSearch(inputValue); // Send search term back to parent
  };

  // Handle option selection
  const handleChange = (selectedOption) => {
    onSelect(selectedOption ? selectedOption.value : ''); // Send selected option's value back to parent
    console.log('Selected Option: ', selectedOption);
    dispatch(setDomainLink(selectedOption ? selectedOption.value : '')); // Update selected value in the store
  };

  return (
    <Select
      placeholder="Search & Select Link..."
      aria-label="Search and select link"
      aria-placeholder="Search & Select Link..."
      aria-live="polite"
      value={filteredOptions.find((option) => option.value === selectedValue) || null} // Show selected option
      onInputChange={handleInputChange} // Handle search input
      onChange={handleChange} // Handle selection
      options={filteredOptions} // Display formatted options
      isClearable
      isSearchable
      noOptionsMessage={() => "No options available"}
      styles={{
        control: (provided) => ({
          ...provided,
          minWidth: '230px',
          marginBottom: '10px',
        }),
      }}
    />
  );
};

export default LiveSearchSelect;
