import {
  GET_DOMAIN_SUMMARY,
  GET_DOMAIN_SUMMARY_LOADING,
  SCREENSHOT,
  RESET_DOMAIN_SUMMARY,
  GET_DOMAINPAGES_SUMMARY,
  RESET_DOMAINPAGES_SUMMARY,
  ADD_DOMAIN,
  ADD_DOMAIN_LOADING,
  GET_DOMAINS,
  GET_DOMAINS_LOADING,
  GET_ACCESSIBILITY_TABLE_MAIN,
  RESET_ACCESSIBILITY_TABLE_MAIN,
  GET_ACCESSIBILITY_TABLE_ONE,
  GET_ACCESSIBILITY_TABLE_ONE_RESET,
  GET_ACCESSIBILITY_TABLE_TWO,
  GET_TOP_TEN_CRITERIA_TABLE,
  GET_TOP_TEN_CRITERIA_TABLE_RESET,
  GET_TOP_TEN_VOILATION_TABLE,
  GET_TOP_TEN_VOILATION_TABLE_RESET,
  GET_PAGES_BEING_ANALYZED,
  GET_PRIORITY_ACCESSIBILITY_GRAPH,
  PRIORITY_ACCESSIBILITY_BAR_GRAPH_LOADING,
  ADD_DOMAIN_SUCCESS,
  AFTER_ADD_DOMAIN,
  SET_DOMAIN,
  SET_DOMAIN_LINK,
  SET_SCAN,
  GET_DOMAINS_LINKS,
  OPEN_DOMAIN_MODAL,
  GET_DOMAIN_GRADES,
  RESET_GRADES,
  GET_HTML_CSS_TABLE,
  GET_HTML_CSS_TABLE_RESET,
  HTML_TABLE_LOADING,
  GET_HTML_TABLE,
  GET_HTML_TABLE_RESET,
  CSS_TABLE_LOADING,
  GET_CSS_TABLE,
  GET_CSS_TABLE_RESET,
  GET_NEW_HTML_TABLE,
  NEW_HTML_TABLE_LOADING,
  GET_NEW_CSS_TABLE,
  NEW_CSS_TABLE_LOADING,
  SET_CODE_SUMMARY,
  SET_SCAN_PROGRESS,
  CONFIG_LOADED,
  LOGOUT_MODAL,
  GET_TABS_INFO,
  SET_TABS_INFO_LOADING,
  SET_UNIFIED
} from "../actions/dashboardActions";

const initialState = {
  unified: false,
  domainSummary: {
    summary: {
      numberOfAccessibilityIssues: null,
      numberOfAccessibilityIssuesChange: null,
      numberOfAccessibilityIssuesPercentage: null,

      numberOfCyberSecurityIssues: null,
      numberOfCyberSecurityIssuesChange: null,
      numberOfCyberSecurityIssuesPercentage: null,

      numberOfFrontEndIssues: null,
      numberOfFrontEndIssuesChange: null,
      numberOfFrontEndIssuesPercentage: null,

      numberOfSpeedAndPerformanceIssues: null,
      numberOfSpeedAndPerformanceIssuesChange: null,
      numberOfSpeedAndPerformanceIssuesPercentage: null,

      numberOfGrammerAndSpellingIssues: null,
      numberOfGrammerAndSpellingIssuesChange: null,
      numberOfGrammerAndSpellingIssuesPercentage: null,

      numberOfFileOptimizationIssues: null,
      numberOfFileOptimizationIssuesChange: null,
      numberOfFileOptimizationIssuesPercentage: null,

      numberOfHtmlErrors: null,
      numberOfBrokenLinks: null,
      numberOfCssErrors: null,
      totalSites: null,
      allyScore: null,

      numberOfSeoErrors: null,
      seoGrade: null,
      seoGradeType: null,

      pageSpeedScoreChange: null,
      pageSpeedScorePerformanceChange: null,
      pageSpeedScoreGradeChange: null,
      pageLoadedTimeChange: null,
      numberOfBrokenLinksChange: null,
      numberOfHtmlErrorsChange: null,
      numberOfHtmlWarningsChange: null,
      numberOfCssErrorsChange: null,
      numberOfCssWarningsChange: null,
      numberOfJavascriptErrors: null,
      numberOfSpellingOrGrammarMistakesChange: null,
      numberOfWcagViolationsChange: null,
      totalSitesChange: null,

      pageSpeedScorePercentage: null,
      pageSpeedScorePerformancePercentage: null,
      pageSpeedScoreGradePercentage: null,
      pageLoadedTimePercentage: null,
      numberOfBrokenLinksPercentage: null,
      numberOfHtmlErrorsPercentage: null,
      numberOfHtmlWarningsPercentage: null,
      numberOfCssErrorsPercentage: null,
      numberOfCssWarningsPercentage: null,
      numberOfJsErrorsPercentage: null,
      numberOfSpellingOrGrammarMistakesPercentage: null,
      numberOfWcagViolationsPercentage: null,
      totalSitesPercentage: null,
    },
    pages: {
      numberOfAccessibilityPages: null,
      numberOfCyberSecurityPages: null,
      numberOfFrontEndPages: null,
      numberOfSpeedAndPerformancePages: null,
      numberOfGrammerAndSpellingPages: null,
      numberOfFileOptimizationPages: null,
      numberOfHtmlPages: null,
      numberOfBrokenLinkPages: null,
      numberOfCssPages: null,
      numberOfXssPages: null,
      numberOfSslPages: null,
      numberOfSqliPages: null,
      numberOfLfiPages: null,
      numberOfJavascriptPages: null,
      speedAndPerformancePages: null,
    },
    grades: {
      accessibilityGrade: null,
      accessibilityGradeType: null,
      cyberSecurityGrade: null,
      cyberSecurityGradeType: null,
      frontEndGrade: null,
      frontEndGradeType: null,
      speedAndPerformanceGrade: null,
      speedAndPerformanceGradeType: null,
      grammerAndSpellingGrade: null,
      grammerAndSpellingGradeType: null,
      fileOptimizationGrade: null,
      fileOptimizationGradeType: null,
      htmlGrade: null,
      htmlGradeType: null,
      brokenLinkGrade: null,
      brokenLinkGradeType: null,
      cssGrade: null,
      cssGradeType: null,
    },
    loading: true,
    status: null,
  },
  domainGrades: {
    scores: {
      accessibilityScore: null,
      accessibilityGrade: null,
      cyberSecurityScore: null,
      cyberSecurityGrade: null,
      pageSpeedScore: null,
      pageSpeedGrade: null,
      frontEndScore: null,
      frontEndGrade: null,
      grammerScore: null,
      grammerGrade: null,
      accessibilitySeverity: null,
      cyberSecuritySeverity: null,
      pageSpeedSeverity: null,
      frontEndSeverity: null,
      grammerSeverity: null,
    },
    loading: true,
    image: "",
    status: "pending",
  },
  domainPagesSummary: {
    pageSummary: {
      totalPages: null,
      uniquePages: null,
      pagesInThePlan: null,
      newTotalPages: null,
      newUniquePages: null,
      newTotalPagesChange: null,
      newTotalPagesContrast: null,
      newUniquePagesChange: null,
      newUniquePagesContrast: null,
      pagesInThePlanChange: null,
      pagesInThePlanContrast: null,
      totalPagesChange: null,
      totalPagesContrast: null,
      uniquePagesChange: null,
      uniquePagesContrast: null,
    },
    loading: true,
    status: null,
  },
  tabsInfo: {
    accessibility: {
      all: true,
      top10Criteria: true,
      top10Vilations: true,
      section508: true,
      others: true
    },
    cyber: {
      sql: true,
      xss: true,
      lfi: true,
      ssl: true,
      exploit: true,
      breached: true,
      others: true
    },
    performance: {
      html: true,
      css: true,
      js: true,
      files: true,
      pdf: true,
      seo: true,
      pagemetrics: true,
      others: true
    }
  },
  tabsInfoLoading: false,
  screenshot: null,
  screenshot_status: "pending",
  logout_modal: false,
  pagesBeingAnalyzed: null,
  pagesBeingAnalyzedLoading: false,
  pagesBeingAnalyzedStatus: null,

  accessibilityTableMain: null,
  accessibilityTableMainStatus: "pending",

  accessibilityTableOne: null,
  accessibilityTableOnePages: null,
  accessibilityTableOneCount: null,
  accessibilityTableOneStatus: "pending",

  accessibilityTableTwo: null,
  accessibilityTableTwoStatus: "pending",
  priorityAccessibilityBarGraph: {
    graph: null,
    loading: false,
    status: "pending",
  },
  topTenCriteriaTable: null,
  topTenCriteriaTableStatus: "pending",
  topTenVoilationTable: null,
  topTenVoilationTableStatus: "pending",

  htmlCssTable: null,
  htmlCssTableLoading: false,
  htmlCssTableStatus: "pending",

  htmlTable: null,
  htmlTableLoading: false,
  htmlTableStatus: "pending",

  cssTable: null,
  cssTableLoading: false,
  cssTableStatus: "pending",

  newHtmlTable: null,
  newHtmlTableStatus: "pending",
  newHtmlTableLoading: false,

  addDomainLoading: false,
  addDomainSuccess: "",
  domains: [],
  domainsLoading: false,

  error: null,
  selectedDomain: "",
  selectedDomainId: "",
  selectedDomainScanId: "",
  isDomainPackagePurchased: false,
  selectedLink: "",
  domainPackage: null,
  links: null,
  openModal: false,
  codeSummary: "",

  scanProgress: [],
  scanProgressStatus: "pending",
  authConfig: null,
  isConfigLoaded: false,
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_UNIFIED:
      return {
        ...state,
        unified: action.payload,
      };
    case GET_DOMAINS:
      return {
        ...state,
        domains: action.payload,
      };
    case GET_DOMAINS_LOADING:
      return {
        ...state,
        domainsLoading: !state.domainsLoading
      }
    case GET_DOMAINS_LINKS:
      return {
        ...state,
        links: action.payload,
      };
    case SET_DOMAIN:
      return {
        ...state,
        selectedDomain: action.payload.selectedDomain,
        selectedDomainId: action.payload.selectedDomainId,
        isDomainPackagePurchased: action.payload.isPackagePurchased,
        domainPackage: action.payload.selectedPackage,
        selectedLink: action.payload.selectedLink,

      };
    case SET_DOMAIN_LINK:
       return {
          ...state,
          selectedLink: action.payload.selectedLink,
  
        };
    case SET_SCAN:
      return {
        ...state,
        selectedDomainScanId: action.payload,
      };
    case GET_DOMAIN_SUMMARY:
      return {
        ...state,
        domainSummary: {
          ...state.domainSummary,
          summary: action.payload.result.summary,
          pages: action.payload.result.pages,
          grades: action.payload.result.grades,
          status: action.payload.status,
          loading: false,
        },
      };
    case GET_DOMAIN_SUMMARY_LOADING:
      return {
        ...state,
        domainSummary: { ...state.domainSummary, loading: true },
      };
    case GET_DOMAINPAGES_SUMMARY:
      return {
        ...state,
        domainPagesSummary: {
          ...state.domainPagesSummary,
          pageSummary: action.payload.result.pageSummary,
          status: action.payload.status,
          loading: false,
        },
      };
    case RESET_DOMAINPAGES_SUMMARY:
      return {
        ...state,
        domainPagesSummary: { ...initialState.domainPagesSummary },
      };

    case SCREENSHOT:
      return {
        ...state,
        screenshot: action.payload.result.image,
        screenshot_status: action.payload.status,
      };
    case LOGOUT_MODAL:
      return {
        ...state,
        logout_modal: true,
      };
    case RESET_DOMAIN_SUMMARY:
      return {
        ...state,
        domainSummary: { ...initialState.domainSummary },
      };

    case GET_PAGES_BEING_ANALYZED:
      return {
        ...state,
        pagesBeingAnalyzed: action.payload.result.count,
        pagesBeingAnalyzedLoading: false,
        pagesBeingAnalyzedStatus: action.payload.status,
      };

    case GET_DOMAIN_GRADES:
      return {
        ...state,
        domainGrades: {
          scores: action.payload.scores,
          image: action.payload.image,
          status: action.payload.status,
          loading: false,
        },
      };
    case RESET_GRADES:
      return {
        ...state,
        domainGrades: { ...initialState.domainGrades },
      };
    case RESET_ACCESSIBILITY_TABLE_MAIN:
      return {
        ...state,
        accessibilityTableMain: null,
        accessibilityTableMainStatus: {
          ...initialState.accessibilityTableMainStatus,
        },
      };
    case GET_ACCESSIBILITY_TABLE_MAIN:
      return {
        ...state,
        accessibilityTableMain: action.payload.data,
        accessibilityTableMainStatus: action.payload.status,
      };

    case GET_ACCESSIBILITY_TABLE_ONE:
      return {
        ...state,
        accessibilityTableOne: action.payload.result,
        accessibilityTableOnePages: action.payload.pages,
        accessibilityTableOneCount: action.payload.count,
        accessibilityTableOneStatus: action.payload.status,
      };
    case GET_ACCESSIBILITY_TABLE_ONE_RESET:
      return {
        ...state,
        accessibilityTableOne: { ...initialState.accessibilityTableOne },
        accessibilityTableOneStatus: "pending",
      };
    case GET_ACCESSIBILITY_TABLE_TWO:
      return {
        ...state,
        accessibilityTableTwo: action.payload,
      };
    case GET_TOP_TEN_CRITERIA_TABLE:
      return {
        ...state,
        topTenCriteriaTable: action.payload.data,
        topTenCriteriaTableStatus: action.payload.status,
      };
    case GET_TOP_TEN_CRITERIA_TABLE_RESET:
      return {
        ...state,
        topTenCriteriaTable: { ...initialState.topTenCriteriaTable },
        topTenCriteriaTableStatus: "pending",
      };
    case GET_TOP_TEN_VOILATION_TABLE:
      return {
        ...state,
        topTenVoilationTable: action.payload.data.summaries,
        topTenVoilationTableStatus: action.payload.status,
      };
    case GET_TOP_TEN_VOILATION_TABLE_RESET:
      return {
        ...state,
        topTenVoilationTable: { ...initialState.topTenVoilationTable },
        topTenVoilationTableStatus: "pending",
      };
    case GET_HTML_TABLE:
      return {
        ...state,
        htmlTable: action.payload.data,
        htmlTableStatus: action.payload.status,
        htmlTableLoading: false,

      };
    case GET_HTML_TABLE_RESET:
      return {
        ...state,
        htmlTable: { ...initialState.htmlTable },
        htmlTableStatus: "pending",
      };
    case HTML_TABLE_LOADING:
      return {
        ...state,
        htmlTableLoading: !state.htmlTableLoading,
      };

    case GET_NEW_HTML_TABLE:
      return {
        ...state,

        newHtmlTable: action.payload.result ? action.payload.result.issues : null,
        newHtmlTableStatus: action.payload.status ? action.payload.status : "pending",
      };
    case NEW_HTML_TABLE_LOADING:
      return {
        ...state,
        newHtmlTableLoading: !state.newHtmlTableLoading,
      };

    case GET_NEW_CSS_TABLE:
      return {
        ...state,

        newCssTable: action.payload.result ? action.payload.result.issues : null,
        newCssTableStatus: action.payload.status ? action.payload.status : "pending",
      };
    case NEW_CSS_TABLE_LOADING:
      return {
        ...state,
        newCssTableLoading: !state.newCssTableLoading,
      };

    case GET_HTML_CSS_TABLE:
      return {
        ...state,
        htmlCssTable: action.payload.data,
        htmlCssTableStatus: action.payload.status,
      };
    case GET_HTML_CSS_TABLE_RESET:
      return {
        ...state,
        htmlCssTable: { ...initialState.htmlCssTable },
        htmlCssTableStatus: "pending",
      };
    case GET_CSS_TABLE:
      return {
        ...state,
        cssTable: action.payload.data,
        cssTableStatus: action.payload.status,
        cssTableLoading: false,

      };
    case GET_CSS_TABLE_RESET:
      return {
        ...state,
        cssTable: { ...initialState.cssTable },
        cssTableStatus: "pending",
      };
    case CSS_TABLE_LOADING:
      return {
        ...state,
        cssTableLoading: !state.cssTableLoading,
      };
    case GET_PRIORITY_ACCESSIBILITY_GRAPH:
      return {
        ...state,
        priorityAccessibilityBarGraph: {
          ...state.priorityAccessibilityBarGraph,
          graph: action.payload.data,
          status: action.payload.status,
        },
      };
    case PRIORITY_ACCESSIBILITY_BAR_GRAPH_LOADING:
      return {
        ...state,
        priorityAccessibilityBarGraph: {
          ...state.priorityAccessibilityBarGraph,
          loading: !state.priorityAccessibilityBarGraph.loading,
        },
      };
    case ADD_DOMAIN_LOADING:
      return {
        ...state,
        addDomainLoading: !state.addDomainLoading,
      };

    case ADD_DOMAIN_SUCCESS:
      return {
        ...state,
        addDomainSuccess: action.payload,
      };
    case AFTER_ADD_DOMAIN:
      return {
        ...state,
        addDomainSuccess: null,
      };
    case OPEN_DOMAIN_MODAL:
      return {
        ...state,
        openModal: !state.openModal,
      };
    case SET_CODE_SUMMARY:
      return {
        ...state,
        codeSummary: action.payload,
      };
    case SET_SCAN_PROGRESS:
      return {
        ...state,
        scanProgress: action.payload.progress,
      };
    case CONFIG_LOADED:
      return {
        ...state,
        isConfigLoaded: true,
      };
    case GET_TABS_INFO:
      return {
        ...state,
        tabsInfo: action.payload,
        tabsInfoLoading: false
      };
    case SET_TABS_INFO_LOADING:
      return {
        ...state,
        tabsInfoLoading: !state.tabsInfoLoading,
      };
    case ADD_DOMAIN:
    default:
      return state;
  }
};
export default dashboardReducer;