import React from "react";
import motor from "../../../../assets/disabilityicons/motor.svg";
import Box from "@material-ui/core/Box";

export default function Motor() {
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      mr={1.5}
      style={{ fontSize: 13, fontWeight: 500, marginRight: 9, width: 60 }}
      role="group"
      aria-labelledby="motor-title"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{ border: "2px solid black", borderRadius: "50%", padding: 6 }}
        role="img"
        aria-label="Motor Disability Icon"
      >
        <img src={motor} height="22px" width="22px" alt="Motor Disability" />
      </Box>
      <span
        id="motor-title"
        style={{ fontSize: "17.5px", fontWeight: 500 }}
      >
        Motor
      </span>
    </Box>
  );
} 
