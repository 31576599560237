import React from "react";
import Button from "@material-ui/core/Button";
import mastercard from "../assets/creditcards/master.png";
import visa from "../assets/creditcards/visa.jpg";
import american from "../assets/creditcards/american.jpg";
import jcb from "../assets/creditcards/jcb.png";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import financialStyles from "../Styles/jss/financialStyles";
import { makeStyles } from "@material-ui/core/styles";
// import AddPayment from '../../AddPayment';
import CreditCardIcon from "@material-ui/icons/CreditCard";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ElementsConsumer } from "@stripe/react-stripe-js";
import MuiCard from "@material-ui/core/Card";
import CheckoutForm from "./Payments/StripeMethod";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import { getCards } from "../store/actions/changePackageActions";
import { defaultCard, deleteStripeCard } from "../store/actions/packageSelectionActions";
import CustomCardContainerHead from "../components/commons/CustomCardContainerHead";

const getConfig = localStorage.getItem("authConfig");
const configValue = JSON.parse(getConfig);

const STRIPE_KEY = configValue;

const stripePromise = STRIPE_KEY
  ? loadStripe(STRIPE_KEY.value.stripe_key)
  : loadStripe("");
const useStyles = makeStyles(financialStyles);

export default function PaymentMethods() {

  const dispatch = useDispatch();
  const classes = useStyles();

  const cards = useSelector(state => state.changePackage.stripeCards);
  const domainId = useSelector((state) => state.dashboard.selectedDomainId);


  const [addMore, setAddMore] = React.useState(false);

  React.useEffect(() => {
    dispatch(getCards())
  }, []);

  function handleMethodChecked(item) {
    dispatch(defaultCard({
      "domainId": domainId,
      "cardId": item.id,
      "org": true,
      "domain": true
    }))
  }

  function deleteCard(cardId) {
    dispatch(deleteStripeCard(cardId))
  }
  return (
    <div>
      {cards && cards.length === 0 ? (
        <div>
          <CustomCardContainerHead title="Add Card" titleTag="h1">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 20,
                marginBottom: 20,
              }}
            >
              <Typography variant="h4" style={{ fontSize: "24px" }}>
                To add payment method please click the below button
              </Typography>
            </div>
            <Button
              color="primary"
              variant="contained"
              style={{
                display: "block",
                margin: "auto",
                fontSize: "18px",
                marginTop: "30px",
                backgroundColor: "rgb(31, 58, 147)",
              }}
              onClick={() => (window.location.pathname = "account-setup")}
              aria-label="Add Card"
            >
              Add Card
            </Button>
          </CustomCardContainerHead>
        </div>
      ) : (
        <div>
          {cards && cards.length > 0 && !addMore && (
            <Box my={4}>
              <TableContainer>
                <Table aria-label="simple table" style={{ tableLayout: "fixed" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: 60 }}></TableCell>
                      <TableCell
                        style={{ width: 230 }}
                        className={classes.tableHead}
                      >
                        Card/Bank Account No.
                      </TableCell>
                      <TableCell className={classes.tableHead} style={{ width: 80 }}>
                        Expiry
                      </TableCell>
                      <TableCell style={{ width: 60 }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cards.map((item, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            <Button
                              role="button"
                              label="delete card"
                              color="secondary"
                              variant="contained"
                              onClick={() => {
                                deleteCard(item.id);
                              }}
                              aria-label="Delete Card"
                            >
                              Delete
                            </Button>
                          </TableCell>
  
                          <TableCell component="th" scope="row">
                            <Box
                              display="flex"
                              alignItems="center"
                              style={{ width: 230 }}
                            >
                              {item.brand === "Visa" && (
                                <img width="44" height="25" src={visa} alt="visa" />
                              )}
                              {item.brand === "Mastercard" && (
                                <img
                                  width="44"
                                  height="25"
                                  src={mastercard}
                                  alt="mastercard"
                                />
                              )}
                              {item.brand === "Amex" && (
                                <img
                                  width="44"
                                  height="25"
                                  src={american}
                                  alt="American Express"
                                />
                              )}
                              {item.brand === "Jcb" && (
                                <img width="44" height="25" src={jcb} alt="JCB" />
                              )}
                              <span
                                style={{ marginLeft: "0.7rem", fontSize: "1em" }}
                              >
                                {`xxxx-xxxx xxxx ${item.last4}`}{" "}
                              </span>{" "}
                            </Box>
                          </TableCell>
                          <TableCell>{`${item.exp_month}/${item.exp_year}`}</TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            <label className="containerCheckbox">
                              <input
                                type="radio"
                                className="radios"
                                name="radio"
                                defaultChecked={index === 0}
                                onChange={() => handleMethodChecked(item)}
                                aria-label="Select Card"
                              />
                              <span className="checkmark"></span>
                            </label>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button
                color="primary"
                variant="contained"
                style={{ margin: "0 auto", display: "block", marginTop: 20 }}
                onClick={() => setAddMore(true)}
                aria-label="Add Another Card"
              >
                Add Another Card
              </Button>
            </Box>
          )}
          {addMore && <AddPayment domainId={domainId} setAddMore={setAddMore} />}
        </div>
      )}
    </div>
  );
}

const AddPayment = ({ setAddMore, domainId }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      // marginTop: "10rem",
      [theme.breakpoints.down("sm")]: {
        marginTop: "7rem",
      },
    },
    marginZero: {
      margin: "0px",
    },
  }));
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const InjectedCheckoutForm = () => {
    return (
      <ElementsConsumer>
        {({ elements, stripe }) => (
          <CheckoutForm
            elements={elements}
            stripe={stripe}
            domainId={domainId}
          />
        )}
      </ElementsConsumer>
    );
  };

  const PaymentForm = () => {
    return (
      <div>
        <Elements stripe={stripePromise}>
          <InjectedCheckoutForm />
        </Elements>
      </div>
    );
  };

  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          my={3}
          alignItems="center"
        >
          <Typography variant="h4" className={classes.mainHeading}>
            <small>Add Payment Method</small>
          </Typography>
          <Typography variant="subtitle2">
            <small style={{ color: "#505050" }}>
              Enter Your Your Card Details Here
            </small>
          </Typography>
        </Box>
        <Grid spacing={3} container>
          <Grid item xs={12} md={7} style={{ margin: "0 auto" }}>
            <MuiCard style={{ boxShadow: "0px 3px 25px rgba(0 0 0 / 15%)" }}>
              <CustomTabs
                value={value}
                onChange={handleChange}
                centered
                scrollButtons="on"
              >
                <CustomTab
                  label={
                    <Box
                      display="flex"
                      justifyContent="center"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <CreditCardIcon style={{ fontSize: "2rem" }} />{" "}
                      <span>Credit/Debit Card</span>
                    </Box>
                  }
                />
              </CustomTabs>
              <PaymentForm />
            </MuiCard>
            <Button
              color="primary"
              variant="contained"
              style={{ marginTop: 20 }}
              onClick={() => setAddMore(false)}
            >
              back
            </Button>
          </Grid>
        </Grid>

      </div>
    </>
  );
};

const CustomTabs = withStyles({
  root: {
    borderBottom: "none",
  },
  indicator: {
    backgroundColor: "#1f3a93",
  },
})(Tabs);

const CustomTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    maxWidth: "100%",
    width: 210,
    opacity: 1,
    color: "black",
    "&:hover": {
      color: "#1f3a93",
      opacity: 1,
    },
    "&$selected": {
      color: "#1f3a93",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#1f3a93",
    },
  },
  selected: {
    color: "#1f3a93",
  },
}))((props) => <Tab disableRipple {...props} />);
