import React from "react";
import { useSelector } from "react-redux";
import Security from "./SecurityCurrentAndHistory";
import ScanSelector from "../commons/ScanSelector";

import Box from "@material-ui/core/Box";
import SecurityReport from "../../pages/SecurityReport";
import ModuleSummary from "../commons/ModuleSummary";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Button, Card, CircularProgress } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { getUserReports } from "../../store/actions/dashboardActions";
import { Pagination } from "@material-ui/lab";
import DatePicker from "../commons/DateRangePicker";
import DatePickerSimple from "../commons/DateRangePickerSimple";
import IssueTrackerTableSecurity from "../commons/IssueTrackerTableSecurity";
import Auth0Service from "../../services/auth0Service/authentikService";

export default function SecurityDashboard() {
  const dispatch = useDispatch();
  const selectedScanId = useSelector(
    (state) => state.miscellaneous.selectedScanId
  );
  const isSelectedDomainLink = useSelector(
    (state) => state.dashboard.selectedLink
  );

  const domainId = useSelector((state) => state.dashboard.selectedDomainId);
  const securityTabs = useSelector((state) => state.dashboard.tabsInfo?.cyber);
  const tabsLoading = useSelector((state) => state.dashboard.tabsInfoLoading);
  const [reps, setReps] = React.useState([])

  const [reportType, setReportType] = React.useState({
    report: false,
    analytics: true,
    reports: false,
    spreadSheet: false
  });

  const [values, setValues] = React.useState({
    dateNowRange: '',
    domainId
  })
  React.useEffect(() => {
    if (domainId) {
      dispatch(getUserReports(values)).then(data => {
        setReps(data.result);
      }).catch(err => {
      })
    }
  }, [domainId])


  const CustomTab = withStyles((theme) => ({
    root: {
      minWidth: 40,
      opacity: 1,
      minHeight: "auto",
      borderRadius: "2px",
      padding: "0px 8px",
      border: "1px solid #1f3a93",
      color: "#1f3a93",
      fontWeight: 400,
      fontSize: "18px",
      maxWidth: '100%',
      borderRight: 0,
      "&:last-child": {
        borderRight: "1px solid #1f3a93",

      },
      "&:hover": {
        color: "#fff!important",
        backgroundColor: "#1f3a93",
        opacity: 1,
      },
      "&$selected": {
        color: "white",
        fontWeight: theme.typography.fontWeightMedium,
        backgroundColor: "#1f3a93",
      },
      "&:focus": {
        color: "white",
        backgroundColor: "#1f3a93",
      },
    },
    selected: {
      color: "white",
      backgroundColor: "#1f3a93",
      // display: 'none'
    },
  }))((props) => <Tab disableRipple {...props} />);

  return (
    <>
      <ScanSelector aria-label="Scan Selector"/>
      {/* <ModuleSummary module="security" title="Overall Cyber Security Score" /> */}
      <Box display="flex" flexWrap="wrap" alignItems="center" mb={2} role="tablist" aria-label="Report Type Tabs">

        {reps.length > 0 ? (
          < CustomTab
            label="Reports"
            onClick={() => setReportType({ reports: true })}
            role="tab"
            aria-selected={reportType.reports}
            tabIndex={reportType.reports ? "0" : "-1"}
            style={{
              backgroundColor: reportType.reports ? "#1f3a93" : "none",
              color: reportType.reports ? "#ffff" : "#1f3a93",
            }}
          />
        ) : (null)
        }

        <CustomTab
          label="Analytics"
          onClick={() => setReportType({ analytics: true })}
          role="tab"
          aria-selected={reportType.analytics}
          tabIndex={reportType.analytics ? "0" : "-1"}
          style={{
            backgroundColor: reportType.analytics ? "#1f3a93" : "none",
            color: reportType.analytics ? "#ffff" : "#1f3a93",
          }}
        />
        {Auth0Service.isAuthenticated() && <>
          <CustomTab
            label="Security Sheet"
            onClick={() => setReportType({ spreadSheet: true })}
            role="tab"
            aria-selected={reportType.spreadSheet}
            tabIndex={reportType.spreadSheet ? "0" : "-1"}
            style={{
              backgroundColor: reportType.spreadSheet ? "#1f3a93" : "none",
              color: reportType.spreadSheet ? "#ffff" : "#1f3a93",
            }}
          />
          <CustomTab
            label={`SQLI Sheet`}
            onClick={() => setReportType({ sqli: true })}
            role="tab"
            aria-selected={reportType.sqli}
            tabIndex={reportType.sqli ? "0" : "-1"}
            style={{
              backgroundColor: reportType.sqli ? "#1f3a93" : "none",
              color: reportType.sqli ? "#ffff" : "#1f3a93",
            }}
          />
          <CustomTab
            label="XSS Sheet"
            onClick={() => setReportType({ xss: true })}
            role="tab"
            aria-selected={reportType.xss}
            tabIndex={reportType.xss ? "0" : "-1"}
            style={{
              backgroundColor: reportType.xss ? "#1f3a93" : "none",
              color: reportType.xss ? "#ffff" : "#1f3a93",
            }}
          />
          <CustomTab
            label="LFI Sheet"
            onClick={() => setReportType({ lfi: true })}
            role="tab"
            aria-selected={reportType.lfi}
            tabIndex={reportType.lfi ? "0" : "-1"}
            style={{
              backgroundColor: reportType.lfi ? "#1f3a93" : "none",
              color: reportType.lfi ? "#ffff" : "#1f3a93",
            }}
          />
          <CustomTab
            label="Subdomain Takeover Sheet"
            onClick={() => setReportType({ subdomainTakeover: true })}
            role="tab"
            aria-selected={reportType.subdomainTakeover}
            tabIndex={reportType.subdomainTakeover ? "0" : "-1"}
            style={{
              backgroundColor: reportType.subdomainTakeover ? "#1f3a93" : "none",
              color: reportType.subdomainTakeover ? "#ffff" : "#1f3a93",
            }}
          />
          <CustomTab
            label="Global Sheet"
            onClick={() => setReportType({ global: true })}
            role="tab"
            aria-selected={reportType.global}
            tabIndex={reportType.global ? "0" : "-1"}
            style={{
              backgroundColor: reportType.global ? "#1f3a93" : "none",
              color: reportType.global ? "#ffff" : "#1f3a93",
            }}
          />
        </>}

        {tabsLoading ? <CircularProgress style={{ marginLeft: 10 }} size={20} role="status" aria-label="Loading tabs, please wait"/> : <>

          {Object.values(securityTabs).some(item => item === true) && <CustomTab
            label="Data tables"
            onClick={() => setReportType({ report: true })}
            role="tab"
            aria-selected={reportType.report}
            tabIndex={reportType.report ? "0" : "-1"}
            style={{
              backgroundColor: reportType.report ? "#1f3a93" : "none",
              color: reportType.report ? "#ffff" : "#1f3a93",
            }}
          />}
        </>}
      </Box>
      {reportType.analytics && (
        <Security scanId={selectedScanId} overTime={true} />
      )}
      {reportType.report && <SecurityReport />}
      {reportType.reports && <Reports />}
      {reportType.spreadSheet && <IssueTrackerTableSecurity title="CyberSec Issue Sheet" vulnType='security' domainId={domainId} />}
      {reportType.sqli && <IssueTrackerTableSecurity title="SQLi Issue Sheet" vulnType='security-sqli' domainId={domainId} />}
      {reportType.xss && <IssueTrackerTableSecurity title="XSS Issue Sheet" vulnType='security-xss' domainId={domainId} />}
      {reportType.lfi && <IssueTrackerTableSecurity title="LFI Issue Sheet" vulnType='security-lif' domainId={domainId} />}
      {reportType.subdomainTakeover && <IssueTrackerTableSecurity title="Subdomain Takeover Issue Sheet" vulnType='security-subdomain-takeover' domainId={domainId} />}
      {reportType.global && <IssueTrackerTableSecurity title="Global Issue Sheet" vulnType='global' domainId={domainId} />}

    </>
  );
}



function Reports() {
  const dispatch = useDispatch();
  const domainId = useSelector((state) => state.dashboard.selectedDomainId);

  const [reps, setReps] = React.useState([])
  const [page, setPage] = React.useState(1)
  const [pages, setPages] = React.useState(0)
  const [loading, setLoading] = React.useState(false)

  const [dateRange, setDateRange] = React.useState(false)


  const limit = 5;
  const [values, setValues] = React.useState({
    dateNowRange: '',
    page: page,
    limit,
    domainId
  })

  React.useEffect(() => {
    if (domainId) {
      setLoading(true)
      dispatch(getUserReports(values)).then(data => {
        setReps(data.result);
        setPages(data.pages)
        setLoading(false)

      }).catch(err => {
        setLoading(false)
      })
    }
  }, [domainId, page])

  const handleChange = (event, value) => {
    setPage(value);
  }

  function applyDateRange() {
    setLoading(true)
    dispatch(getUserReports({ ...values, dateNowRange: dateRange })).then(data => {
      setReps(data.result);
      setPages(data.pages)
      setLoading(false)

    }).catch(err => {
      setLoading(false)
    })
  }

  function resetDateRange() {
    setLoading(true)
    dispatch(getUserReports({ ...values, dateNowRange: dateRange })).then(data => {
      setReps(data.result);
      setPages(data.pages)
      setLoading(false)

    }).catch(err => {
      setLoading(false)
    })
  }

  return (
    <Box aria-live="polite">
      <Box display="flex" alignItems="center" justifyContent="flex-end" mb={3} role="toolbar" aria-label="Date Range Controls">
        <DatePickerSimple
          setDateRange={setDateRange}
          aria-label="Select date range"
        />
        <Button
          style={{ marginLeft: 10 }}
          variant="contained"
          disabled={!dateRange}
          color="primary"
          onClick={applyDateRange}
          aria-label="Apply selected date range"
        >
          Apply
        </Button>
        <Button
          style={{ marginLeft: 10 }}
          variant="contained"
          disabled={!dateRange}
          color="secondary"
          onClick={resetDateRange}
          aria-label="Reset selected date range"
        >
          Reset
        </Button>
      </Box>
  
      {loading ? (
        <Box display="flex" alignItems="center" justifyContent="center" width="100%" height={300}>
          <CircularProgress role="status" aria-label="Loading reports, please wait" />
        </Box>
      ) : (
        reps.length > 0 ? (
          reps.map((data, index) => (
            <Card key={index} role="region" aria-labelledby={`report-title-${index}`} aria-describedby={`report-desc-${index}`}>
              <Box p={3}>
                <Box display="flex" flexWrap="wrap">
                  <Box mr={2} style={{ fontSize: 18 }}>
                    <b id={`report-title-${index}`}>Title:</b> {data.title}
                  </Box>
                  <Box mr={2} style={{ fontSize: 18 }}>
                    <b id={`report-desc-${index}`}>Description:</b> {data.description}
                  </Box>
                  <Box mr={2} style={{ fontSize: 18 }}>
                    <b>Date:</b> {new Date(data.date).toDateString()}
                  </Box>
                  {!data.reportUrl.includes("html") && (
                    <Box mr={2}>
                      <b>File:</b> <a href={data.reportUrl} target="_blank" rel="noopener noreferrer">View</a>
                    </Box>
                  )}
                </Box>
                {data.reportUrl.includes("html") && (
                  <iframe
                    src={data.reportUrl}
                    width="100%"
                    height={350}
                    title={`Report for ${data.title}`}
                    aria-label={`Embedded report content for ${data.title}`}
                  />
                )}
              </Box>
            </Card>
          ))
        ) : (
          <Box textAlign="center" role="status">No reports found</Box>
        )
      )}
  
      <Box p={3} display="flex" justifyContent="center">
        <Pagination
          color="primary"
          count={pages}
          page={page}
          onChange={handleChange}
          aria-label="Pagination for report list"
        />
      </Box>
    </Box>
  );
}