import React from "react";
import Box from "@material-ui/core/Box";
import SnackBarAlert from "./SnackBarAlert";
import LogoutModal from "../dialogs/LogoutModal";

export default function OnBoardingWrapper({ children }) {
  const [logout, setLogout] = React.useState(false);

  React.useEffect(() => {
    const interval = setInterval(() => {
      const expiresAt = JSON.parse(localStorage.getItem("expires_at"));
      if (!logout && expiresAt) {
        const isExpired = new Date().getTime() > expiresAt;
        if (isExpired) {
          // window.history.replaceState(null, null, `#autologout=1`);
          setLogout(true);
        }
      }
    }, 5000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  });

  return (
    <Box style={{ maxWidth: "1200px", margin: "0 auto" }} height='100%' role="main" 
      aria-labelledby="content-heading"
    >
       <div id="content-heading" style={{ position: 'absolute', width: 0, height: 0, overflow: 'hidden' }}>
        Main content area
      </div>
      {children}
      <SnackBarAlert />
      <LogoutModal open={logout} />
    </Box>
  );
}
