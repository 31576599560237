import React from "react";
import TablesCard from "../../commons/TablesCard";
import DescriptionIcon from '@material-ui/icons/Description';
import { useSelector, useDispatch } from 'react-redux';
import { xssResults } from '../../../store/actions/securityActions';
import { archiveXssLinks, completeXssLinks, ignoreXssLinks } from '../../../store/actions/securityTablesActions';

import Box from '@material-ui/core/Box';
import LaunchIcon from '@material-ui/icons/Launch';
import ReactTable from '../../commons/ReactTable'


export default function XSSDetection({ scanId, tables, setTables, tabs }) {

  const [tblData, setTblData] = React.useState(null);

  const data = useSelector(state => state.security.xssData);
  const status = useSelector(state => state.security.xssDataStatus);
  const loading = useSelector(state => state.security.xssDataLoading);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (scanId) {
      dispatch(xssResults(scanId))
    } else {
      dispatch(xssResults(scanId))
    }
    // eslint-disable-next-line
  }, [scanId])

  React.useEffect(() => {
    if (status === "pending") {
      const interval = setInterval(() => {
        if (scanId) {
          dispatch(xssResults(scanId))
        } else {
          dispatch(xssResults(scanId))
        }
      }, 15000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [status]);

  function getXssData(xssData) {
    const tblDataXss = [];

    for (let i = 0; i < xssData.length; i++) {
      const link = xssData[i].page;
      const severity = xssData[i].severity;
      const type = xssData[i].type;
      tblDataXss.push({
        key: i,
        link: <Box display="flex" alignItems="center" >
          <LaunchIcon style={{ fontSize: '1.2rem' }}
            onClick={() => window.open(`${link}`, '_blank')}
          />
          <span style={{ ...styles.wplinks, fontWeight: 600 }} aria-label={link} className="primarycolor"
            onClick={() =>
              window.open(
                `/violation-reports/link?${new URLSearchParams({ link: link }).toString()}`
              )
            }
          >{(link.length > 40) ? `...${link.substring(20, 45)}...` : link}</span>
        </Box>,
        type: <span style={{ fontWeight: 600 }} >{type}</span>,
        severity: <span style={{ fontWeight: 600 }} >{severity}</span>,
        issue: {
          ...xssData[i]
        }
      });
    }
    return tblDataXss;
  }

  React.useEffect(() => {
    let getParsedData = null;

    if (data) {
      // eslint-disable-next-line
      data.length > 0 ? setTables({ ...tables, xss: true }) : setTables({ ...tables, xss: false });;
      getParsedData = getXssData(data);
      setTblData(getParsedData);
      // eslint-disable-next-line
    }
    // eslint-disable-next-line
  }, [data]);


  const xssColumns = [
    {
      width: '150px',
      Header: 'Web Page Link',
      accessor: 'link',
    },
    {
      width: '100px',
      Header: 'Type',
      accessor: 'type',
    },
    {
      width: 'auto',
      Header: 'Severity',
      accessor: 'severity',
    },
  ]

  const styles = {
    marginRight: {
      marginRight: 9
    },
    wplinks: {
      textDecoration: "none", fontWeight: 600
    },
    textCenter: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer"
    }
  }


  function archivedActions(selectedRowIds, options) {
    const issues = selectedRowIds.map((item) => {
      return item.original.issue
    })

    const readyData = {
      scanId: scanId,
      archiveOptions: {
        ...options
      },
      issues
    }
    dispatch(archiveXssLinks(readyData, scanId))
  }

  function completeAction(selectedRowIds) {
    const issues = selectedRowIds.map((item) => {
      return item.original.issue
    })

    const readyData = {
      scanId: scanId,
      issues
    }
    dispatch(completeXssLinks(readyData, scanId))
  }


  function ignoreAction(selectedRowIds) {
    const issues = selectedRowIds.map((item) => {
      return item.original.issue
    })

    const readyData = {
      scanId: scanId,
      issues
    }
    dispatch(ignoreXssLinks(readyData, scanId))
  }

  if ((!tblData || tblData.length < 1) && status === "completed") {
    return ""
  } else {
    return (
      <TablesCard icon={<DescriptionIcon />} title="XSS Detection" status={status} tabs={tabs}>
        {tblData && !loading ? (
          <>
            <p tabIndex="0">
              Cross-site scripting <strong>(XSS)</strong> is a type of security vulnerability typically found in web applications. <strong>XSS</strong> attacks enable attackers to inject client-side scripts into web pages viewed by other users. A cross-site scripting vulnerability may be used by attackers to bypass access controls such as the same-origin policy.
            </p>
            {tblData.length > 0 ? (
              <ReactTable
                tableColumns={xssColumns}
                data={tblData}
                selection={true}
                optionType={true}
                completeAction={completeAction}
                ignoreAction={ignoreAction}
                archivedActions={archivedActions}
              />
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "200px", maxHeight: "200px" }}
                role="alert"
                aria-live="assertive"
              >
                <h4 tabIndex="0">Please wait while we load your data</h4>
              </Box>
            )}
          </>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: "200px", maxHeight: "200px" }}
            role="status"
            aria-live="polite"
          >
            <h4>Please wait while we load your data</h4>
          </Box>
        )}
      </TablesCard>
    );
  }
}