import React from "react";
import { useSelector, useDispatch } from "react-redux";
// import ReadMoreReact from 'read-more-react';
import { unArchiveBreachedInfo, getArchivedBreachedInfo } from "../../../store/actions/securityTablesActions";

import TablesCard from "../../commons/TablesCard";
import DescriptionIcon from "@material-ui/icons/Description";
import Box from "@material-ui/core/Box";
import ReactTable from "../../commons/ReactTable";
import loader from "../../../assets/loader.svg";

export default function ArchivedBreachedDataTable({ domainId }) {
  const [tblData, setTblData] = React.useState(null);
  const [columns, setColumns] = React.useState([]);

  const [page, setPage] = React.useState(1);

  const dispatch = useDispatch();
  const data = useSelector((state) => state.securityTable.archivedBreachedInfo);
  const pages = useSelector((state) => state.securityTable.archivedBreachedInfoPages);
  const count = useSelector((state) => state.securityTable.archivedBreachedInfoCount);
  const brColumns = useSelector((state) => state.securityTable.archivedBreachedInfoCols);

  const status = useSelector((state) => state.securityTable.archivedBreachedInfoStatus);
  const loading = useSelector((state) => state.securityTable.archivedBreachedInfoLoading);


  React.useEffect(() => {
    if (domainId) {
      dispatch(
        getArchivedBreachedInfo(
          domainId, page
        )
      );
    }
    // eslint-disable-next-line
  }, [domainId, page]);



  React.useEffect(() => {
    if (data && brColumns) {
      const mdCols = brColumns.map(col => {
        return ({
          Header: col,
          accessor: col
        })
      })
      setColumns(mdCols);
      const bData = data.map(d => {
        return (
          { ...d, issue: { ...d } }
        )
      })
      setTblData(bData)
    }
  }, [data, domainId]);

  function unarchivedAction(selectedRowIds, options) {
    const issues = selectedRowIds.map((item) => {
      return item.original.issue
    })
    dispatch(
      unArchiveBreachedInfo(
        issues,
        domainId,
      )
    );
  }


  return (
    <TablesCard
      icon={<DescriptionIcon />}
      title="Breached Info"
      status={status}
    >
      {tblData && !loading ? (
        <>
          {tblData.length > 0 ? (
            <ReactTable
              tableColumns={columns}
              data={tblData}
              selection={true}
              unarchivedAction={unarchivedAction}
              pages={pages}
              count={count}
              page={page}
              setPage={setPage}
              breachTable={true}
            />
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ minHeight: "200px", maxHeight: "200px" }}
              role="alert"
              aria-live="assertive"
            >
              <h4>We found no Archived Breached Data on your site</h4>
            </Box>
          )}
        </>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "200px", maxHeight: "200px" }}
          role="status"
          aria-live="polite"
        >
          <img src={loader} alt="loader" />
        </Box>
      )}
    </TablesCard>
  );
}
