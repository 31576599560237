import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCveExploitsTable2 } from "../../../store/actions/securityTablesActions";
import TablesCard from "../../commons/TablesCard";
import DescriptionIcon from "@material-ui/icons/Description";
import Box from "@material-ui/core/Box";
import loader from "../../../assets/loader.svg";
import CVEExploitTable from "../../../pages/cve/CVEExploitTable";

export default function DismissedCveExploits({ scanId, changer }) {
  const [tblData, setTblData] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const dispatch = useDispatch();

  const data = useSelector(
    (state) => state.securityTable.cveExploitsTableDismissed
  );

  React.useEffect(() => {
    if (scanId) {
      dispatch(getCveExploitsTable2(scanId, page, false, true));
    }
    // eslint-disable-next-line
  }, [scanId]);

  React.useEffect(() => {
    if (data) {
      setTblData(data);
      console.log(data);
    }
  }, [data, scanId]);

  return (
    <TablesCard
      icon={<DescriptionIcon />}
      title="Dismissed CVE's"
      status={"completed"}
    >
      {tblData ? (
        <>
          {tblData.result && tblData.result.length > 0 ? (
            <CVEExploitTable
              data={tblData.result}
              scanId={scanId}
              setPage={setPage}
              page={page}
              archive={false}
              dismiss={true}
              noDesc={"Dismissed CVE's"}
            />
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ minHeight: "200px", maxHeight: "200px" }}
              role="alert"
              aria-live="assertive"
            >
              {changer(false)}
              <h4>No Dismissed CVE's</h4>
            </Box>
          )}
        </>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "200px", maxHeight: "200px" }}
          role="status"
          aria-live="polite"
        >
          <img src={loader} alt="loader" />
        </Box>
      )}
    </TablesCard>
  );
}
