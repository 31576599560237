import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { getAccessibilityCardDetails } from "../../../store/actions/accessibilityCardActions";
import loader from "../../../assets/loader.svg";
import { CardNote } from "../../accessibility-main/components/CardNote";
import LinearProgress from "@material-ui/core/LinearProgress";
import allIcon from "../../../assets/new-accessibility-icons/all.png";
import hearingIcon from "../../../assets/new-accessibility-icons/hearing.svg";
import visualIcon from "../../../assets/new-accessibility-icons/visual.svg";
import motorIcon from "../../../assets/new-accessibility-icons/motor.svg";
import cognitiveIcon from "../../../assets/new-accessibility-icons/cognitive.svg";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { IconCircle } from "../../commons/icons/accessibilitymainIcons/icons/IconCircle";
import PerformanceTable from "../../PerformanceComponents/performanceCard/PerformanceTable";
import ShortenNumber from "../../commons/ShortenNumber";
import { nFormatter } from "../../../utils/shortNumber";
import {
  accessibilityAnalyticsStyles,
  accessibilityAnalyticsInlineStyles,
} from "../../../Styles/jss/accessibilityComponents/accessibilityAnalyticsStyles";

import scanStatus from "../../../scanStatuses";
import Arrow from "../../commons/Arrow";
import Top5 from "../../PerformanceComponents/performanceCard/Top5";

const useStyles = makeStyles(accessibilityAnalyticsStyles);

const styles = {
  ...accessibilityAnalyticsInlineStyles,
};

function parseDonutData(data) {
  const parsedData = [];
  const keys = Object.keys(data.violationsByPriority);
  for (let i = 0; i < keys.length; i++) {
    if (keys[i] === "low") {
      parsedData.push({
        name: "low",
        value: data.violationsByPriority.low,
      });
    } else if (keys[i] === "medium") {
      parsedData.push({
        name: "medium",
        value: data.violationsByPriority.medium,
      });
    } else {
      parsedData.push({
        name: "high",
        value: data.violationsByPriority.high,
      });
    }
  }
  return parsedData;
}

//These colors are only for pie chart
const COLORS = ["#21CA5A", "#FFA412", "#E2211E"];

function AccessibilityCard({ scanId, setLoader }) {
  const [tab, setTab] = React.useState(0);
  const [donutData, setDonutData] = React.useState([]);

  const unnified = useSelector(
    (state) => state.dashboard.unified
  );
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const classes = useStyles();

  const dispatch = useDispatch();
  const data = useSelector((state) => state.accessibilityCard.data);
  const isSelectedDomainLink = useSelector(
    (state) => state.dashboard.selectedLink
  );

  const loading = useSelector((state) => state.accessibilityCard.loading);
  const status = useSelector((state) => state.accessibilityCard.status);
  const scanProgress = useSelector(
    (state) => state.onBoardDashboard.scanProgress
  );
  const allAccessibility = "all";
  React.useEffect(() => {
    if (scanId) {
      if(isSelectedDomainLink === "" || isSelectedDomainLink === undefined) {
        dispatch(getAccessibilityCardDetails(scanId, allAccessibility, unnified));
      } else {
        dispatch(getAccessibilityCardDetails(scanId, allAccessibility, unnified, isSelectedDomainLink));
      }
    }
    // eslint-disable-next-line
  }, [scanId, unnified, isSelectedDomainLink]);

  React.useEffect(() => {
    if (status === scanStatus.RUNNING || status === scanStatus.PENDING) {
      setLoader(true);
      const interval = setInterval(() => {
        if (scanId) {
          setTab(0);
          dispatch(getAccessibilityCardDetails(scanId, allAccessibility, unnified));
        }
      }, 15000);
      return () => clearInterval(interval);
    } else {
      setLoader(false);
    }
    // eslint-disable-next-line
  }, [status]);

  React.useEffect(() => {
    if (data) {
      const parsedData = parseDonutData(data);
      setDonutData(parsedData);
    }
  }, [data]);

  const columns = [
    {
      column: "",
      width: 30,
    },
    {
      column: "Total",
      width: 64,
    },
    {
      column: "Priority",
      width: 280,
    },
  ];

  const columns2 = [
    {
      column: "",
      width: 30,
    },
    {
      column: "Instances",
      width: 100,
    },

    {
      column: "Page Count",
      width: 120,
    },
    {
      column: "WCAG",
      width: 120,
    },
    {
      column: "Priority",
      width: 280,
    },
    {
      column: "",
      width: 280,
    },
  ];

  const pagesColumns = [
    {
      column: "No. of Violations",
      width: 165,
    },
    {
      column: "Pages",
      width: 100,
    },
    {
      column: "Priority",
      width: 280,
    },
  ];

  const tabs = ["Violations", "Pages"];
  return (
    <React.Fragment>
      {loading ? (
        <>
          {setLoader(false)}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: 371 }}
          >
            <img src={loader} alt="loader" />
          </Box>
        </>
      ) : (
        <>
          {data ? (
            <>
              <Box display="flex" justifyContent="space-between">
                <CustomTabs
                  value={tab}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  style={{ marginBottom: "10px" }}
                >
                  <CustomTab
                    label="ALL"
                    onClick={() =>
                      dispatch(getAccessibilityCardDetails(scanId, "all"))
                    }
                    tabIndex="0"
                  />
                  <CustomTab
                    label="Cognitive"
                    onClick={() =>
                    {isSelectedDomainLink === ""  || isSelectedDomainLink === undefined ? dispatch(getAccessibilityCardDetails(scanId, "cognitive")) : dispatch(getAccessibilityCardDetails(scanId, "cognitive", unnified, isSelectedDomainLink))

                    }
                    }
                    tabIndex="0"
                  />
                  <CustomTab
                    label="Hearing"
                    onClick={() =>
                    {
                      isSelectedDomainLink === "" || isSelectedDomainLink === undefined ? dispatch(getAccessibilityCardDetails(scanId, "hearing")) : dispatch(getAccessibilityCardDetails(scanId, "hearing", unnified, isSelectedDomainLink))
                    }
                    }
                    tabIndex="0"
                  />
                  <CustomTab
                    label="Visual"
                    onClick={() =>
                    
                      isSelectedDomainLink === "" || isSelectedDomainLink === undefined ? dispatch(getAccessibilityCardDetails(scanId, "visual")) : dispatch(getAccessibilityCardDetails(scanId, "visual", unnified, isSelectedDomainLink))
                    }
                    tabIndex="0"
                  />
                  <CustomTab
                    label="Motor"
                    onClick={() =>
                      isSelectedDomainLink === "" || isSelectedDomainLink === undefined ? dispatch(getAccessibilityCardDetails(scanId, "motor")) : dispatch(getAccessibilityCardDetails(scanId, "motor", unnified, isSelectedDomainLink))
                    }
                    tabIndex="0"
                  />
                </CustomTabs>
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} tabIndex="0">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} tabIndex="0">
                      <Box className={`${classes.boxContainer} box-container `}>
                        {tab === 0 && (
                          <img
                            src={allIcon}
                            width="59px"
                            height="59px"
                            alt="accessibility-icon"
                          />
                        )}
                        {tab === 1 && (
                          <img
                            src={cognitiveIcon}
                            width="59px"
                            height="59px"
                            alt="cognitive-icon"
                          />
                        )}
                        {tab === 2 && (
                          <img
                            src={hearingIcon}
                            width="59px"
                            height="59px"
                            alt="hearing-icon"
                          />
                        )}
                        {tab === 3 && (
                          <img
                            src={visualIcon}
                            width="59px"
                            height="59px"
                            alt="visual-icon"
                          />
                        )}
                        {tab === 4 && (
                          <img
                            src={motorIcon}
                            width="59px"
                            height="59px"
                            alt="motor-icon"
                          />
                        )}
                        <Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <CardNote
                              size="big"
                              className={clsx(
                                data.scoreType === "high"
                                  ? "circle-critical"
                                  : data.scoreType === "medium"
                                    ? "circle-moderate2"
                                    : "circle-safe"
                              )}
                            >
                              {data.score}
                            </CardNote>
                            <Arrow
                              value={data.scoreChangePercentage}
                              change={data.scoreChange}
                            />
                          </Box>
                          {tab === 0 && (
                            <div style={styles.scoreTitle}>
                              Accessibility <br /> Score
                            </div>
                          )}
                          {tab === 1 && (
                            <div style={styles.scoreTitle}>
                              Accessibility <br /> Cognitive Score
                            </div>
                          )}
                          {tab === 2 && (
                            <div style={styles.scoreTitle}>
                              Accessibility <br /> Hearing Score
                            </div>
                          )}
                          {tab === 3 && (
                            <div style={styles.scoreTitle}>
                              Accessibility <br /> Visual Score
                            </div>
                          )}
                          {tab === 4 && (
                            <div style={styles.scoreTitle}>
                              Accessibility <br /> Motor Score
                            </div>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} tabIndex="0">
                      <Box style={styles.boxContainer2}>
                        <Box width="100%">
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <h3 style={styles.vulPagesTitle}>
                              Pages With Errors
                            </h3>
                            <span style={styles.vulPages}>
                              <Arrow
                                value={data.pagesWithErrorsChangePercentage}
                                change={data.pagesWithErrorsChange}
                              />
                              <ShortenNumber>
                                {data.pagesWithErrors}
                              </ShortenNumber>
                            </span>
                          </Box>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            mb={2.5}
                          >
                            <h3 style={styles.vulPagesTitle}>
                              Total Number of Pages
                            </h3>
                            <span style={styles.vulPages}>
                              <ShortenNumber>
                                {/* {scanProgress.totalLinks} */}
                                {data.pagesWithErrors}
                              </ShortenNumber>
                            </span>
                          </Box>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <span style={styles.barPercentage}>
                              {data.pageWithErrorsPercentage}%
                            </span>
                            <Box width="100%">
                              <BorderLinearProgress
                                variant="determinate"
                                value={data.pageWithErrorsPercentage}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    {isSelectedDomainLink === "" || isSelectedDomainLink === undefined ? (
                    <Grid item xs={12} sm={6} md={4} tabIndex="0">
                    <Box style={styles.boxContainer}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        flexDirection="column"
                        alignItems="center"
                        height="100%"
                      >
                        <h3 style={styles.avgperPageTitle}>
                          Average <br /> Per Page
                        </h3>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <span
                            style={styles.avgperPageValue}
                            className={clsx(
                              data.totalAverageType === "high"
                                ? "color-critical"
                                : data.totalAverageType === "medium"
                                  ? "color-moderate2"
                                  : "color-safe"
                            )}
                          >
                            {data.averagePerPage}
                          </span>
                          <Arrow
                            value={data.averagePerPageChangePercentage}
                            change={data.averagePerPageChange}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                    ) : (null)}

                    <Grid item xs={12} sm={6} md={4} tabIndex="0">
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        text
                        height="100%"
                        style={{
                          background: "rgb(255, 255, 255)",
                          boxShadow: "rgb(0 0 0 / 12%) 0px 0px 10px",
                          boxSizing: "border-box",
                          borderRadius: 9,
                          padding: 14,
                        }}
                      >
                        <h3 style={styles.totalViolationsTitle}>
                          Total <br /> Violations
                        </h3>
                        <Box
                          display="flex"
                          justifyContent="center"
                          flexDirection="column"
                          alignItems="center"
                          textAlign="center"
                          height="100%"
                          width="100%"
                        >
                          <ResponsiveContainer width={100} height={100}>
                            <PieChart width={50} height={50}>
                              <text
                                style={styles.pieCahrtValue}
                                x={"50%"}
                                y={"50%"}
                                textAnchor="middle"
                                dominantBaseline="middle"
                              >
                                {nFormatter(data.totalViolations, 10000)}
                              </text>
                              <Pie
                                dataKey="value"
                                data={donutData}
                                cx={"50%"}
                                cy={"50%"}
                                innerRadius={40}
                                outerRadius={50}
                                fill="#8884d8"
                              >
                                {donutData.map((entry, index) => (
                                  <Cell
                                    key={index}
                                    fill={COLORS[index % COLORS.length]}
                                  />
                                ))}
                              </Pie>
                            </PieChart>
                          </ResponsiveContainer>
                          <Arrow
                            value={data.totalViolationsChangePercentage}
                            change={data.totalViolationsChange}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} tabIndex="0">
                      <Box style={styles.boxContainer}>
                        <Box
                          display="flex"
                          width="100%"
                          justifyContent="space-between"
                          flexDirection="column"
                          alignItems="center"
                          height="100%"
                        >
                          <h3 style={styles.ViolationsByPriorityTitle}>
                            Violations <br /> by Priority
                          </h3>
                          <Box
                            width="100%"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            height="100%"
                            ml={4}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <span style={styles.priorityValue}>
                                {/* //className="color-critical"
                                  removed from span to make colors of violation by 
                                  priority same color */}
                                <ShortenNumber>
                                  {data.violationsByPriority.high}
                                </ShortenNumber>
                              </span>
                              {/* removed from ShortenNumber className="color-moderate2"to make violation by 
                                  priority same color */}
                              <span style={styles.priorityValue}>
                                <ShortenNumber>
                                  {data.violationsByPriority.medium}
                                </ShortenNumber>
                              </span>
                              <span style={styles.priorityValue}>
                                <ShortenNumber>
                                  {data.violationsByPriority.low}
                                </ShortenNumber>
                              </span>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                textAlign: "start",
                              }}
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                textAlign="start"
                                style={{ minWidth: "90px", maxWidth: "90px" }}
                              >
                                {/* fill="#E2211E" */}
                                <span style={{ margin: "0px 5px" }}>
                                  <IconCircle
                                    fill="#1f3a93"
                                    height="6px"
                                    width="6px"
                                  />
                                </span>
                                <span style={styles.priorityValue}>A</span>
                                <Arrow
                                  value={
                                    data.violationsByPriority
                                      .highChangePercentage
                                  }
                                  change={data.violationsByPriority.highChange}
                                />
                              </Box>
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                textAlign="start"
                                style={{ minWidth: "90px", maxWidth: "90px" }}
                              >
                                <span style={{ margin: "0px 5px" }}>
                                  <IconCircle
                                    fill="#1f3a93"
                                    height="6px"
                                    width="6px"
                                  />
                                </span>
                                <span style={styles.priorityValue}>AA</span>
                                <Arrow
                                  value={
                                    data.violationsByPriority
                                      .mediumChangePercentage
                                  }
                                  change={
                                    data.violationsByPriority.mediumChange
                                  }
                                />
                              </Box>
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                textAlign="start"
                                style={{
                                  minWidth: "90px",
                                  maxWidth: "90px",
                                  marginLeft: "4px",
                                }}
                              >
                                <span style={{ margin: "0px 5px" }}>
                                  <IconCircle
                                    fill="#1f3a93"
                                    height="6px"
                                    width="6px"
                                  />
                                </span>
                                <span style={styles.priorityValue}>AAA</span>
                                <Arrow
                                  value={
                                    data.violationsByPriority
                                      .lowChangePercentage
                                  }
                                  change={data.violationsByPriority.lowChange}
                                />
                              </Box>
                            </div>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid item xs={12} md={4}>
                  <Box style={styles.boxContainer}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      height="100%"
                    >
                      <h3 style={styles.section508}> Section 508</h3>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                      >
                        <span
                          style={styles.avgperPageValue}
                        // className={classes.section508Value}
                        >
                          {data.totalSection508}
                        </span>
                        <Arrow
                          value={data.totalSection508ChangePercentage}
                          change={data.totalSection508Change}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid> */}
                {/* <Grid item xs={12} sm={12} md={8} tabIndex="0">
                  <PerformanceTable
                    columns={columns}
                    columns2={pagesColumns}
                    data={data}
                    tabs={tabs}
                    totalViolations={data.totalViolations}
                  />
                </Grid> */}
                <Grid item xs={12} sm={12} md={12} tabIndex="0">
                  <Top5
                    title={"Top 5 Most Frequent Violations"}
                    name="top5Frequent"
                    columns={columns2}
                    columns2={pagesColumns}
                    data={data}
                    tabs={tabs}
                    totalViolations={data.totalViolations}
                  />
                </Grid>
                {data.length > 0 && <Grid item xs={12} sm={12} md={12} tabIndex="0">
                  <Top5
                    name="top5Critical"
                    title={"Top 5 Critical"}
                    columns={columns2}
                    columns2={pagesColumns}
                    data={data}
                    tabs={tabs}
                    totalViolations={data.totalViolations}
                  />
                </Grid>}
              </Grid>

            </>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              className={classes.boxload}
            >
              <h4>
                {" "}
                {scanProgress.status !== "completed"
                  ? "Currently, the scan is running. If an issue is discovered, the data will automatically populate here."
                  : "We found no Acessibility Issues on your site"}{" "}
              </h4>
            </Box>
          )}
        </>
      )}
    </React.Fragment>
  );
}
export default React.memo(AccessibilityCard);

const CustomTabs = withStyles({
  root: {
    borderBottom: "none",
  },
  indicator: {
    backgroundColor: "white",
  },
})(Tabs);

const CustomTab = withStyles((theme) => ({
  root: {
    minWidth: 40,
    opacity: 1,
    minHeight: "auto",
    borderRadius: "2px",
    padding: "0px 8px",
    border: "1px solid #1f3a93",
    color: "#1f3a93",
    fontWeight: 400,
    fontSize: "18px",
    fontFamily: "Segoe UI",
    borderRight: 0,
    "&:last-child": {
      borderRight: "1px solid #1f3a93",
    },
    "&:hover": {
      color: "white",
      backgroundColor: "#1f3a93",
      opacity: 1,
    },
    "&$selected": {
      color: "white",
      fontWeight: theme.typography.fontWeightMedium,
      backgroundColor: "#1f3a93",
    },
    "&:focus": {
      color: "white",
      backgroundColor: "#1f3a93",
    },
  },
  selected: {
    color: "white",
    backgroundColor: "#1f3a93",
  },
}))((props) => <Tab disableRipple {...props} />);

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 10,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 10,
    backgroundColor: "#1f3a93",
  },
}))(LinearProgress);
