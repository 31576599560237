import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ReplayIcon from '@material-ui/icons/Replay';
import StopIcon from '@material-ui/icons/Stop';
import PauseIcon from '@material-ui/icons/Pause';
import CompletedIcon from '../../commons/icons/CompletedIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Tooltip from '@material-ui/core/Tooltip';
import { CircularProgress, Grid } from '@material-ui/core';
import Auth0Service from "../../../services/auth0Service/authentikService";
import { dateFormatter } from "../../../utils/dateFormatter";
import { getCrashedScans, deleteScan, reScan, scanAction } from "../../../store/actions/adminScansManagementActions";
import history from '../../../@history';
import { useParams } from "react-router-dom";

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row(props) {
  // keyCode
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        onClick={() => setOpen(!open)}
        hover
        role="button"
        aria-expanded={open}
        aria-label={`Toggle details for ${row.domain}`}
      >
        <TableCell component="th" scope="row" style={{ width: "100%" }}>
          <Box display="flex" alignItems="center">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <Typography>{row.domain} - {dateFormatter(new Date(row.dateNow))}</Typography>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, overflow: "auto" }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="scan details" style={{ tableLayout: "fixed" }}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 250 }}>Scan Id</TableCell>
                    <TableCell style={{ width: 150 }}>Date</TableCell>
                    <TableCell style={{ width: 150 }}>Status</TableCell>
                    <TableCell style={{ width: 120 }}>Browser</TableCell>
                    <TableCell style={{ width: 100 }}>Device</TableCell>
                    <TableCell style={{ width: 120 }}>Resolution</TableCell>
                    <TableCell style={{ width: 230 }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.scans.map((scan) => (
                    <TableRow key={scan?.scanId}>
                      <TableCell scope="row" style={{ cursor: 'pointer' }} onClick={() => window.open(`/dashboard/?domain=${row.domain}&scanId=${scan?.scanId}`, "_blank")}>
                        {scan?.scanId}
                      </TableCell>
                      <TableCell scope="row">
                        {dateFormatter(new Date(scan?.dateNow))}
                      </TableCell>
                      <TableCell>{scan?.status}</TableCell>
                      <TableCell>{scan?.browser}</TableCell>
                      <TableCell>{scan?.device}</TableCell>
                      <TableCell>
                        {scan?.resolution}
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Delete Scan">
                          <IconButton onClick={() => dispatch(deleteScan(scan?.scanId))}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Rescan with new Sitemap">
                          <IconButton onClick={() => dispatch(reScan(scan?.scanId))}>
                            <ReplayIcon />
                          </IconButton>
                        </Tooltip>
                        {scan?.status === "stopped" && (
                          <Tooltip title="Rescan with old Sitemap">
                            <IconButton onClick={() => dispatch(scanAction(scan?.scanId, "rescan"))}>
                              <StopIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {scan?.status !== "stopped" && (
                          <Tooltip title="Stop Scan">
                            <IconButton onClick={() => dispatch(scanAction(scan?.scanId, "stop"))}>
                              <StopIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {(scan?.status !== "resumed" && scan?.status !== "stopped" && scan?.status !== "paused") && (
                          <Tooltip title="Pause Scan">
                            <IconButton onClick={() => dispatch(scanAction(scan?.scanId, "pause"))}>
                              <PauseIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {(scan?.status !== "stopped" && scan?.status !== "pending") && (
                          <Tooltip title="Resume Scan">
                            <IconButton onClick={() => dispatch(scanAction(scan?.scanId, "resume"))}>
                              <PlayArrowIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {scan?.status !== "stopped" && (
                          <Tooltip title="Mark as Complete Scan">
                            <IconButton onClick={() => dispatch(scanAction(scan?.scanId, "complete"))}>
                              <CompletedIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}



export default function AdminScans() {
  // const [key, setKey] = React.useState('')

  const dispatch = useDispatch();
  // const { keyCode } = useParams();
  // React.useEffect(() => {
  //   if (keyCode) {
  //     setKey(keyCode);
  //   }
  // }, [keyCode])

  const data = useSelector((state) => state.adminScansManagementReducer.data);
  const loading = useSelector((state) => state.adminScansManagementReducer.loading);


  React.useEffect(() => {
    dispatch(getCrashedScans());
    // eslint-disable-next-line
  }, [])

  if (!Auth0Service.isAuthenticated()) {
    return window.location.href = "/authenticate"
  } else {
    return (
      <>
        {!loading ? <>
          <Grid container spacing={2}>
            {data.length > 0 ? <> {data.map((row) => (
              <Grid item md={6}>
                <Table aria-label="collapsible table" style={{ tableLayout: "fixed" }}>
                  {/* <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      width: "100%",
                      background: '#fff'
                    }}
                  >Scans</TableCell>
                </TableRow>
              </TableHead> */}
                  <TableBody>
                  {/* keyCode={key} */}
                    <Row key={row.scanManagerId} row={row} />
                  </TableBody>
                </Table>
              </Grid>
            ))}</> :
              <Box textAlign='center' width='100%' mt={4}>
                <i>No Other Scans</i>
              </Box>
            }

          </Grid>
        </>
          :
          <Box width="100%" display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>}
      </>
    );
  }
}

