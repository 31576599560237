import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import ShortenNumber from "../../../commons/ShortenNumber";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
// import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
// import CustomTooltip from '../../../commons/Tooltip';

import securityStatCardStyles from "../../../../Styles/jss/securityComponents/securityAnalytics/securityStatCardStyles";
const styles = {
  ...securityStatCardStyles,
};

const useStyles = makeStyles((theme) => ({
  root: {
    color: "white",
    fontFamily: "Segoe UI",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 700,
    letterSpacing: "0em",
    textAlign: "left",
  },
  IconButton: {
    marginLeft: 10,
    color: "black",
    padding: 0,
    marginBottom: 2,
  },
  title: {
    fontSize: 20,
    color: "#000",
    fontWeight: 700,
  },
}));

export default function SubDomainTakeOverCard({
  title,
  detail,
  found,
  riskLevel,
  status,
  vuls,
  subdomainDetected,
  detected,
  change,
}) {
  const classes = useStyles();
  const settingDisplay = () => {
    change(vuls);
  };
  return (
    <span style={{ transform: "translate3d(0, 0, 0)" }} role="region"
      aria-labelledby="section-title">
      {settingDisplay()}
      <Card style={styles.cardMain} role="region" aria-labelledby="section-title" >
        <Box
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="flex-start"
          width={"100%"}
          className="stat-card-dash"
        >
          <Box
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="space-between"
            width={"100%"}
          >
            <small className={classes.title}>{title}</small>
            {/* <CustomTooltip title={detail}>
              <ErrorOutlineIcon style={{ fontSize: '1.3rem',color:'#585858' }} />
            </CustomTooltip> */}
          </Box>
        </Box>
        <CardContent style={styles.p0}>
          <Box style={styles.cardSub}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <span style={styles.subTitle}>Status</span>
              <span
                role="status"
                aria-live="polite"
                style={{
                  color: status === "vulnerable" ? "#E2211E" : "#065B14",
                  fontWeight: "bold",
                }}
              >
                {status}
              </span>
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <span style={styles.subTitle}>Risk Level</span>
              <Chip
                className={classes.root}
                aria-label={`Risk Level: ${riskLevel}`}
                style={{
                  backgroundColor:
                    riskLevel === "CRITICAL"
                      ? "#FFE4E8"
                      : riskLevel === "MODERATE"
                      ? "FFF6A4"
                      : "#CAFFD2",
                  color:
                    riskLevel === "MODERATE"
                      ? "black"
                      : riskLevel === "CRITICAL"
                      ? "#EA001B"
                      : "#065B14",
                }}
                size="small"
                label={riskLevel.toUpperCase()}
              />
            </Box>
          </Box>
          {title !== "CORS" &&
          title !== "CVES" &&
          title !== "Breached Information" ? (
            <Box style={styles.BoxSub}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={title === "SSL Detection" ? 0 : 1.5}
              >
                <>
                  <span style={styles.subTitle}>
                    {title === "Subdomain Takeover"
                      ? "Subdomain Detected"
                      : "Detected"}
                  </span>
                  <span style={styles.vulPages}>
                    {title === "Subdomain Takeover"
                      ? subdomainDetected
                      : detected}
                  </span>
                </>
              </Box>
            </Box>
          ) : (
            <></>
          )}
          {/* <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={0.5}
          >
            <span style={styles.subTitle}>Vulnerabilities</span>
            <span style={styles.vulFound}>
              <ShortenNumber>{vuls}</ShortenNumber>
            </span>
          </Box> */}
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box style={{ width: 90, height: 90 }} aria-label={`Vulnerabilities: ${vuls}`}>
              <CircularProgressbar
                maxValue={vuls}
                value={vuls}
                text={`${vuls}`}
                strokeWidth={13}
                counterClockwise
                styles={buildStyles({
                  pathColor: "#FF5C00",
                  textColor: "#000",
                  textSize: "21px",
                })}
              />
              <h3
                style={{
                  fontFamily: "Segoe UI",
                  fontSize: "20px",
                  fontWeight: 700,
                  letterSpacing: "0em",
                  lineHeight: 2,
                  marginTop: "-8px",
                  marginLeft: "-20px",
                }}
              >
                Vulnerabilities
              </h3>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </span>
  );
}
