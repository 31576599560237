import React from "react";
import TablesCard from "../../commons/TablesCard";
import DescriptionIcon from '@material-ui/icons/Description';
import { useSelector, useDispatch } from 'react-redux';
import { sqliResults } from '../../../store/actions/securityActions';
import { archiveSqliLinks, completeSqliLinks, ignoreSqliLinks } from '../../../store/actions/securityTablesActions';

import Box from '@material-ui/core/Box';
import LaunchIcon from '@material-ui/icons/Launch';
import ReactTable from '../../commons/ReactTable'
export default function SqlDetection({ scanId, tables, setTables, tabs }) {

  const [tblData, setTblData] = React.useState(null);

  const data = useSelector(state => state.security.sqliData);
  const status = useSelector(state => state.security.sqliDataStatus);
  const loading = useSelector(state => state.security.sqliDataLoading);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (scanId) {
      dispatch(sqliResults(scanId));
    } else {
      dispatch(sqliResults(scanId, []));
    }
    // eslint-disable-next-line
  }, [scanId])

  React.useEffect(() => {
    if (status === "pending") {
      const interval = setInterval(() => {
        if (scanId) {
          dispatch(sqliResults(scanId));
        } else {
          dispatch(sqliResults(scanId, []));
        }
      }, 15000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [status]);

  function getSqlData(sqliData) {
    const tblDataSQLI = [];

    for (let i = 0; i < sqliData.length; i++) {
      const link = sqliData[i].page;
      const severity = sqliData[i].severity;
      const type = sqliData[i].type;
      tblDataSQLI.push({
        key: i,
        link: <Box display="flex" alignItems="center">
          <LaunchIcon style={{ fontSize: '1.2rem' }}
            onClick={() => window.open(`${link}`, '_blank')}
          />
          <span style={styles.wplinks} aria-label={`${link}`} className="primarycolor"
            onClick={() =>
              window.open(
                `/violation-reports/link?${new URLSearchParams({ link: link }).toString()}`
              )
            }
          >{(link.length > 40) ? `...${link.substring(20, 45)}...` : link}</span>
        </Box>,
        type: <span style={{ fontWeight: 500 }} >{type}</span>,
        severity: <span style={{ fontWeight: 500 }} >{severity}</span>,
        issue: {
          ...sqliData[i]
        }
      });
    }
    return tblDataSQLI;
  }

  React.useEffect(() => {

    let getParsedData = null;

    if (data) {
      // eslint-disable-next-line
      // data.length > 0 ? setTables({ ...tables, sql: true }) : setTables({ ...tables, sql: false });
      getParsedData = getSqlData(data);
      setTblData(getParsedData);
      // eslint-disable-next-line
    }
    // eslint-disable-next-line
  }, [data]);


  const sqliColumns = [
    {
      width: '150px',
      Header: 'Web Page Link',
      accessor: 'link',
    },
    {
      width: '100px',
      Header: 'Type',
      accessor: 'type',
    },
    {
      width: 'auto',
      Header: 'Severity',
      accessor: 'severity',
    },
  ]


  const styles = {
    marginRight: {
      marginRight: 9
    },
    wplinks: {
      textDecoration: "none", fontWeight: 500
    },
    textCenter: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer"
    }
  }


  function archivedActions(selectedRowIds, options) {
    const issues = selectedRowIds.map((item) => {
      return item.original.issue
    })

    const readyData = {
      scanId: scanId,
      archiveOptions: {
        ...options
      },
      issues
    }
    dispatch(archiveSqliLinks(readyData, scanId))
  }

  function completeAction(selectedRowIds) {
    const issues = selectedRowIds.map((item) => {
      return item.original.issue
    })

    const readyData = {
      scanId: scanId,
      issues
    }
    dispatch(completeSqliLinks(readyData, scanId))
  }


  function ignoreAction(selectedRowIds) {
    const issues = selectedRowIds.map((item) => {
      return item.original.issue
    })

    const readyData = {
      scanId: scanId,
      issues
    }
    dispatch(ignoreSqliLinks(readyData, scanId))
  }

  if ((!tblData || tblData.length < 1) && status === "completed") {
    return ""
  } else {
    return (
      <TablesCard icon={<DescriptionIcon />} title="SQLI Detection" status={status} tabs={tabs}>
        {tblData && !loading ? (
          <>
            <p tabIndex="0">
              <strong>SQL</strong> injection attack is one of the most severe attacks that can be used against web database driving applications. Attackers&apos; use <strong>SQLI</strong> to get unauthorized access to website database and perform unauthorized data modification.
            </p>
            {tblData.length > 0 ? (
              <ReactTable
                tableColumns={sqliColumns}
                data={tblData}
                selection={true}
                optionType={true}
                ignoreAction={ignoreAction}
                completeAction={completeAction}
                archivedActions={archivedActions}
              />
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "200px", maxHeight: "200px" }}
                role="alert"
                aria-live="assertive"
              >
                <h4 tabIndex="0">Please wait while we load your data</h4>
              </Box>
            )}
          </>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: "200px", maxHeight: "200px" }}
            role="status"
            aria-live="polite"
          >
            <h4>Please wait while we load your data</h4>
          </Box>
        )}
      </TablesCard>
    );
  }
}
