import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getCssTable,
  getNewCssTable,
} from "../../../store/actions/dashboardActions";
import NewHtmlCSSErrorsTable from "./NewHtmlCSSErrorsTable";

import CssWarningsTable from "./CssWarningsTable";
import CssErrorsTable from "./CssErrorsTable";
import Box from "@material-ui/core/Box";
import TablesCard from "../../commons/TablesCard";
import DescriptionIcon from "@material-ui/icons/Description";
import loader from "../../../assets/loader.svg";
import Grid from "@material-ui/core/Grid";
import Auth0Service from "../../../services/auth0Service/authentikService";

export default function HtmlCssTables({ scanId, link, setTables, tables, tabs }) {
  // eslint-disable-next-line
  const dispatch = useDispatch();

  const dataCss = useSelector((state) => state.dashboard.cssTable);
  const statusCss = useSelector((state) => state.dashboard.cssTableStatus);
  const cssLoading = useSelector((state) => state.dashboard.cssTableLoading);

  const newDataCss = useSelector((state) => state.dashboard.newCssTable);
  const newStatusCss = useSelector(
    (state) => state.dashboard.newCssTableStatus
  );
  const isPackagePurchased = useSelector(
    (state) => state.dashboard.isDomainPackagePurchased
  );

  // Get selected domain link from the store
  const isSelectedDomainLink = useSelector((state) => state.dashboard.selectedLink);
  

  // eslint-disable-next-line

  React.useEffect(() => {
    if (link && scanId) {
      dispatch(getCssTable(scanId, link));
    } else {
      dispatch(getCssTable(scanId, []));
      if (Auth0Service.isAuthenticated() && isPackagePurchased) {
        dispatch(getNewCssTable(scanId, []));
      }
    }
    // eslint-disable-next-line
  }, [scanId]);

  React.useEffect(() => {
    if (statusCss === "pending") {
      const interval = setInterval(() => {
        if (link && scanId) {
          dispatch(getCssTable(scanId, link));
        } else {
          dispatch(getCssTable(scanId, []));
        }
      }, 15000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [statusCss, scanId, link, dispatch]);

  React.useEffect(() => {
    if (dataCss) {
      dataCss.cssWarningsTable.length > 0 || dataCss.cssErrorsTable.length > 0
        ? setTables({ ...tables, css: true })
        : setTables({ ...tables, css: false });
    }
  }, [dataCss, setTables, tables]);

  // if (
  //   (!dataCss ||
  //     dataCss.cssWarningsTable.length < 1 ||
  //     dataCss.cssErrorsTable.length < 0) &&
  //   statusCss === "completed"
  // ) {
  //   return "";
  // } else {

    // Safely apply filtering only if dataHtml is available and not null
    const filteredDataCss = dataCss
    ? isSelectedDomainLink
      ? {
          ...dataCss,
          cssWarningsTable: dataCss.cssWarningsTable.filter(item => item.link === isSelectedDomainLink),
          cssErrorsTable: dataCss.cssErrorsTable.filter(item => item.link === isSelectedDomainLink),
        }
      : dataCss
    : null;

  const filteredNewDataCss = newDataCss
    ? isSelectedDomainLink
      ? newDataCss.filter(item => item.link === isSelectedDomainLink)
      : newDataCss
    : null;

  
  // Check if filtered data is empty
  const isFilteredDataEmpty = !(
    filteredDataCss?.cssWarningsTable.length > 0 ||
    filteredDataCss?.cssErrorsTable.length > 0 ||
    (filteredNewDataCss && filteredNewDataCss.length > 0)
  );

  return (
    <>
      <Box width="100%">
        {cssLoading && !isSelectedDomainLink ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            role="status"
            aria-live="polite"
          >
            <img src={loader} alt="loader" />
          </Box>
        ) : (
          <>
            {statusCss === "completed" && !isFilteredDataEmpty ? (
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <TablesCard
                    icon={<DescriptionIcon />}
                    title="CSS Warnings"
                    status={statusCss}
                    tabs={tabs}
                  >
                    <CssWarningsTable data={filteredDataCss} />
                  </TablesCard>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TablesCard
                    icon={<DescriptionIcon />}
                    title="CSS Errors"
                    status={statusCss}
                  >
                    <CssErrorsTable data={filteredDataCss} />
                  </TablesCard>
                </Grid>
                {newDataCss &&
                  newDataCss.length > 0 &&
                  Auth0Service.isAuthenticated() &&
                  isPackagePurchased && (
                    <Grid item xs={12} sm={12}>
                      <TablesCard
                        icon={<DescriptionIcon />}
                        title="New CSS Issues"
                        status={newStatusCss}
                      >
                        <NewHtmlCSSErrorsTable data={filteredNewDataCss} />
                      </TablesCard>
                    </Grid>
                  )}
              </Grid>
            ) : isSelectedDomainLink && isFilteredDataEmpty ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                role="alert"
                aria-live="assertive"
              >
                <p>No data available for the selected link</p>
              </Box>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                role="alert"
                aria-live="assertive"
              >
                <p>No data available</p>
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
  // }
}
