import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  IconButton: {
    marginLeft: 5,
    color: "black",
    padding: 0
  },
  infoIcon: {
    width: 15,
    height: 15,
  },
}
const CustomTooltip = ({title}) => {
  return (
    <LightTooltip placement="top" arrow title={title} aria-label={title} role="tooltip">
      <IconButton style={styles.IconButton}  aria-hidden="true">
        <InfoOutlinedIcon style={styles.infoIcon} />
      </IconButton>
    </LightTooltip>
  );
};

export default CustomTooltip;


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#36454f',
    color: '#f5f5f9 ',

    boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px',

    fontSize: 12,
  },
  arrow: {
    color: '#36454f',

  }
}))(Tooltip);