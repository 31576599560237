import React from 'react';
import Box from '@material-ui/core/Box';
import loaderwave from '../../../../assets/loader.svg';
import Card from '@material-ui/core/Card';

const styles = {
  cardSub: {
    filter: "drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.13))",
    height: "300px",
    width: "100%"
  },
}

function SecurityCardLoader() {
  return (
    <Card role="status" aria-live="polite" style={styles.cardSub}>
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <img alt="Loading content" src={loaderwave} />
      </Box>
    </Card>
  );
}

export default SecurityCardLoader;
